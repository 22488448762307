import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {User} from '../reetrak-layout/models/user';
import {
    API_AUTH,
    API_AUTH_REFRESH_TOKEN,
    API_AUTH_VALIDATE_TOKEN,
    API_USER_CHANGE_PASSWORD,
    API_USER_LOGOUT,
    API_USER_VALIDATE_PASSWORD,
    API_AUTH_SERVER_DATE
} from '../reetrak-layout/util/service-url';

@Injectable()
export class AuthServiceService {

    loggedInUser: User;

    constructor(private http: HttpClient) {
    }

    attemptAuth(ussername: string, password: string): Observable<any> {
        const credentials = {username: ussername, password: password};
        return this.http.post<any>(API_AUTH, credentials).pipe(
            tap(value => {
                this.loggedInUser = value.res.user
            }),
            map(value => {
                return value.res.token
            }),
            catchError(this.handleError('attemptAuth'))
        );
    }

    validateToken(): Observable<any> {
        return this.http.get<any>(API_AUTH_VALIDATE_TOKEN).pipe(
            tap(result => {
                this.loggedInUser = result.res;
            }),
            map(result => {
                return result.res.firstTimeFlag;
            }),
            catchError(this.handleError('validateToken'))
        );
    }

    refreshToken(): Observable<any> {
        return this.http.get<any>(API_AUTH_REFRESH_TOKEN).pipe(
            tap(value => {
                this.loggedInUser = value.res.user
            }),
            map(value => {
                return value.res.token
            }),
            catchError(this.handleError('refreshToken'))
        );
    }

    getUser(): User {
        return this.loggedInUser;
    }

    changePassword(user: User, remarks = ""): Observable<any> {
        const params = new HttpParams().set("remarks", remarks)
        return this.http.post<any>(API_USER_CHANGE_PASSWORD, user, {params: params}).pipe(
            tap(res => console.log("UserManagementService -> change password--: " + res)),
            catchError(this.handleError('change password'))
        );
        ;
    }

    passwordAuthentication(user: User, currPsw = ""): Observable<any> {
        const params = new HttpParams().set("currPsw", currPsw)
        return this.http.post<any>(API_USER_VALIDATE_PASSWORD, user, {params: params}).pipe(
            tap(res => console.log("UserManagementService -> change password--: " + res)),
            catchError(this.handleError('change password'))
        );
        ;
    }

    logout(user: User): Observable<any> {
        return this.http.post<any>(API_USER_LOGOUT, user).pipe(
            tap(res => console.log("UserManagementService -> logout--: " + res)),
            catchError(this.handleError('logout'))
        );
        ;
    }

    serverDate(): Observable<string> {
        
        return this.http.get(API_AUTH_SERVER_DATE , {responseType: 'text'}
          ).pipe(         
            tap(res => console.log("serverDate -> " + res)),   
            catchError(this.handleError('serverDate'))
        );
    }

    private handleError(message: string) {
        return (error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error(`${message} An error occurred:`, error.error.message);
            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                // console.error(
                //   `${message} Backend returned code ${error.status}, ` +
                //   `${message} body was: ${error.error}`);
            }
            // return an observable with a user-facing error message
            return observableThrowError(error.error);
        };
    }

}
