import { Component, OnInit, Inject } from '@angular/core';
import { AppSettings } from '../../../util/app-settings';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  title: string;
  message: any;  
  buttonSubmit: string;
  buttonCancel: string;  
  icon : string;
  AS = AppSettings;
  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>, @Inject(MAT_DIALOG_DATA) data: any,
  public sanitize: DomSanitizer) {
    this.title =  data.title;        
    this.message = sanitize.bypassSecurityTrustHtml(data.message);
    this.buttonSubmit = data.buttonSubmit;
    this.buttonCancel = data.buttonCancel;    
    this.icon = data.icon;
  }

  ngOnInit() {      
  }

  submitRemark() {
    this.dialogRef.close("ok");
  }
  closeRemark() {
    this.dialogRef.close("");
  }
  
 

}
