<div class="row">
  <div class="col-md-12">
    <div class="card card-product">
      <div class="card-header card-header-image">        
          <!-- <img class="img" src="./assets/img/card-2.jpg">         -->
          
      </div>
      <div class="card-body">
        <div #printEl class="text-center custom-img">
          <img [src]="'data:image/png;base64,' + imageData" />
        </div>
        <h4 class="card-title">
          {{imageName}}
        </h4>        
      </div>
      <div class="card-footer">
        
          <button (click)="print()" mat-raised-button type="submit" class="btn" [ngClass]="AS.btnClass">Print</button>
          <button (click)="close()" mat-raised-button type="submit" class="btn" [ngClass]="AS.btnClass">Close</button>
        
      </div>
    </div>
  </div>