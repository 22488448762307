import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserRemarksComponent } from '../modules/dialog/user-remarks/user-remarks.component';
import { ConfirmationComponent } from '../modules/dialog/confirmation/confirmation.component';
import { Constants } from '../util/constants';
import { ListConfirmationDialogComponent } from '../modules/dialog/list-confirmation-dialog/list-confirmation-dialog.component';
import { UserRemarksDropdownComponent } from '../modules/dialog/user-remarks-dropdown/user-remarks-dropdown.component';

declare var $: any;
const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
enum notificationType { defualt, info, success, warning, danger, rose, primary };
@Injectable()
export class NotificationService {

  CT = Constants;
  timer: number;
  constructor(private dialog: MatDialog) { }

  success(message: string, seconds?: number) {
    this.showNotification("top", "right", type[notificationType.success], message, seconds);
  }

  warning(message: string, seconds?: number) {
    this.showNotification("top", "right", type[notificationType.warning], message, seconds);
  }

  failure(message: string, seconds?: number) {
    this.showNotification("top", "right", type[notificationType.danger], message, seconds);
  }

  showNotification(from: any, align: any, type: string, message: string, timer: number) {
    // const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];    
    if (timer > 0)
      this.timer = timer;
    else
      this.timer = 5;

    $.notify({
      icon: 'notifications',
      message: message,
    }, {
      type: type,
      delay: this.timer * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message" style="word-break: break-word;">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  userRemarks(title: string): Observable<string> {
    return Observable.create((observer) => {

      let dialogRef = this.dialog.open(UserRemarksComponent, {
        width: '400px',
        data: {
          title: title
        },
        hasBackdrop: true,
        panelClass: 'mat-dialog-custom-panel',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(result);
        } else {
          observer.error("No remarks");

        }

        observer.complete();
      });
    })
  }

  userRemarksDropdown(title: string): Observable<string> {
    return Observable.create((observer) => {

      let dialogRef = this.dialog.open(UserRemarksDropdownComponent, {
        width: '100%',
        height: '99%',
        data: {
          title: title
        },
        hasBackdrop: true,
        panelClass: 'mat-dialog-custom-panel',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(result);
        } else {
          observer.error("No remarks");

        }

        observer.complete();
      });
    })
  }

  confirmationDialoag(title: string, message: string, buttonSubmit?: string, buttonCancel?: string, icon?: string): Observable<string> {
    return Observable.create((observer) => {

      let dialogRef = this.dialog.open(ConfirmationComponent, {
        width: '400px',
        data: {
          title: title,
          message: message,
          buttonSubmit: buttonSubmit != null || buttonSubmit != undefined ? buttonSubmit : this.CT.COMMON_OK,
          buttonCancel: buttonCancel != null || buttonCancel != undefined ? buttonCancel : this.CT.COMMON_CLOSE,
          // buttonCancel: buttonCancel != null || buttonCancel != undefined ? buttonCancel : undefined,          // for making close button optional
          icon: icon != null || icon != undefined ? icon : undefined,
        },
        hasBackdrop: true,
        panelClass: 'mat-dialog-custom-panel',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(result);
        } else {
          observer.error("Cancelled or error");

        }
        observer.complete();
      });
    })
  }

  listConfirmationDialoag(title: string, list: String[], buttonSubmit?: string, buttonCancel?: string): Observable<boolean> {
    return Observable.create((observer) => {

      let dialogRef = this.dialog.open(ListConfirmationDialogComponent, {
        width: '60%',
        data: {
          title: title,
          list: list,
          buttonSubmit: buttonSubmit != null || buttonSubmit != undefined ? buttonSubmit : this.CT.COMMON_OK,
          buttonCancel: buttonCancel != null || buttonCancel != undefined ? buttonCancel : this.CT.COMMON_CLOSE
        },
        hasBackdrop: true,
        panelClass: 'mat-dialog-custom-panel',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(result);
        } else {
          observer.error("Cancelled or error");

        }
        observer.complete();
      });
    })
  }

}
