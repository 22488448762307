import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityPolicy } from '../models/security-policy';
import { ResponseObject } from '../models/response-object';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_SECURITY_UPDATE, API_SECURITY_LIST } from '../util/service-url';
import { map, catchError } from 'rxjs/operators';
import { AliasService } from './alias.service';
import { BusinessError } from '../models/business-error';

@Injectable()
export class SecurityPolicyService {

  constructor(private http: HttpClient, private AL: AliasService) { }

  getSecurityPolicyDetails(): Observable<SecurityPolicy> {
    return this.http.get<ResponseObject<SecurityPolicy>>(API_SECURITY_LIST).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getSecurityPolicyDetails'))
    );
  }

  updateSecurityPolicy(securityPolicy: SecurityPolicy, remarks = ""): Observable<ResponseObject<any>> {
    const params = new HttpParams().set("remarks", remarks);
    return this.http.post<ResponseObject<SecurityPolicy>>(API_SECURITY_UPDATE, securityPolicy, {params : params}).pipe(
      map(value => value),
      catchError(this.AL.handleError('updateSecurityPolicy'))
    )
  }

}
