import { Component, OnInit, Inject } from '@angular/core';
import { AppSettings } from 'app/reetrak-layout/util/app-settings';
import { Constants } from 'app/reetrak-layout/util/constants';
import { NotificationDetail } from '../../../../models/notification-details';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { LoaderService } from 'app/reetrak-layout/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AliasService } from 'app/reetrak-layout/services/alias.service';
import { NotificationService } from 'app/reetrak-layout/services/notification.service';
import { CommonService } from 'app/reetrak-layout/services/common.service';
import { UserNotificationService } from 'app/reetrak-layout/services/user-notification.service';
import { LovVal } from 'app/reetrak-layout/models/lov-val';
import { LovService } from 'app/reetrak-layout/services/lov.service';

const TRANSLATE_KEY = "NOTIFICATIONS.";

@Component({
  selector: 'app-notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.scss']
})
export class NotificationsFormComponent implements OnInit {

  AS = AppSettings;
  CT = Constants;
  NotificationDetail: NotificationDetail;
  isReload:boolean;
  todayDate: Date = new Date();
  moduleList: Array<LovVal>;

  constructor(public dialogRef: MatDialogRef<NotificationsFormComponent>, public userNotificationService: UserNotificationService,
    @Inject(MAT_DIALOG_DATA) data: any, private notificationService: NotificationService,
    private fb: FormBuilder, private commonService: CommonService, public AL: AliasService,private lovService: LovService,
    private loaderService: LoaderService, private translate: TranslateService) {
      this.NotificationDetail = data.NotificationDetail;
     }

  ngOnInit() {
    this.lovService.getLovMst(this.CT.LOV_MODULE_FOR_AUDIT_TRAIL).subscribe((res) => {
      this.moduleList = res.lovValList;
    })
  }

  isNumber(val): string {
    return Number(val).toString(); 
  }
}
