import { environment } from "environments/environment";

// --- URL for local develoment
const prefix = environment.apiPrefix;
// const prefix = "" 
// for deply in spring boot 
// const prefix = ".."  // for deploy on tomcat

export const API_AUTH: string = `${prefix}/token/generate-token`;
export const API_AUTH_VALIDATE_TOKEN: string = `${prefix}/menu/validate-token`;
export const API_AUTH_REFRESH_TOKEN: string = `${prefix}/menu/refresh-token`;
export const API_AUTH_SERVER_DATE: string = `${prefix}/token/server-date`;
export const API_MENU_LIST: string = `${prefix}/menu/list/`;
export const API_MENU_FLUSH: string = `${prefix}/menu/flush/`;

/** nomenclature */

export const API_NOMENCLATURE_LIST: string = `${prefix}/nomenclature/list`;
export const API_NOMENCLATURE_ADD: string = `${prefix}/nomenclature/add`;
export const API_NOMENCLATURE_UPDATE: string = `${prefix}/nomenclature/update`;

/** config */

export const API_CONFIG_LIST: string = `${prefix}/config/list`;
export const API_CONFIG_ADD: string = `${prefix}/config/add`;
export const API_CONFIG_UPDATE: string = `${prefix}/config/update`;
export const API_GET_CONFIG_LIC_DAYS: string = `${prefix}/config/getLicenseDays`;


/** snInbound */

export const API_SNINBOUND_LIST: string = `${prefix}/snInbound/list`;
export const API_SNINBOUND_FILTER_LIST: string = `${prefix}/snInbound/filterd/list`;
export const API_SNINBOUND_GET: string = `${prefix}/snInbound/get/`;
export const API_SNINBOUND_DOWNLOAD: string = `${prefix}/snInbound/download/`;
export const API_SNINBOUND_EXPORT_CSV: string = `${prefix}/snInbound/exportCsvWithFilter`;
export const API_SNINBOUND_GET_PRODUCT: string = `${prefix}/snInbound/getProductCodeList`;
export const API_SNINBOUND_GET_REQ_RES_FILE_LIST: string = `${prefix}/snInbound/getReqResFileList`;
export const API_SNINBOUND_GET_FILE_XML: string = `${prefix}/snInbound/getXML/`;

/** snOutbound */

export const API_SNOUTBOUND_LIST: string = `${prefix}/snOutbound/list`;
export const API_SNOUTBOUND_FILTER_LIST: string = `${prefix}/snOutbound/filterd/list`;
export const API_SNOUTBOUND_GET: string = `${prefix}/snOutbound/get/`;
export const API_SNOUTBOUND_DOWNLOAD: string = `${prefix}/snOutbound/download/`;
export const API_SNOUTBOUND_EXPORT_CSV: string = `${prefix}/snOutbound/exportCsvWithFilter`;
export const API_SNOUTBOUND_GET_PRODUCT: string = `${prefix}/snOutbound/getProductCodeList`;
export const API_SNOUTBOUND_DOWNLOAD_FOR_AXA: string = `${prefix}/snOutbound/downloadCodeForAxa`;
export const API_SNOUTBOUND_GET_CODE_ALLOCATION: string = `${prefix}/snOutbound/getCodeAllocation`;

/** snManager */

export const API_SNMANAGER_LIST: string = `${prefix}/snManager/list`;
export const API_SNMANAGER_ACTIVE_LIST: string = `${prefix}/snManager/activelist`;
export const API_SNMANAGER_ADD: string = `${prefix}/snManager/add`;
export const API_SNMANAGER_UPDATE: string = `${prefix}/snManager/update`;
export const API_SN_ACTIVE: string = `${prefix}/snManager/active/`;
export const API_SN_DEACTIVE: string = `${prefix}/snManager/deactive/`;
export const API_SNMANAGER_REMOVE_LEVEL: string = `${prefix}/snManager/removelevel/`;
export const API_SNMANAGER_REMOVE_CODE: string = `${prefix}/snManager/removeCode/`;
export const API_SNMANAGER_FLUSH: string = `${prefix}/snManager/flush`;
export const API_SNMANAGER_ALL_FLUSH: string = `${prefix}/snManager/allflush`;
export const API_SNMANAGER_DOWNLOAD_CODE: string = `${prefix}/snManager/exportCSV`;
export const API_SNMANAGER_COUNTER_VALUE: string = `${prefix}/snManager/countervalue/`;
export const API_SNMANAGER_GET_BY_ID: string = `${prefix}/snManager/get/`;
export const API_SNMANAGER_PRODUCT_CODE: string = `${prefix}/snManager/productCode/`;
export const API_SNMANAGER_EXPORT: string = `${prefix}/snManager/exportCsvWithFilter`;
export const API_DEACTIVE_SNMANAGER_LIST: string = `${prefix}/snManager/deActiveSnManagerList/`;
export const API_SNMANAGER_EXTENSION_LIST: string = `${prefix}/snManagerExtension/bySNManagerId/`;
export const API_SNMANAGER_EXTENSION_LIST_MDLP: string = `${prefix}/snManagerExtension/extensionDetails/`;
export const API_DEACTIVE_SNMANAGER_THRESHOLDPOOL_LIST: string = `${prefix}/thresholdpool/deActiveSnManagerThresholdList`;
export const API_SAVE_ACTIVE_SMANAGER_LIST: string = `${prefix}/snManager/saveActiveSnManagerList/`;
export const API_GET_ALL_SMANAGER_LIST: string = `${prefix}/snManager/allList/`;
export const API_SN_MANAGER_PRIVATE_KEY_REMOVE = `${prefix}/snManager/removePrivateKey/`;

/**MDLP */
export const API_SNMASTER_LIST: string = `${prefix}/mdlp/getSnMasterList/`;
export const API_SNMASTER_LIST_481: string = `${prefix}/mdlp/getSnMasterList481/`;
export const API_GENERATE_EVENT_552: string = `${prefix}/mdlp/generate552/`;
export const API_GENERATE_EVENT_335: string = `${prefix}/mdlp/generate335/`;
export const API_GENERATE_EVENT_333: string = `${prefix}/mdlp/generate333/`;
export const API_GENERATE_EVENT_342: string = `${prefix}/mdlp/generate342/`;
export const API_GENERATE_EVENT_542: string = `${prefix}/mdlp/generate542/`;
export const API_GENERATE_EVENT_341: string = `${prefix}/mdlp/generate341/`;
export const API_GENERATE_EVENT_541: string = `${prefix}/mdlp/generate541/`;
export const API_GENERATE_EVENT_415: string = `${prefix}/mdlp/generate415/`;
export const API_GENERATE_EVENT_471: string = `${prefix}/mdlp/generate471/`;
export const API_GENERATE_EVENT_472: string = `${prefix}/mdlp/generate472/`;
export const API_GENERATE_EVENT_391: string = `${prefix}/mdlp/generate391/`;
export const API_GENERATE_EVENT_250: string = `${prefix}/mdlp/generate250/`;
export const API_GENERATE_EVENT_916: string = `${prefix}/mdlp/generate916/`;
export const API_GENERATE_EVENT_912: string = `${prefix}/mdlp/generate912/`;
export const API_GENERATE_EVENT_251: string = `${prefix}/mdlp/generate251/`;
export const API_GENERATE_EVENT_313: string = `${prefix}/mdlp/generate313/`;
export const API_GENERATE_EVENT_915: string = `${prefix}/mdlp/generate915/`;
export const API_GENERATE_EVENT_913: string = `${prefix}/mdlp/generate913/`;
export const API_GENERATE_EVENT_10319: string = `${prefix}/mdlp/generate10319/`;
export const API_GENERATE_EVENT_10311: string = `${prefix}/mdlp/generate10311/`;
export const API_GENERATE_EVENT_481: string = `${prefix}/mdlp/generate481/`;
export const API_GENERATE_EVENT_701: string = `${prefix}/mdlp/generate701/`;
export const API_MDLP_GET_INCOMING_DOCUMENT: string = `${prefix}/mdlp/getMDLPDocumentListByDate/`;
export const API_MDLP_GET_MDLP_EVENT_REPORT_DATA: string = `${prefix}/mdlp/getMdlpEventReportData/`;
export const API_MDLP_GET_MDLP_EVENT_REPORT_DATA_CSV: string = `${prefix}/mdlp/getMdlpEventReportDataCsv/`;
export const API_MDLP_EVENT_TRIGGER_LIST: string = `${prefix}/mdlp/getEvenTriggerList/`;
export const API_MDLP_EVENT_TRIGGER_LIST_BY_LOT: string = `${prefix}/mdlp/eventTriggerListByLot/`;
export const API_MDLP_EVENT_GET_RECEIPT: string = `${prefix}/mdlp/getReceipt/`;
export const API_MDLP_EVENT_FILE_POST: string = `${prefix}/mdlp/fileUpload/`;
export const API_MDLP_EVENT_GET_XML: string = `${prefix}/mdlp/getXML/`;
export const API_TRADEVALUE_SSCC_LIST: string = `${prefix}/mdlp/ssccTradeValue/`;
export const API_EVENT_916_CODE: string = `${prefix}/mdlp/codeListFor916/`;
export const API_MDLP_EVENT_FILE: string = `${prefix}/mdlp/eventFile/`;
export const API_EVENT_10319_GET_CODE: string = `${prefix}/mdlp/getPrimaryLevelCode/`;
export const API_MDLP_EVENT_TRIGGER_REPROCESS: string = `${prefix}/mdlp/reprocess/`;
export const API_MDLP_EVENT_TRIGGER_REPROCESS_10319: string = `${prefix}/mdlp/reprocess10319/`;
export const API_MDLP_EVENT_TRIGGER_EXTENSION: string = `${prefix}/mdlp/getReportIdFromEvenTriggerExtension/`;
export const API_MDLP_CHECK_10319_POSTED: string = `${prefix}/mdlp/check10319Posted/`;
export const API_EVENT_913_CODE: string = `${prefix}/mdlp/codeListFor913/`;
export const API_EVENT_915_CODE: string = `${prefix}/mdlp/bySSCCCodeList/`;
export const API_GET_CODE_INFO_FOR_ACCEPT: string = `${prefix}/mdlp/getCodeInfoForAccept/`;
/** Threshold Pool */

export const API_THRESHOLDPOOL_LIST: string = `${prefix}/thresholdpool/list`;
export const API_SNMANAGER_THRESHOLDPOOL_LIST: string = `${prefix}/thresholdpool/snManagerWiselist/`;
export const API_THRESHOLDPOOL_EXPORT_CSV: string = `${prefix}/thresholdpool/exportCsvWithFilter`;
export const API_DEACTIVE_PRODUCTS_THRESHOLDPOOL_LIST: string = `${prefix}/thresholdpool/deActiveProductsThresholdList`;
export const API_GET_THRESHOLDPOOL: string = `${prefix}/thresholdpool/getThresholdPool`;
export const API_GET_THRESHOLDPOOL_LIST_BY_SENDER_TRADETYPE: string = `${prefix}/thresholdpool/getThresholdPoolListByTradeTypeAndSender`;
/** Event Master */

export const API_EVENTMASTER_LIST: string = `${prefix}/eventmaster/list`;
export const API_EVENTMASTER_ADD: string = `${prefix}/eventmaster/add`;
export const API_EVENTMASTER_UPDATE: string = `${prefix}/eventmaster/update`;
export const API_EVENTMASTER_ACTIVE: string = `${prefix}/eventmaster/active/`;
export const API_EVENTMASTER_DEACTIVE: string = `${prefix}/eventmaster/deactive/`;
export const API_EVENTMASTER_EXTENSION: string = `${prefix}/eventmaster/extension/`;

/** Reports */

export const API_REPORT_AUDIT_TRAIL: string = `${prefix}/report/auditTrail`;
export const API_REPORT_AUDIT_TRAIL_FILTERED: string = `${prefix}/report/filtered/list`;
export const API_AUDIT_BY_APP_ID_AND_ENTITY_ID_AND_LINK_ID: string = `${prefix}/report/getByAppIDEntityIDlinkAppId/`;
export const API_AUDIT_IMAGE: string = `${prefix}/report/corporateLogo`;
export const API_REPORT_BATCH: string = `${prefix}/report/batchView`;
export const API_REPORT_BATCH_RELATION: string = `${prefix}/report/batchRelationView`;
export const API_REPORT_BATCH_ACK: string = `${prefix}/report/batchAck`;
export const API_REPORT_ACCEPTED_CODE: string = `${prefix}/report/acceptedCodeView`;
export const API_REPORT_REJECTED_CODE: string = `${prefix}/report/rejectedCodeView`;
export const API_REPORT_CODE_STATUS: string = `${prefix}/report/codeStusReportView`;
export const API_REPORT_GRADECHART: string = `${prefix}/report/gradeChartView`;
export const API_REPORT_PRODUCTCHART: string = `${prefix}/report/productChartView`;
export const API_REPORT_SERIAL_CODE: string = `${prefix}/report/serialCodeView`;
export const API_REPORT_RELATIONSHIP: string = `${prefix}/report/relationshipView`;
export const API_REPORT_SERIAL_CODE_HISTORY: string = `${prefix}/report/serialCodeHistoryView`;
export const API_REPORT_BATCHLIST: string = `${prefix}/report/batchList`;
export const API_REPORT_BATCHLEVELLIST: string = `${prefix}/report/batchLevelList`;
export const API_REPORT_AUDIT_MODULELIST: string = `${prefix}/report/moduleList`;
/** manage user  */

export const API_USER_LIST: string = `${prefix}/user/list/`;
export const API_USER_GET: string = `${prefix}/user/get/`;
export const API_USER_SAVE: string = `${prefix}/user/add/`;
export const API_OTHER_USER_SAVE: string = `${prefix}/user/addOtherUser/`;
export const API_USER_UPDATE: string = `${prefix}/user/update/`;
export const API_OTHER_USER_UPDATE: string = `${prefix}/user/updateOtherUser/`;
export const API_USER_ACTIVE: string = `${prefix}/user/active/`;
export const API_USER_DEACTIVE: string = `${prefix}/user/deactive/`;
export const API_USER_UNLOCK: string = `${prefix}/user/unlock/`;
export const API_USER_CHANGE_PASSWORD: string = `${prefix}/user/changePassword/`;
export const API_USER_VALIDATE_PASSWORD: string = `${prefix}/user/validatePassword/`;
export const API_USER_REGENERATEKEY: string = `${prefix}/user/regenerateKey/`;
export const API_USER_REGENERATE_TOKEN: string = `${prefix}/user/regeneratetoken/`;
export const API_USER_IMAGE_UPLOAD: string = `${prefix}/user/upload/`;
export const API_USER_LOGOUT: string = `${prefix}/user/logout/`;
export const API_ALL_USER_LIST: string = `${prefix}/user/allUsers/`;


/** Company Details */

export const API_COMPANY_LIST: string = `${prefix}/company/list/`;
export const API_CUSTOMER_LIST: string = `${prefix}/company/customerList`;
export const API_CUSTOMER_ALL_LIST: string = `${prefix}/company/customerAllList`;
export const API_COMPANY_GET: string = `${prefix}/company/get/`;
export const API_COMPANY_ADD: string = `${prefix}/company/add/`;
export const API_COMPANY_UPDATE: string = `${prefix}/company/update/`;
export const API_COMPANY_ACTIVE: string = `${prefix}/company/active/`;
export const API_COMPANY_DEACTIVE: string = `${prefix}/company/deactive/`;
export const API_CUSTOMER_DETAIL_LOTMASTER: string = `${prefix}/company/lotMaster/`;
export const API_BRANDOWNER_GLN_BIND: string = `${prefix}/company/checkBrandOwnerGLNBinding/`;
export const API_BRANDOWNER_EXPORT_CSV: string = `${prefix}/company/exportCsvWithFilter/`;
export const API_CUSTOMER_LIST_BY_USER: string = `${prefix}/company/brandListByUser`;

/** Security Policy Details */
export const API_SECURITY_LIST: string = `${prefix}/securityPolicy/list/`;
export const API_SECURITY_UPDATE: string = `${prefix}/securityPolicy/update/`;

/** Manage Role */
export const API_ROLE_LIST: string = `${prefix}/role/list/`;
export const API_ROLE_GET: string = `${prefix}/role/get/`;
export const API_ROLE_MENU_MAPPING: string = `${prefix}/role/menuMapping/`;
export const API_ROLE_MENU_MAPPING_UPDATE: string = `${prefix}/role/saveRoleMenuMapping/`;
export const API_ROLE_UPDATE: string = `${prefix}/role/update/`;
export const API_ROLE_ACTIVE: string = `${prefix}/role/active/`;
export const API_ROLE_DEACTIVE: string = `${prefix}/role/deactive/`;

/** Manage Plants Details */

export const API_PLANT_DETAILS: string = `${prefix}/plant/details/`;
export const API_PLANT_GET: string = `${prefix}/plant/get/`;
export const API_PLANT_ADD: string = `${prefix}/plant/add/`;
export const API_PLANT_UPDATE: string = `${prefix}/plant/update/`;
export const API_PLANT_ACTIVE: string = `${prefix}/plant/active/`;
export const API_PLANT_DEACTIVE: string = `${prefix}/plant/deactive/`;
export const API_PLANT_GLN_BIND: string = `${prefix}/plant/checkSenderBinding/`;
export const API_PLANT_LIST: string = `${prefix}/plant/list/`;
export const API_PLANT_EXPORT_CSV: string = `${prefix}/plant/exportCsvWithFilter/`;
export const API_PLANT_GET_PRODUCT: string = `${prefix}/plant/getProductsFromPlant/`;

/** Manage Location Master Details */

export const API_LOCATION_MASTER_LIST: string = `${prefix}/location/list/`;
export const API_LOCATION_MASTER_GET: string = `${prefix}/location/get/`;
export const API_LOCATION_MASTER_ADD: string = `${prefix}/location/add/`;
export const API_LOCATION_MASTER_UPDATE: string = `${prefix}/location/update/`;
export const API_LOCATION_MASTER_ACTIVE: string = `${prefix}/location/active/`;
export const API_LOCATION_MASTER_DEACTIVE: string = `${prefix}/location/deactive/`;
export const API_LOCATION_MASTER_EXPORT_CSV: string = `${prefix}/location/exportCsvWithFilter/`;




/** Manage Product */
export const API_PRODUCT_LIST: string = `${prefix}/product/list/`;
export const API_PRODUCT_FRONTDETAILS_LIST: string = `${prefix}/product/frontDetailslist/`;
export const API_PRODUCT_THRESHOLDPOOL: string = `${prefix}/product/addThreholdPool/`;
export const API_PRODUCT_THRESHOLDPOOL_GET: string = `${prefix}/product/addThreholdPoolGet/`;
export const API_PRODUCT_GET_PRODUCTLEVEL_LIST: string = `${prefix}/product/getProductLevelList/`;
export const API_PRODUCT_THRESHOLDPOOL_BY_PRODUCTCODE: string = `${prefix}/product/getListOfThresholdPoolByCode/`;
export const API_PRODUCT_GET_LOTMASTER_BY_CODE: string = `${prefix}/product/getLotMasterByProductCode/`;
export const API_PRODUCT_CHECK_LEVEL_IN_SNOUTBOUND: string = `${prefix}/product/checkProductLevelInSnOutBound/`;
export const API_PRODUCT_THRESHOLDPOOL_LIST: string = `${prefix}/product/thresholdPoolSSCCList/`;
export const API_PRODUCT_GET: string = `${prefix}/product/get/`;
export const API_PRODUCT_GET_BY_CODE: string = `${prefix}/product/getProductByCode/`;
export const API_PRODUCT_ADD: string = `${prefix}/product/add/`;
export const API_PRODUCT_UPDATE: string = `${prefix}/product/update/`;
export const API_PRODUCT_ACTIVE: string = `${prefix}/product/active/`;
export const API_PRODUCT_DEACTIVE: string = `${prefix}/product/deactive/`;
export const API_PRODUCT_ACTIVEMULTIPLE: string = `${prefix}/product/activeMultiple/`;
export const API_PRODUCT_REMARKS: string = `${prefix}/product/remarks/`;
export const API_PRODUCT_DOWNLOAD_CODE: string = `${prefix}/product/exportcsv`;
export const API_PRODUCT_EXPORT: string = `${prefix}/product/exportCsvWithFilter`;
export const API_PRODUCT_FLUSH: string = `${prefix}/product/flush`;
export const API_PRODUCT_COMPLIANCE_LIST: string = `${prefix}/product/complianceList/`;
export const API_PRODUCT_EXTRA_FIELD_LIST: string = `${prefix}/product/complianceField/`;
export const API_PRODUCT_LEVEL_FOR_SN_MANAGER: string = `${prefix}/product/productLevel/`;
export const API_PRODUCT_IS_TRADEVALUE_SENDER_RECIEVER: string = `${prefix}/product/isTradeValueSenderReceiver/`;
export const API_PRODUCT_IS_SENDER_RECEIVER: string = `${prefix}/product/isSenderReceiverBindSNOutBound/`;
export const API_PRODUCT_COMPLIANCE_TRIGGER_LIST: string = `${prefix}/product/complianceTriggerList/`;
export const API_PRODUCT_COMPLIANCE_FIELD_LIST: string = `${prefix}/product/extraDetailsListByLotId/`;
export const API_PRODUCT_MASTER_EXPORT: string = `${prefix}/product/download/`;
export const API_IVEDA_SINGLE_PRODUCT_EXPORT: string = `${prefix}/product/ivedaSingleProductExport/`;
export const API_IVEDA_MULTI_PRODUCT_EXPORT: string = `${prefix}/product/ivedaMultiProductExport/`;
export const API_DEACTIVE_PRODUCT_LIST: string = `${prefix}/product/deactiveProductList/`;
export const API_SAVE_ACTIVE_PRODUCT_LIST: string = `${prefix}/product/saveActiveProductList/`;
export const API_PRODUCT_LIST_FOR_LOT: string = `${prefix}/product/listForLot/`;
export const API_PRODUCT_LIST_FOR_AXA: string = `${prefix}/product/productList/`;
export const API_PRODUCT_JSON_EXPORT: string = `${prefix}/product/exportJsonWithSnManager/`;
export const API_PRODUCT_JSON_EXPORT_CSV: string = `${prefix}/product/generateProductJsonCsv`;

/** manage company prefix  */

export const API_COMAPNY_PREFIX_LIST: string = `${prefix}/companyPrefix/list/`;
export const API_COMAPNY_PREFIX_GET: string = `${prefix}/companyPrefix/get/`;
export const API_COMAPNY_PREFIX_SAVE: string = `${prefix}/companyPrefix/add/`;
export const API_COMAPNY_PREFIX_UPDATE: string = `${prefix}/companyPrefix/update/`;
export const API_COMAPNY_PREFIX_ACTIVE: string = `${prefix}/companyPrefix/active/`;
export const API_COMAPNY_PREFIX_DEACTIVE: string = `${prefix}/companyPrefix/deActive/`;

/** Manage Production Master */

export const API_PRODUCTION_MASTER_LIST: string = `${prefix}/productionMaster/list/`;
export const API_PRODUCTION_MASTER_GET: string = `${prefix}/productionMaster/get/`;
export const API_PRODUCTION_MASTER_ADD: string = `${prefix}/productionMaster/add/`;
export const API_PRODUCTION_MASTER_UPDATE: string = `${prefix}/productionMaster/update/`;
export const API_PRODUCTION_MASTER_ACTIVE: string = `${prefix}/productionMaster/active/`;
export const API_PRODUCTION_MASTER_DEACTIVE: string = `${prefix}/productionMaster/deactive/`;

/** Manage Dealer Mst */

export const API_DEALER_MST_LIST: string = `${prefix}/dealer/list/`;
export const API_DEALER_MST_GET: string = `${prefix}/dealer/get/`;
export const API_DEALER_MST_ADD: string = `${prefix}/dealer/add/`;
export const API_DEALER_MST_UPDATE: string = `${prefix}/dealer/update/`;
export const API_DEALER_MST_ACTIVE: string = `${prefix}/dealer/active/`;
export const API_DEALER_MST_DEACTIVE: string = `${prefix}/dealer/deactive/`;

/** Manage Process Order */

export const API_PROCESS_ORDER_LIST: string = `${prefix}/erpBatchDetail/list/`;
export const API_PROCESS_ORDER_GET: string = `${prefix}/erpBatchDetail/get/`;
export const API_PROCESS_ORDER_ADD: string = `${prefix}/erpBatchDetail/add/`;
export const API_PROCESS_ORDER_UPDATE: string = `${prefix}/erpBatchDetail/update/`;

/** Manage Batch */

export const API_BATCH_LIST: string = `${prefix}/batch/list/`;
export const API_BATCH_GET: string = `${prefix}/batch/get/`;
export const API_BATCH_ADD: string = `${prefix}/batch/add/`;
export const API_BATCH_TRANSFER: string = `${prefix}/batch/transferBatchToLine/`;
export const API_BATCH_UPDATE: string = `${prefix}/batch/update/`;
export const API_BATCH_IS_COMPLETED: string = `${prefix}/batch/isBatchCompleted/`;
export const API_BATCH_DELETE_BATCH: string = `${prefix}/batch/deleteBatch/`;
export const API_BATCH_RE_TRANSFER_BATCH: string = `${prefix}/batch/reTransferBatch/`;
export const API_BATCH_ASSIGN_BATCH: string = `${prefix}/batch/assignBatch/`;
export const API_BATCH_CHANGE_CODE_STATUS: string = `${prefix}/batch/listChangeCodeStatus/`;
export const API_BATCH_CHANGE_CODE_STATUS_UPDATE: string = `${prefix}/batch/listChangeCodeStatusUpdate/`;
export const API_BATCH_FILTER_LIST: string = `${prefix}/batch/batchList`;
export const API_BATCH_FILTER_ADVANCE_LIST: string = `${prefix}/batch/getBatchListWithinRange`;
export const API_BATCH_REPACK_BATCH: string = `${prefix}/batch/repackFullBatch`;
export const API_BATCH_GET_REPACKING: string = `${prefix}/batch/getBatchForRepacking`;
export const API_BATCH_REPACK_REMOVE_CHILD: string = `${prefix}/batch/repackRemoveChild`;
export const API_BATCH_REPACK_TRANSFER_CHILD: string = `${prefix}/batch/repackTransferChild`;
export const API_BATCH_UN_ASSIGN_CODE: string = `${prefix}/batch/unAssignCodeForBatch`;
export const API_BATCH_REPACK_ASSIGN_CODE: string = `${prefix}/batch/repackAssignCode`;
export const API_BATCH_GET_CODE_CHILD_LIST: string = `${prefix}/batch/getCodeChildList`;
export const API_BATCH_GET_REPACK_CODE_LIST: string = `${prefix}/batch/getCodeListForRecallRepack`;
export const API_BATCH_REPACK_RECALL_EVENT: string = `${prefix}/batch/repackRecallEvent`;
export const API_BATCH_GET_PARTIAL_CODE_LIST: string = `${prefix}/batch/getCodeListForSelectedBatch`;
export const API_BATCH_GET_UPDATE_PRINT_COUNT: string = `${prefix}/batch/updateReprintCount`;
export const API_BATCH_REAPCK_PARTIAL: string = `${prefix}/batch/repackPartial`;
export const API_BATCH_REPRINT_CODE_DETAILS: string = `${prefix}/batch/reprintCodeDetails`;
export const API_BATCH_MANUAL_EVENT_CODE_LIST: string = `${prefix}/batch/getCodeListForManualEvent`

/** LOV */
export const API_LOV_ROLE_TYPES: string = `${prefix}/lov/roleTypes/`;
export const API_LOV_ROLE: string = `${prefix}/lov/roles/`;
export const API_LOV_OTHER_ROLE: string = `${prefix}/lov/otherRole/`;
export const API_LOV_ROLE_RIGHTS: string = `${prefix}/lov/roleRights/`;
export const API_LOV_MENU_PRIVILEGES: string = `${prefix}/lov/menuPrivileges/`;
export const API_LOV_MENU_PRIVILEGES_BY_ROLE: string = `${prefix}/lov/menuPrivilegesByRole/`;
export const API_LOV_MENU_LIST: string = `${prefix}/lov/menuList/`;
export const API_LOV_NOMENCLATURE: string = `${prefix}/lov/nomenclature/`;
export const API_LOV_COMMON_DATA: string = `${prefix}/lov/commonData/`;
export const API_LOV_SPECIAL_CHARACTER: string = `${prefix}/lov/specialCharacter/`;
export const API_LOV_LEVEL_DETAIL: string = `${prefix}/lov/levelDetail/`;
export const API_LOV_APP_CONFIG: string = `${prefix}/lov/config/`;
export const API_LOV_PLANT: string = `${prefix}/lov/plant/`;
export const API_LOV_USER: string = `${prefix}/lov/user/`;
export const API_LOV_LINE_MASTER: string = `${prefix}/lov/line/`;
export const API_LOV_PRODUCT_CODE: string = `${prefix}/lov/productCode/`;
export const API_LOV_COMPANY_PRE_FIX: string = `${prefix}/lov/companyPreFix/`;
export const API_LOV_BUSINESS_RULE: string = `${prefix}/lov/businessRule/`;
export const API_LOV_PRODUCT_COMPANY_MAPPING: string = `${prefix}/lov/productCompanyMapping/`;
export const API_LOV_PRODUCTION_MASTER: string = `${prefix}/lov/productionMaster/`;
export const API_LOV_PRODUCTION_MAP_CONFIG: string = `${prefix}/lov/productionMapConfig/`;
export const API_LOV_HIERARCHY_MST: string = `${prefix}/lov/hierarchyMst/`;
export const API_LOV_PACKING_LINE_DATA: string = `${prefix}/lov/packingLineData/`;
export const API_LOV_EPCIS_EVENT: string = `${prefix}/lov/epcisEvents/`;
export const API_GET_LOV_MST: string = `${prefix}/lov/mst/`;
export const API_ADD_LOV_MST: string = `${prefix}/lov/addLovMst/`;
export const API_UPDATE_LOV_MST: string = `${prefix}/lov/updateLovMst/`;
export const API_GET_THRESHOLDPOOL_BYTRADETYPE = `${prefix}/lov/getThresholdPoolByTradeType/`
export const API_GET_LOV_BY_DEPENDANT_PARENT = `${prefix}/lov/getByDependantParent/`
export const API_LOV_ROLE_ONLY_READ_RIGHT: string = `${prefix}/lov/rolesOnlyReadRight/`;
export const API_GET_LOV_VAL_BY_LOV_MST = `${prefix}/lov/getLovValByLovMstId/`



export const API_LOV_MST: string = `${prefix}/lov/lovMst/`;

/** LOV end */

// Archive Batch Data
export const API_ARCHIVE_BATCH_LIST_BY_DATE: string = `${prefix}/batch/getArchiveBatchListByDate`;
export const API_ARCHIVING_BATCH_DATA: string = `${prefix}/batch/getArchivingBatchData`;

//Shipment Batch List
export const API_GET_SHIPMENT_LIST: string = `${prefix}/shipment/getShipmentList`;
export const API_GET_CODE_LIST: string = `${prefix}/shipment/getCodeListForShipment`;
export const API_INSERT_SHIPMENTDETAILS: string = `${prefix}/shipment/insertShipmentDetail`;

/** Manage Events */
export const API_MANAGE_EVENTS_GEN_DAVA_FILE: string = `${prefix}/manageEvents/generateDAVARelatedFiles/`;
export const API_MANAGE_EVENTS_GEN_POST_MANNUAL_EVENT: string = `${prefix}/manageEvents/generateAndPostMannualEvent/`;
export const API_MANAGE_EVENTS_RELATION_SERVICE: string = `${prefix}/manageEvents/relationService/`;
export const API_MANAGE_EVENTS_GEN_POST_TRACELINK_EVENT: string = `${prefix}/manageEvents/generateAndPostTracelinkEventFile/`;
export const API_MANAGE_EVENTS_GEN_POST_EPCIS_EVENT_FILE: string = `${prefix}/manageEvents/generateAndPostEPCISEventFile/`;


/** EPCIS Master */
export const API_EPCIS_MASTER_LIST: string = `${prefix}/epcismaster/list`;
export const API_EPCIS_MASTER_FILTER_LIST: string = `${prefix}/epcismaster/filterd/list`;
export const API_EPCIS_MASTER_GET: string = `${prefix}/epcismaster/get/`;
export const API_EPCIS_MASTER_DOWNLOAD: string = `${prefix}/epcismaster/download/`;
export const API_EPCIS_MASTER_XML_UPLOAD = `${prefix}/epcismaster/xmlupload`;
export const API_EPCIS_MASTER_UPDATE_PROCESSING_STATUS = `${prefix}/epcismaster/updateProcessingStatus`;
export const API_EPCIS_VOCABULARY_LIST: string = `${prefix}/product/epcisVocabulary/list`;
export const API_EPCIS_MASTER_SAMPLE_EPCIS: string = `${prefix}/epcismaster/sampleEPCIS/`;
export const API_EPCIS_MASTER_EXPORT_CSV: string = `${prefix}/epcismaster/exportCsvWithFilter/`;
export const API_AXA_XML_UPLOAD = `${prefix}/epcismaster/axaXmlupload`;
export const API_EPCIS_MASTER_JSON_UPLOAD = `${prefix}/epcismaster/uploadJson`;

/**LOT Master */
export const LOT_MASTER_LIST: string = `${prefix}/lotMaster/list/`;
export const LOT_MASTER_FILTER_LIST: string = `${prefix}/lotMaster/filtered/`;
export const LOT_MASTER_FILTER_LIST_MDLP: string = `${prefix}/lotMaster/mdlpLot/`;
export const LOT_MASTER_GET: string = `${prefix}/lotMaster/get/`;
export const LOT_MASTER_GET_TRADE_VALUE_COUNT: string = `${prefix}/lotMaster/getTradeValueWiseCount/`;
export const LOT_MASTER_ACCEPT_REJECT_COUNT: string = `${prefix}/lotMaster/acceptRejectCount/`;
export const LOT_MASTER_ACCEPT_REJECT_COUNT_FOR_MYLAN_API_PRINTER: string = `${prefix}/lotMaster/getAcceptRejectCountForMylanApiPrinter/`;
export const API_REPORT_RELATIONSHIP_RP: string = `${prefix}/lotMaster/relationshipReport`;
export const API_REPORT_RELATIONSHIP_CSV: string = `${prefix}/lotMaster/downloadCSV`;
export const API_LOT_ACCEPTED_CODE_DOWNLOAD: string = `${prefix}/lotMaster/downloadAcceptedCode`;
export const API_LOT_REJECTED_CODE_DOWNLOAD: string = `${prefix}/lotMaster/downloadRejectedCode`;
export const API_LOT_SAMPLE_CODE_DOWNLOAD: string = `${prefix}/lotMaster/downloadSampleCode`;
export const API_LOT_EVENT_TRIGGER_DETAILS: string = `${prefix}/lotMaster/getEventTriDetails`;
export const API_LOT_MDLP_XML_DOWNLOAD: string = `${prefix}/lotMaster/mdlpXMLDownload`;
export const API_IVEDA_LOT_EXPORT: string = `${prefix}/lotMaster/ivedaExportLotMaster`;
export const API_LOT_MASTER_EXPORT_CSV: string = `${prefix}/lotMaster/exportCsvWithFilter`;
export const API_LOT_MASTER_BY_LOT_NUMBER: string = `${prefix}/lotMaster/getLotMasterByLotNumber`;
export const API_LOT_MASTER_BY_LOT_NUMBER_FOR_DYNAMICE_SCAN: string = `${prefix}/lotMaster/getLotMasterByLotNumberForDynamicScanRepack`;
export const API_LOT_MASTER_BY_SSCC_CODE: string = `${prefix}/lotMaster/getLotMasterBySSCCCode`;
export const API_LOT_MASTER_BY_ID: string = `${prefix}/lotMaster/getLotMasterById`;
export const LOT_MASTER_FILTER_LIST_FOR_REPACK: string = `${prefix}/lotMaster/lotForRepack/`;
export const LOT_MASTER_FILTER_LIST_EMVO: string = `${prefix}/lotMaster/emvoLot/`;
export const LOT_MASTER_GET_TRADE_VALUE_FOR_PRINT: string = `${prefix}/lotMaster/getTradeValueByTradeTypeForPrintCode`;
export const LOT_MASTER_ALLOCATE_CODE: string = `${prefix}/lotMaster/allocateCode`;
export const LOT_MASTER_GET_CODE: string = `${prefix}/lotMaster/getCode`;
export const LOT_MASTER_CODE_PRINT_ACTION: string = `${prefix}/lotMaster/codePrintAction`;
export const LOT_MASTER_RE_PRINT_CODE_LIST: string = `${prefix}/lotMaster/getRePrintCodeList`;
export const LOT_MASTER_GET_CODE_COUNT_BIND_WITH_TRADE_VALUE: string = `${prefix}/lotMaster/getCodeBindWithTradeValueAndLot`;
export const LOT_MASTER_EXPORT_CSV: string = `${prefix}/lotMaster/downloadTotalOrPrintedCode`;
export const LOT_MASTER_GET_TRADE_TYPE_BY_LOT: string = `${prefix}/lotMaster/getTradeTypeByLotId`;
export const LOT_MASTER_VERIFY_CODE_SCAN_ACTION: string = `${prefix}/lotMaster/verifyCodeScanAction`;
export const LOT_MASTER_CODE_SCAN_ACTION: string = `${prefix}/lotMaster/codeScanAction`;
export const LOGISTIC_USER_CODE_SCAN_ACTION: string = `${prefix}/lotMaster/codeScanActionForLogisticUser`;
export const LOT_MASTER_GET_LOT_FROM_SCAN_CODE: string = `${prefix}/lotMaster/getLotFromScanCode`;
export const LOT_MASTER_GET_TRADE_VALUE_FROM_SCAN_CODE_COMPANY_PREFIX: string = `${prefix}/lotMaster/getTradeValueForCompanyPrefixFromScanCode`;
export const API_LOT_MASTER_GET_CODE_BY_ID: string = `${prefix}/lotMaster/getCodeListForLot`;
export const API_LOT_MASTER_GET_CODE_BY_ID_VOID: string = `${prefix}/lotMaster/getCodeListForLotVoid`;
export const API_LOT_MASTER_GET_EXTENSION_DETAIL: string = `${prefix}/lotMaster/getLotMasterExtensionDetails`;
export const LOT_MASTER_GET_TRADE_VALUE_BY_LOT: string = `${prefix}/lotMaster/tradeValueList`;
export const LOT_MASTER_ALLOCATE_SSCC_CODE: string = `${prefix}/lotMaster/allocateSSCCCode`;
//mylan API
export const MYLAN_LOT_MASTER_GET_TRADE_VALUE_FOR_PRINT: string = `${prefix}/lotMaster/getMylanTradeValueByTradeTypeForPrintCode`;
export const MYLAN_LOT_MASTER_ALLOCATE_CODE: string = `${prefix}/lotMaster/mylanAllocateCode`;
export const MYLAN_LOT_MASTER_GET_CODE: string = `${prefix}/lotMaster/mylanGetCode`;
export const MYLAN_LOT_MASTER_GET_CODE_BY_TRADE_TYPE_VALUE_AND_LOT: string = `${prefix}/lotMaster/mylanGetCodeByTradeTypeTradeValueAndLot`;
export const MYLAN_LOT_MASTER_CODE_PRINT_ACTION: string = `${prefix}/lotMaster/mylanCodePrintAction`;
export const MYLAN_LOT_MASTER_RE_PRINT_CODE_LIST: string = `${prefix}/lotMaster/mylanGetRePrintCodeList`;
export const MYLAN_LOT_MASTER_GET_CODE_COUNT_BIND_WITH_TRADE_VALUE: string = `${prefix}/lotMaster/mylanGetCodeBindWithTradeValueAndLot`;
export const MYLAN_LOT_MASTER_GET_TRADE_TYPE_BY_LOT: string = `${prefix}/lotMaster/mylanGetTradeTypeByLotId`;
export const MYLAN_LOT_MASTER_EXPORT_CSV: string = `${prefix}/lotMaster/mylanDownloadTotalOrPrintedCode`;
export const MYLAN_LOT_MASTER_GET_LOT_FROM_SCAN_CODE: string = `${prefix}/lotMaster/mylanGetLotFromScanCode`;
export const MYLAN_LOT_MASTER_GET_TRADE_VALUE_FROM_SCAN_CODE_COMPANY_PREFIX: string = `${prefix}/lotMaster/mylanGetTradeValueForCompanyPrefixFromScanCode`;
export const MYLAN_API_TRACE_IN_LABEL_PRINT: string = `${prefix}/repack/mylanPrint/`;
export const MYLAN_API_SAMPLE_LABEL_PRINT: string = `${prefix}/repack/mylanSamplePrint/`;
export const MYLAN_LOT_MASTER_VERIFY_CODE_SCAN_ACTION: string = `${prefix}/lotMaster/mylanVerifyCodeScanAction`;
export const MYLAN_LOT_MASTER_CODE_SCAN_ACTION: string = `${prefix}/lotMaster/mylanCodeScanAction`;
export const MYLAN_API_LOT_CREATE_VOID_DATA: string = `${prefix}/lotMaster/getPrintedAndNonPrintedCodeWhileVoidAction`;
export const MYLAN_APPLIED_FOR_PRINT_STATUS_COUNT: string = `${prefix}/lotMaster/getMylanAppliedForPrintStatusCountForPrintCode`;
export const MYLAN_CHANGE_PRINT_STATUS: string = `${prefix}/lotMaster/changeMylanPrintStatus`;

//please Do not change below API_LOT_MDLP_DOWNLOAD need to improve code.
export const API_LOT_MDLP_DOWNLOAD: string = `${prefix}/lotMaster/XMLDownload`;
export const API_LOT_CREATE_VOID_DATA: string = `${prefix}/lotMaster/createVoidData`;
export const LOT_MASTER_FILTER_LIST_BRANDOWNER: string = `${prefix}/lotMaster/filteredBrandOwner`;
export const API_LOT_RE_TRIGGER_EVENT: string = `${prefix}/lotMaster/regenerateEvent`;
export const API_LOT_CREATE: string = `${prefix}/lotMaster/createLot`;
export const API_LOT_EDIT: string = `${prefix}/lotMaster/editLot`;



// export const LOT_MASTER_DOWNLOAD: string = `${prefix}/lotMaster/download/';

/**Dashboard */
export const API_DASHBOARD: string = `${prefix}/dashboard/generateChart/`;
export const API_DASHBOARD_EPCISCOUNT: string = `${prefix}/dashboard/epcisCount/`;
export const API_DASHBOARD_COUNT: string = `${prefix}/dashboard/lotProductSnInboundCount/`;

/**Repack */
export const API_AGGREGATE_CODE_LIST: string = `${prefix}/repack/getAggregateCode/list`;
export const API_DEAGGREGATE_CODE_LIST: string = `${prefix}/repack/getDeAggregateCode/list`;
export const API_AGGREGATE_CODE: string = `${prefix}/repack/aggregateCode`;
export const API_DEAGGREGATE_CODE: string = `${prefix}/repack/deAggregateCode`;
export const API_DEAGGREGATE_PARENT: string = `${prefix}/repack/deAggregateParent`;
export const API_GET_TRADE_VALUE_WITH_OUT_SELLABEL: string = `${prefix}/repack/withoutSellableLevel/list`;
export const API_GET_TRADE_VALUE_WITHOUT_SSCC: string = `${prefix}/repack/tradeValueHavingParent/list`;
export const API_GET_PARENTCODE_VALUE: string = `${prefix}/repack/parentCode/list`;
export const API_GET_CHECK_CODE: string = `${prefix}/repack/checkCode/deAggregation`;
export const API_GET_CHECK_CODE_IF_EXIST: string = `${prefix}/repack/checkCodeIfExist`;
export const API_AGGREGATE_CODE_NEW: string = `${prefix}/repack/newParent`;
export const API_GET_COMPANY_PREFIX_ONLY: string = `${prefix}/repack/onlyCompanyPrefix`;
export const API_GET_COMPANY_PREFIX_CHILD: string = `${prefix}/repack/companyPrefixChild`;
export const API_GET_COMPANY_PREFIX_CHILD_SN_MASTER: string = `${prefix}/repack/companyPrefixChildSnMaster`;
export const API_GET_CHECK_COMPANY_PREFIX_CODE: string = `${prefix}/repack/checkCompanyPrefixCode`;
export const API_GET_BY_COMPANY_PREFIX: string = `${prefix}/repack/checkByCompanyPrefixChild`;
export const API_AGGREGATE_WITH_COMAPNY_PREFIX: string = `${prefix}/repack/companyPrefixAggregation`;
export const API_DEAGGREGATE_CODE_LIST_COMPANY_PREFIX: string = `${prefix}/repack/getDeAggregatedCompanyPrefix`;
export const API_GET_PARENTCODE_VALUE_LOT_SSCC: string = `${prefix}/repack/parentCodeCompanyPrefix`;
export const API_GET_CHECK_THRESHOLD_CODE: string = `${prefix}/repack/checkThresholdPoolCode`;
export const API_AGGREGATE_WITH_THRESHOLD: string = `${prefix}/repack/thresholdAggregation`;
export const API_DEAGGREGATE_CODE_LIST_COMPANY_PREFIX_CHILD_GTIN: string = `${prefix}/repack/getDeAggregatedCompanyPrefixWithLot`;
export const API_DEAGGREGATE_CODE_BY_COMPANY_PREFIX: string = `${prefix}/repack/deAggregateCodeBYCompanyPrefix`;
export const API_GET_CHECK_CODE_FOR_COMPANY_PREFIX_WITH_LOT: string = `${prefix}/repack/checkCode/withLotSSCC`;
export const API_DEAGGREGATE_COMPANY_PREFIX: string = `${prefix}/repack/deAggregateCompanyPrefix`;
export const API_GET_LABEL_EXE: string = `${prefix}/repack/getFile`;



/**Trace IN**/
export const API_TRACE_IN_SN_INBOUND: string = `${prefix}/snInbound/traceCode`;
export const API_TRACE_IN_SN_OUT_BOUND: string = `${prefix}/snOutbound/traceCode`;
export const API_TRACE_IN_SN_MASTER_DETAILS: string = `${prefix}/lotMaster/traceCode`;
export const API_TRACE_IN_LOT_NUMBER: string = `${prefix}/lotMaster/lotNumber`;
export const API_TRACE_IN_SN_HISTORY: string = `${prefix}/snManager/snHistory`;
export const API_TRACE_IN_PLANT: string = `${prefix}/plant/tracePlant`;
export const API_TRACE_IN_SN_MANAGER_TYPE: string = `${prefix}/snManager/snManagerType`;
export const API_TRACE_IN_LABEL_PRINT: string = `${prefix}/repack/print/`;
export const API_TRACE_IN_LABEL_PRINT_DATA: string = `http://localhost:12349/print/printLabel`;
export const GET_PRINT_CONNECTED_STATUS: string = `http://localhost:12349/print/printCheck`;
export const MYLAN_PRODUCT_SPECIMEN_PRINT_LABEL: string = `${prefix}/repack/mylanProductSpecimenPrintLabel`;

/**DISPOSITION Code */
export const API_DISPOSITION_CODE_SEARCH: string = `${prefix}/disposition/list/`;
export const SN_MASTER_CHECK_CHILDREN: string = `${prefix}/disposition/checkChildren`;
export const SN_MASTER_ACTION: string = `${prefix}/disposition/action`;
export const API_DISPOSITION_GET_TRADE_VALUE: string = `${prefix}/disposition/tradeValueList`;
export const API_DISPOSITION_GET_COMPANY_PREFIX: string = `${prefix}/disposition/getCompanyPrefixHavingGTINChild/`;
export const API_DISPOSITION_GET_PARENT_COMPANY_PREFIX: string = `${prefix}/disposition/getCompanyPrefixHavingCompanyPrefixChild/`;
export const SN_MASTER_ACTION_COMPANY_PREFIX: string = `${prefix}/disposition/actionCompanyPrefix`;
export const API_SN_MASTER_STATUS_MAPPINGS: string = `${prefix}/disposition/getSnMasterStatusMappings`;

/**Event Trigger **/
export const API_GET_SN_MASTER_EVENT_TRIGGER: string = `${prefix}/eventTrigger/getSnMasterList`;
export const API_ADD_SN_MASTER_EVENT_TRIGGER: string = `${prefix}/eventTrigger/eventTrigger`;
export const API_GET_COMPANY_PREFIX_SN_MASTER_EVENT_TRIGGER: string = `${prefix}/eventTrigger/getCompanyPrefixSnMasterList`;
export const API_ADD_SN_MASTER_EVENT_TRIGGER_COMPANY_PREFIX: string = `${prefix}/eventTrigger/eventTriggerCompanyPrefix`;
export const API_GET_COMPANY_PREFIX_EVENT_TRIGGER_WITH_LOT: string = `${prefix}/eventTrigger/getSNMasterListCompanyPrefixWithLot`;
export const API_GET_EVENT_TRIGGER_BY_IDS: string = `${prefix}/eventTrigger/eventTriggerByIds`;
export const API_GET_VERIFICATION_CODE_DETAIL: string = `${prefix}/emvo/getVerificationCodeDetail`;


/**Shipment */
export const API_SHIPMENT_MASTER_LIST: string = `${prefix}/shipment/filtered/`;
export const API_SHIPMENT_MASTER_CODE_LIST: string = `${prefix}/shipment/getCodeDetails/`;
export const API_SHIPMENT_MASTER_ATTACHED_LOT: string = `${prefix}/shipment/getLotDetails/`;
export const API_SHIPMENT_MASTER_ADD: string = `${prefix}/shipment/add/`;
export const API_SHIPMENT_MASTER_UPDATE_SHIPMENT: string = `${prefix}/shipment/updateShipment/`;
export const API_SHIPMENT_VOID: string = `${prefix}/shipment/voidShipment/`;
export const API_SHIPMENT_POST: string = `${prefix}/shipment/postShipment/`;
export const API_SHIPMENT_SN_MASTER_GTIN_CODE: string = `${prefix}/shipment/byLot/`;
export const API_SHIPMENT_BY_SSCC_AND_LOT: string = `${prefix}/shipment/bySSCCANDByLot/`;
export const API_SHIPMENT_SN_MASTER_SSCC_CODE: string = `${prefix}/shipment/getSNMasterForSSCC/`;
export const API_GET_COMPANY_PREFIX_TRADE_VALUE: string = `${prefix}/shipment/getCompanyPrefixTradeValue/`;
export const API_GET_SHIPMENT_COMPANY_PREFIX_ONLY: string = `${prefix}/shipment/onlyCompanyPrefix`;
export const API_GET_SHIPMENT_COMPANY_PREFIX_CHILD: string = `${prefix}/shipment/companyPrefixChild`;
export const API_GET_SHIPMNET_TRADE_VALUE_WITHOUT_SSCC: string = `${prefix}/shipment/tradeValueHavingParent/list`;
export const API_GET_SHIPMENT_EXTENSION_LIST: string = `${prefix}/shipment/extension/list`;
export const API_SHIPMENT_EXPORT_CSV: string = `${prefix}/shipment/exportCsvWithFilter`;



/** Warehouse */
export const API_GET_WAREHOUSE_EXTENSION_LIST: string = `${prefix}/warehouse/extension/list`;
export const API_GET_WAREHOUSE_TRADE_VALUE_WITHOUT_SSCC: string = `${prefix}/warehouse/tradeValueHavingParent/list`;
export const API_GET_WAREHOUSE_COMPANY_PREFIX_ONLY: string = `${prefix}/warehouse/onlyCompanyPrefix`;
export const API_GET_WAREHOUSE_COMPANY_PREFIX_CHILD: string = `${prefix}/warehouse/companyPrefixChild`;
export const API_WAREHOUSE_SHIPMENT_MASTER_ATTACHED_LOT: string = `${prefix}/warehouse/getLotDetails/`;
export const API_WAREHOUSE_MASTER_CODE_LIST: string = `${prefix}/warehouse/getCodeDetails/`;
export const API_WAREHOUSE_MASTER_LIST: string = `${prefix}/warehouse/filtered/`;
export const API_SHIPMENT_RECEIPT_ADD: string = `${prefix}/shipment/addReceipt/`;
export const API_WAREHOUSE_DISPATCH_ADD: string = `${prefix}/warehouse/addDispatch/`;
export const API_WAREHOUSE_DISPATCH_RECALL: string = `${prefix}/warehouse/recallShipment/`;
export const API_WAREHOUSE_DISPATCH_LIST: string = `${prefix}/warehouse/filteredWarehouseDispatch/`;
export const LOT_MASTER_FILTER_LIST_BRANDOWNER_FOR_RECEIPT: string = `${prefix}/lotMaster/filteredBrandOwnerDispatch`;
export const API_WAREHOUSE_DISPATCH_SN_MASTER_GTIN_CODE: string = `${prefix}/warehouse/byLot/`;
export const API_WAREHOUSE_DISPATCH_BY_SSCC_AND_LOT: string = `${prefix}/warehouse/bySSCCANDByLot/`;
export const API_WAREHOUSE_DISPATCH_SN_MASTER_SSCC_CODE: string = `${prefix}/warehouse/getSNMasterForSSCC/`;
export const API_WAREHOUSE_RECEIPT_ACCEPT: string = `${prefix}/warehouse/acceptWarehouseReceipt/`;
export const API_WAREHOUSE_RECEIPT_LIST: string = `${prefix}/warehouse/filteredWarehouseReceipt/`;
export const API_WAREHOUSE_RECEIPT_RETURN_AFTER_ACCEPT: string = `${prefix}/warehouse/returnReceiptAfterAccept/`;
//**Label Master */
export const API_LABEL_MASTER_LIST: string = `${prefix}/labelmaster/list/`;
export const API_ADD_LABEL_MASTER: string = `${prefix}/labelmaster/add/`;
export const API_LABEL_MASTER_UPDATE: string = `${prefix}/labelmaster/update/`;
export const API_LABEL_MASTER_ACTIVE: string = `${prefix}/labelmaster/active/`;
export const API_LABEL_MASTER_DEACTIVE: string = `${prefix}/labelmaster/deActive/`;
export const API_LABEL_FILE_DOWNLOAD: string = `${prefix}/labelmaster/download/`;
export const API_LABEL_MASTER_ACTIVE_LIST: string = `${prefix}/labelmaster/activeLabels/`;

/**Dynamic Form */
export const API_DYNAMIC_FORM_LIST: string = `${prefix}/dynamicForm/list/`;
export const API_DYNAMIC_FORM_ADD: string = `${prefix}/dynamicForm/add/`;
export const API_DYNAMIC_FORM_UPDATE: string = `${prefix}/dynamicForm/update/`;
export const API_DYNAMIC_FORM_TABLE_BY_NAME: string = `${prefix}/dynamicForm/byName/`;
export const API_DYNAMIC_FORM_TABLE_LIST: string = `${prefix}/dynamicForm/dynamicFormTableList/`;

/**MDLP Rule Config */

export const API_GET_ALL_RULE_CONFIG_LIST = `${prefix}/ruleConfig/list`;
export const API_POST_UPDATE_RULE_CONFIG = `${prefix}/ruleConfig/update`;
export const API_EMVO_CALLBACK_SUBSCRIPTION = `${prefix}/ruleConfig/setEMVOSubscription`;
export const API_EMVO_ALERT_SUBSCRIPTION = `${prefix}/ruleConfig/setEMVOAlertSubscription`;
export const API_EMVO_CONNECTION_TEST = `${prefix}/ruleConfig/getEmvoConnectionTest`;
// export const API_DYNAMIC_FORM_TABLE_LIST: string = `${prefix}/dynamicForm/dynamicFormTableList/`;

/**Notifications */
export const API_UNREAD_NOTIFICATIONS_LIST: string = `${prefix}/notifications/unreadList`;
export const API_NOTIFICATIONS_FILTER_LIST: string = `${prefix}/notifications/filterd/list`;
export const API_MARK_NOTIFICATION: string = `${prefix}/notifications/markAsRead/`;
export const API_ADD_NOTIFICATION: string = `${prefix}/notifications/add/`;
export const API_LOAD_NOTIFICATION_TEMPLATE: string = `${prefix}/notifications/loadMailTemplate/`;
export const API_ADD_NOTIFICATION_TEMPLATE: string = `${prefix}/notifications/addTemplate/`;
export const API_LOAD_NOTIFICATION_MAIL_CONFIG: string = `${prefix}/notifications/loadMailConfig/`;
export const API_UNREAD_NOTIFICATION: string = `${prefix}/notifications/viewUnRead/`;
export const API_READ_ALL_NOTIFICATION: string = `${prefix}/notifications/readAll/`;


/**Error Details */
export const API_ERROR_DETAILS_LIST: string = `${prefix}/errorDetails/list/`;

/**External API */
export const UPLOAD_EXCEL_PRODUCT: string = `${prefix}/external/addProductFromExcel`;
export const EXT_TRACE_PLANT: string = `${prefix}/external/tracePlant`;
export const EXT_LOV_MST: string = `${prefix}/external/lovMst`;
export const EXT_TRACE_IN_SN_MANAGER_TYPE: string = `${prefix}/external/snManagerType`;
export const EXT_API_TRACE_IN_LOT_NUMBER: string = `${prefix}/external/lotNumber`;
export const EXT_TRACE_IN_SN_MASTER_DETAILS: string = `${prefix}/external/snMasterHistory`;
export const EXT_TRACE_IN_SN_HISTORY: string = `${prefix}/external/snHistory`;
export const EXT_CHECK_CALL_LIMIT: string = `${prefix}/external/isCallLimitReached`;
export const EXT_VERIFY_PRODUCT: string = `${prefix}/external/verifyProduct`;
export const EXT_GET_USER_BY_PHONE: string = `${prefix}/external/getUserByPhone`;
export const EXT_UPDATE_USER: string = `${prefix}/external/updateUser`;
export const EXT_LOAD_EXTRA_DETAIL: string = `${prefix}/external/loadExtraDetails`;
export const EXT_IS_CAPTCHA_ENABLE: string = `${prefix}/external/isCaptchaEnable`;
export const EXT_TRACE_IN_LOT_MASTER_DETAILS: string = `${prefix}/external/lotDetails`;

/**Manage Market */
export const API_GET_MANAGE_MARKET_LIST: string = `${prefix}/manageMarket/list`;
export const API_GET_MANAGE_MARKET_STATUS: string = `${prefix}/manageMarket/changeStatus/`;
export const API_POST_SAVE_MANAGE_MARKET :string = `${prefix}/manageMarket/save/`;
export const API_POST_UPDATE_MANAGE_MARKET :string = `${prefix}/manageMarket/update/`;

/**EMVO */
export const API_EMVO_PACKED_DATA_CODE_LIST: string = `${prefix}/emvo/packedDataCodeList`;
export const API_EMVO_MARKET_LIST_FOR_RECALL_BATCH: string = `${prefix}/emvo/marketListForRecallBatch`;
export const API_EMVO_SN_LIST: string = `${prefix}/emvo/getSnListToEMVOOperation`;
export const API_EMVO_GENERATE_AND_POST_EVENT: string = `${prefix}/emvo/addEMVOGenerateAndPostEvent`;

export const API_EMVO_PRODUCT_LIST: string = `${prefix}/emvo/emvoProductList`;
export const API_EMVO_SUBMIT_PRODUCT_LIST: string = `${prefix}/emvo/submitProductList`;
export const API_EMVO_WITHDRAWN_PRODUCT_LIST: string = `${prefix}/emvo/withdrawnProductList`;
export const API_EMVO_LOT_MASTER_FILTER_LIST: string = `${prefix}/emvo/lotMasterList`;
export const API_EMVO_PRODUCT_LOT_MASTER_FILTER_LIST: string = `${prefix}/emvo/getEMVOLotMasterListFilterByProduct`;
export const API_EMVO_SUBMIT_PRODUCT_PACK_DATA: string = `${prefix}/emvo/submitProductPackData`;
export const API_EMVO_SN_UPDATE_CODE_LIST: string = `${prefix}/emvo/snStatusUpdateCodeList`;
export const API_POST_EMVO_SN_UPDATE_CODE_LIST: string = `${prefix}/emvo/updateStatus`;
export const API_POST_EMVO_BATCH_RECALL: string = `${prefix}/emvo/batchRecall`;
export const API_POST_EMVO_PACK_UPDATE_CODE_LIST: string = `${prefix}/emvo/updatePackState`;
export const API_EMVO_MARKET_PRODUCT_MAPPING_LIST: string = `${prefix}/emvo/getEmvoMarketProductList`;
export const API_EMVO_EVENT_TRIGGER_LIST_BY_LOT: string = `${prefix}/emvo/eventTriggerListByLot/`;
export const API_EMVO_STATUS_MAPPING: string = `${prefix}/emvo/emvoStatusMappings`;
export const API_EMVO_VERIFICATION: string = `${prefix}/emvo/verification`;
export const API_EMVO_PACKED_PACK_STATE_UPDATE: string = `${prefix}/emvo/getProductPackedStateUpdateCodeList`;
export const API_EMVO_REPORT: string = `${prefix}/emvo/report`;
export const API_EMVO_GET_MDLP_EVENT_REPORT_DATA_CSV: string = `${prefix}/emvo/getEMVOCSVData`;
export const API_EMVO_EVENT_TRIGGER_REPROCESS: string = `${prefix}/emvo/reprocess/`;
export const API_EMVO_SUBMIT_REPORT_REQUEST: string = `${prefix}/emvo/submitReportRequest/`;
export const API_EMVO_EVENT_TRIGGER_LIST_BY_LOT_FOR_REPORT: string = `${prefix}/emvo/eventTriggerListByLotForReport/`;
export const API_GET_GENUINE_CODE_LIST: string = `${prefix}/repack/getCodeList`;
export const API_GET_EMVO_COMPANY_PREFIX_ONLY: string = `${prefix}/emvo/onlyCompanyPrefix`;
export const API_GET_GENUINE_PARENT_CODE_LIST: string = `${prefix}/repack/getParentCodeList`;

export const API_AS2INBOX_LIST: string = `${prefix}/as2Receiver/list`;
export const API_AS2_CONTENT_FILE: string = `${prefix}/as2Receiver/contentFile/`;
export const API_AS2_MSG_FILE: string = `${prefix}/as2Receiver/msgFile/`;

export const API_PRODUCT_JSON_LIST: string = `${prefix}/product/productJsonList`;
export const API_PRODUCT_RESET_JSON: string = `${prefix}/product/resetJson`;

export const API_TRACE_HISTORY_LIST: string = `${prefix}/dqsa/verificationHistoryList`;
export const API_VERIFY_PRODUCT: string = `${prefix}/dqsa/verifyProduct`;
export const API_GET_LOYALTY_TRANS_LIST_BY_PHONE: string = `${prefix}/loyalty/getLoyaltyTransListByPhone`;
export const API_GET_LOYALTY_TRANS_LIST_BY_PRODUCT_ID: string = `${prefix}/loyalty/getLoyaltyTransListByProductId`;
export const GET_BATCH_LIST_FOR_TRANSACTION_STATUS = `${prefix}/regulatoryCompliance/getBatchListForTransactionStatus/`;
export const GET_BATCH_LIST_FOR_SFDA_OPERATION = `${prefix}/regulatoryCompliance/getBatchListForSFDAOperation/`;
export const GET_CODE_LIST_FOR_SFDA_OPERATION = `${prefix}/regulatoryCompliance/getSNListToSFDAOperation/`;
export const UPDATE_SFDA_EVENT_STATUS = `${prefix}/regulatoryCompliance/updateSFDAEventStatus/`;
export const TRACE_GOODS = `${prefix}/regulatoryCompliance/traceGoods/`;
export const TRACE_WAREHOUSE_GOODS = `${prefix}/regulatoryCompliance/traceWarehouseGoods/`;
export const VERIFY_SSCC_FOR_SFDA_OPERATION = `${prefix}/regulatoryCompliance/verifySSCCCodeForSFDAOperation/`;
export const GET_CHILD_DATA_LIST = `${prefix}/regulatoryCompliance/getChildSNMasterList/`;
export const GET_CHILD_SN_MASTER_FOR_LOT = `${prefix}/regulatoryCompliance/getSNMasterListForLot/`;
export const GET_EVENT_TRIGGER_LIST = `${prefix}/regulatoryCompliance/getEventTriggerList/`;
export const GET_EVENT_TRIGGER_FILE_LIST = `${prefix}/regulatoryCompliance/getEventTriggerFileList/`;
export const API_SFDA_DOWNLOAD_FILE = `${prefix}/regulatoryCompliance/downloadFile/`;
export const API_SFDA_RESEND_FILE_DATA = `${prefix}/regulatoryCompliance/reprocessSFDAFile/`;
export const API_GET_LOT_LIST_FROM_EVENT_TRIGGER = `${prefix}/regulatoryCompliance/getLotListFromEventTrigger/`;
export const GET_EVENT_TRIGGER_CODE_LIST = `${prefix}/regulatoryCompliance/getEventTriggerCodeList/`;
export const GET_EVENT_TRIGGER_EXTENSION_LIST = `${prefix}/regulatoryCompliance/getEventTriggerExtensionList/`;

export const API_GET_SN_HISTORY = `${prefix}/regulatoryCompliance/getSnHistory/`;
export const API_GET_SN_MASTER_DETAILS = `${prefix}/regulatoryCompliance/getSnMasterDetails`;
export const API_GET_EVENT_TRIGGER = `${prefix}/regulatoryCompliance/getEventTrigger/`;
export const API_VERIFY_SN_FOR_RETURN_CONFIRMATION = `${prefix}/regulatoryCompliance/verifySNForReturnConfirmation/`;
export const API_UPDATE_UNLOCK_STATUS = `${prefix}/regulatoryCompliance/unlockSNStatus`;
export const API_REUPLOAD_EPCIS = `${prefix}/regulatoryCompliance/reuploadEPCIS`;
export const API_PROCESS_MANUAL_EVENT  =`${prefix}/regulatoryCompliance/processSFDAManualEvent`;
export const GET_TRADE_VALUE_FOR_SUPPLY_CHAIN = `${prefix}/regulatoryCompliance/getTradeValueListForSupplyChain`;
export const GET_BATCH_LIST_FOR_CHECK_STATUS = `${prefix}/regulatoryCompliance/getBatchListForCheckStatus/`;
export const GET_CODE_LIST_FOR_CHECK_STATUS = `${prefix}/regulatoryCompliance/getCodeListToCheckStatus/`;
export const GET_CODE_LIST_FOR_DEAGG_BY_LOT = `${prefix}/regulatoryCompliance/getCodeListForDeaggByLot/`;

export const GET_CODE_LIST_FOR_DEAGG_BY_SSCC: string = `${prefix}/regulatoryCompliance/getCodeListForDeaggBySSCC`;

export const GET_CODE_LIST_FOR_DEAGG_BY_GTIN: string = `${prefix}/regulatoryCompliance/getCodeListForDeaggByGTIN`;
export const GET_TRADE_VALUE_LIST_FOR_DEAGGREGATION = `${prefix}/regulatoryCompliance/getTradeValueListForDeaggregation/`;
export const GET_TRADE_VALUE_LIST_FOR_REAGGREGATION = `${prefix}/regulatoryCompliance/getTradeValueListByLotWithDescOrderForReaggregation/`;

export const API_GET_CODELIST_FOR_REAGG_HOMO: string = `${prefix}/regulatoryCompliance/getCodeListForReaggByLot`;

export const API_GET_CODELIST_FOR_REAGG_HETRO: string = `${prefix}/regulatoryCompliance/getCodeListForReaggByMultipleLot`;

/** Warehouse Master Details */
export const API_WAREHOUSE_LIST = `${prefix}/wh_warehouse/list/`;
export const API_WAREHOUSE_GET = `${prefix}/wh_warehouse/get/`;
export const API_WAREHOUSE_ADD = `${prefix}/wh_warehouse/add/`;
export const API_WAREHOUSE_UPDATE = `${prefix}/wh_warehouse/update/`;
export const API_WAREHOUSE_SITE_RETAILER_UPDATE = `${prefix}/wh_warehouse/updateSiteNRetailer/`;
export const API_WAREHOUSE_ACTIVE = `${prefix}/wh_warehouse/active/`;
export const API_WAREHOUSE_DEACTIVE = `${prefix}/wh_warehouse/deactive/`;
export const API_WAREHOUSE_GET_SFDA = `${prefix}/wh_warehouse/getSFDACredential/`;
export const API_WAREHOUSE_LOGO = `${prefix}/wh_warehouse/getWarehouseForReportLOGO/`;
export const API_GET_WAREHOUSE_LIST_BY_USER = `${prefix}/wh_warehouse/getWarehouseMasterListByUser/`;
export const API_GET_RETAILER_FROM_GLN = `${prefix}/wh_warehouse/getRetailerDetails/`;
export const GET_ALL_WAREHOUSE_LIST: string = `${prefix}/wh_warehouse/getAllWareHouseMaster/`;
export const GET_ALL_WAREHOUSE_LOCATION_MASTER_LIST_BY_WH_ID: string = `${prefix}/wh_warehouse/getWarehouseLocationMasterByWarehouseId/`;

/** Compliance Master */
export const GET_ALL_COMPLIANCE_MASTER: string = `${prefix}/warehouseCompliance/getAllCompliance/`;
export const SAVE_COMPLIANCE_MASTER: string = `${prefix}/warehouseCompliance/saveCompliance/`;
export const UPDATE_COMPLIANCE_MASTER: string = `${prefix}/warehouseCompliance/updateCompliance/`;
export const GET_ALL_ACTIVE_COMPLIANCE_MASTER: string = `${prefix}/warehouseCompliance/getAllActiveCompliance/`;

/** Warehouse Master Compliance Extension */
export const API_WH_COMPLIANCE_MASTER_EXTENSION_LIST: string = `${prefix}/warehouseComplianceMasterExtension/byWarehouseComplianceMaster/`;

/** Manage Warehouse Location Master Details */

export const API_WAREHOUSE_LOCATION_MASTER_LIST: string = `${prefix}/warehouseLocation/list/`;
export const API_WAREHOUSE_LOCATION_MASTER_GET: string = `${prefix}/warehouseLocation/get/`;
export const API_WAREHOUSE_LOCATION_MASTER_ADD: string = `${prefix}/warehouseLocation/add/`;
export const API_WAREHOUSE_LOCATION_MASTER_UPDATE: string = `${prefix}/warehouseLocation/update/`;
export const AP_WAREHOUSEI_LOCATION_MASTER_ACTIVE: string = `${prefix}/warehouseLocation/active/`;
export const API_WAREHOUSE_LOCATION_MASTER_DEACTIVE: string = `${prefix}/warehouseLocation/deactive/`;
export const API_WAREHOUSE_LOCATION_MASTER_EXPORT_CSV: string = `${prefix}/warehouseLocation/exportCsvWithFilter/`;


export const GET_CODE_LIST_FOR_SFDA_OPERATION_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getSNListToSFDAOperation/`;
export const UPDATE_SFDA_EVENT_STATUS_MANUFACTURE = `${prefix}/sfdaManufactureOperations/uploadSFDAManufactureEvent`;

export const GET_TRADE_VALUE_FOR_SUPPLY_CHAIN_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getTradeValueListForSupplyChain`;

export const GET_EVENT_TRIGGER_LIST_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getEventTriggerList/`;
export const GET_EVENT_TRIGGER_FILE_LIST_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getEventTriggerFileList/`;
export const API_SFDA_DOWNLOAD_FILE_MANUFACTURE = `${prefix}/sfdaManufactureOperations/downloadFile/`;
export const API_SFDA_RESEND_FILE_DATA_MANUFACTURE = `${prefix}/sfdaManufactureOperations/reprocessSFDAFile/`;
export const API_PROCESS_MANUAL_EVENT_MANUFACTURE  =`${prefix}/sfdaManufactureOperations/processSFDAManualEvent`;
export const API_REUPLOAD_EPCIS_MANUFACTURE  = `${prefix}/sfdaManufactureOperations/reuploadEPCIS`;
export const GET_EVENT_TRIGGER_EXTENSION_LIST_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getEventTriggerExtensionList/`;
export const GET_EVENT_TRIGGER_CODE_LIST_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getEventTriggerCodeList/`;
export const API_GET_LOT_LIST_FROM_EVENT_TRIGGER_MANUFACTURE = `${prefix}/sfdaManufactureOperations/getLotListFromEventTrigger/`;
export const VERIFY_SSCC_FOR_SFDA_OPERATION_MANUFACTURE = `${prefix}/sfdaManufactureOperations/verifySSCCCodeForSFDAOperation/`;

export const API_EPCIS_MASTER_FILTER_LIST_WAREHOUSE: string = `${prefix}/warehouseepcismaster/filterd/list`;
export const API_EPCIS_MASTER_XML_UPLOAD_WAREHOUSE = `${prefix}/warehouseepcismaster/xmlupload`;
export const API_EPCIS_MASTER_LIST_WAREHOUSE: string = `${prefix}/epcismaster/list`;
export const API_EPCIS_MASTER_GET_WAREHOUSE: string = `${prefix}/warehouseepcismaster/get/`;
export const API_EPCIS_MASTER_DOWNLOAD_WAREHOUSE: string = `${prefix}/warehouseepcismaster/download/`;
export const API_EPCIS_MASTER_UPDATE_PROCESSING_STATUS_WAREHOUSE = `${prefix}/warehouseepcismaster/updateProcessingStatus`;
export const API_EPCIS_VOCABULARY_LIST_WAREHOUSE: string = `${prefix}/product/epcisVocabulary/list`;
export const API_EPCIS_MASTER_SAMPLE_EPCIS_WAREHOUSE: string = `${prefix}/warehouseepcismaster/sampleEPCIS/`;
export const API_EPCIS_MASTER_EXPORT_CSV_WAREHOUSE: string = `${prefix}/warehouseepcismaster/exportCsvWithFilter/`;

//PTS Service
export const API_PACKAGE_UPLOAD: string = `${prefix}/packageTransfer/packageUpload/`;
export const API_PACKAGE_DOWNLOAD: string = `${prefix}/packageTransfer/packageDownload/`;
export const API_PACKAGE_QUERY: string = `${prefix}/packageTransfer/packageQuery/`;
export const API_GET_TRANSFER_DETAILS: string = `${prefix}/packageTransfer/list/`;
export const API_GET_SFDA_DISPATCH_TRANSFER_DETAILS: string = `${prefix}/packageTransfer/SFDADispatchlist/`;
export const API_ADD_TRANSFER_DETAILS: string = `${prefix}/packageTransfer/add/`;
export const API_UPDATE_TRANSFER_DETAILS: string = `${prefix}/packageTransfer/update/`;
export const API_COUNTRY_LIST: string = `${prefix}/packageTransfer/countryList/`;
export const API_CITY_LIST: string = `${prefix}/packageTransfer/cityList/`;
export const API_ERROR_LIST: string = `${prefix}/packageTransfer/errorList/`;
export const API_DRUG_LIST: string = `${prefix}/packageTransfer/drugList/`;
export const API_CHECK_STATUS: string = `${prefix}/packageTransfer/checkStatus/`;
export const API_GET_NOTIFICATION_ID_LIST: string = `${prefix}/packageTransfer//getSFDADispatchTransferNotificationIdByLotMstId/`;
export const API_GET_LOTMASTER_LIST_BY_WAREHOUSE: string = `${prefix}/lotMaster/getLotMasterListByWarehouse`;
export const API_DISPATCH_DETAIL_LIST = `${prefix}/packageTransfer/dispatchDetailList`;

/** Manage SSCC Details */
export const API_MANAGE_SSCC_LIST = `${prefix}/wh_warehouse/SSCCList/`;
export const API_ACTIVE_SSCC_LIST = `${prefix}/wh_warehouse/activeSSCCList/`;
export const API_MANAGE_SSCC_GET = `${prefix}/wh_warehouse/SSCCget/`;
export const API_MANAGE_SSCC_ADD = `${prefix}/wh_warehouse/SSCCadd/`;
export const API_MANAGE_SSCC_UPDATE = `${prefix}/wh_warehouse/SSCCupdate/`;
export const API_MANAGE_SSCC_ACTIVE = `${prefix}/wh_warehouse/SSCCActive/`;
export const API_MANAGE_SSCC_DEACTIVE = `${prefix}/wh_warehouse/SSCCDeactive/`;
export const API_GET_ALL_SSCC_LIST = `${prefix}/wh_warehouse/getAllSSCCList/`;
export const API_GET_ALL_SSCC_LIST_WH_ID = `${prefix}/wh_warehouse/getAllSSCCList/ByWarehouseId`;


// Repack Reprint
export const API_GET_REPACK_BATCH = `${prefix}/wh_repack/getBatchListToRepack/`;
export const GET_CODE_LIST_TO_DEAGGREGATE_CODE = `${prefix}/wh_repack/getCodeListToDeaggregate/`;
export const UPDATE_DEAGGREGATE_STATUS = `${prefix}/wh_repack/updateDeaggregateStatus/`;
export const UPDATE_DEAGGREGATE_STATUS_FOR_SSCC = `${prefix}/wh_repack/updateDeaggregateStatusForSSCC/`;
export const API_REPACK_REPRINT_VERIFY_DETAIL = `${prefix}/wh_repack/verifyCodeDetailsForReprint/`;
export const API_REPACK_BATCH_DETAIL = `${prefix}/wh_repack/getLotMasterDetail/`;
export const API_REPACK_PRINT_LABEL = `${prefix}/wh_repack/printLabel/`;
export const API_PRINT_FILE_GET = `${prefix}/wh_repack/getFile/`;
export const API_PRINT_UPDATE_REPRINT_COUNT = `${prefix}/wh_repack/updateReprintCount/`;
export const API_REPACK_PRINT_LABEL_2: string = `http://localhost:12349/print/printLabel`;


export const API_GET_LEVEL_BY_BATCHID = `${prefix}/wh_repack/getById/`;

export const API_GET_CODES_BY_BATCHID = `${prefix}/wh_repack/getCodeById/`;

export const API_GET_LOOKUP_DIRECTORY_LIST: string = `${prefix}/dqsa/getAllLookupDirectory`;
export const API_GET_LOOKUP_DIRECTORY: string = `${prefix}/dqsa/getLookupDirectory`;
export const API_SAVE_LOOKUP_DIRECTORY: string = `${prefix}/dqsa/saveLookupDirectory`;
export const API_UPDATE_LOOKUP_DIRECTORY: string = `${prefix}/dqsa/updateLookupDirectory`;
export const API_UZ_SNMASTER_LIST: string = `${prefix}/uzbekistan/getSnMasterList/`;
export const API_UZ_GENERATE_FILE: string = `${prefix}/uzbekistan/generateFile/`;
export const API_PACK_HOMO_GET_SSCC_NUMBER: string = `${prefix}/wh_repack/generateHomogeneousSSCCNumberAndPrint`;

export const API_PACK_HETRO_GET_SSCC_NUMBER: string = `${prefix}/wh_repack/generateHeterogeneousSSCCNumberAndPrint`;

export const API_UPDATE_CODE_STATUS_SSCC: string = `${prefix}/wh_repack/updateScanCodeStatus`;

export const API_VERIFY_SCANNEDCODE_REAGGREGATE = `${prefix}/wh_repack/verifyScannedCodeToReaggregate/`;
export const UPDATE_REAGGREGATE_STATUS = `${prefix}/wh_repack/generateSSCCReaggregateAndPrint/`;
export const GET_LOT_FOR_HETRO = `${prefix}/wh_repack/getLotMasterListForHetroCode`;
export const REMOVE_LOT_FOR_HETRO = `${prefix}/wh_repack/removeLotInformation`;


export const API_PRODUCT_MASTER_LIST: string = `${prefix}/warehouseProductMaster/list/`;
export const API_PRODUCT_MASTER_ADD: string = `${prefix}/warehouseProductMaster/saveProductMaster/`;
export const API_PRODUCT_MASTER_DEACTIVE: string = `${prefix}/warehouseProductMaster/deactive/`;
export const API_PRODUCT_MASTER_ACTIVE: string = `${prefix}/warehouseProductMaster/active/`;
export const API_PRODUCT_EXTRA_FIELD_BY_ID: string = `${prefix}/warehouseProductMaster/getById/`;
export const API_PRODUCT_MASTER_UPDATE: string = `${prefix}/warehouseProductMaster/updateProductMaster/`;
export const API_PRODUCT_ACTIVE_LIST: string = `${prefix}/warehouseProductMaster/listActiveProducts/`;

export const API_GET_CALLBACK_LIST_FOR_ALERT: string = `${prefix}/emvo/callbackListForAlertByLot`;
export const API_GET_LOT_CODE_COUNT_WH = `${prefix}/whLotMaster/lotWiseCount`;
export const LOT_MASTER_FILTER_LIST_WH: string = `${prefix}/whLotMaster/filtered/`;
export const API_VOID_BATCH_DATA_WH = `${prefix}/whLotMaster/voidBatchData`;
export const LOT_MASTER_LIST_WH: string = `${prefix}/whLotMaster/list/`;
export const LOT_MASTER_GET_WH: string = `${prefix}/whLotMaster/get/`;
export const LOT_MASTER_GET_TRADE_VALUE_COUNT_WH: string = `${prefix}/whLotMaster/getTradeValueWiseCount/`;
export const LOT_MASTER_ACCEPT_REJECT_COUNT_WH: string = `${prefix}/whLotMaster/acceptRejectCount/`;
export const API_REPORT_RELATIONSHIP_RP_WH: string = `${prefix}/whLotMaster/relationshipReport`;
export const API_REPORT_RELATIONSHIP_CSV_WH: string = `${prefix}/whLotMaster/downloadCSV`;
export const API_LOT_ACCEPTED_CODE_DOWNLOAD_WH: string = `${prefix}/whLotMaster/downloadAcceptedCode`;
export const API_LOT_REJECTED_CODE_DOWNLOAD_WH: string = `${prefix}/whLotMaster/downloadRejectedCode`;
export const API_LOT_SAMPLE_CODE_DOWNLOAD_WH: string = `${prefix}/whLotMaster/downloadSampleCode`;
export const API_LOT_EVENT_TRIGGER_DETAILS_WH: string = `${prefix}/whLotMaster/getEventTriDetails`;
export const API_LOT_MDLP_XML_DOWNLOAD_WH: string = `${prefix}/whLotMaster/mdlpXMLDownload`;
export const SN_MASTER_TRADE_VALUE_WH: string = `${prefix}/whLotMaster/tradeValueList`;
export const API_VOID_BATCH_DATA_FROM_EPCIS_WH = `${prefix}/whLotMaster/voidBatchDataFromEpcis`;

/** TATMEEN */
export const API_TATMEEN_WH_SCAN_CODE_ACTION = `${prefix}/tatmeen/warehouse/codeScanAction`;
export const API_TATMEEN_WH_GENERATE_SSCC = `${prefix}/tatmeen/warehouse/generateSscc`;
export const API_TATMEEN_WH_VALIDATE_SSCC = `${prefix}/tatmeen/warehouse/validateSscc`;
export const API_TATMEEN_WH_EVENT_TRIGGER_LIST_BY_WH_FOR_REPORT = `${prefix}/tatmeen/eventTriggerListByWarehouseForReport`;
export const API_TATMEEN_WH_EVENT_TRIGGER_XML_DOWNLOAD = `${prefix}/tatmeen/whEventTriggerDownloadXml`;
export const API_TATMEEN_WH_EVENT_TRIGGER_REPROCESS = `${prefix}/tatmeen/whEventTriggerReProcess`;

export const API_EMVO_PACK_DISCLOSURE_REPORT: string = `${prefix}/emvo/packDislosureReport/`;

export const API_TRADE_VAL_COMP_PREFIX_ADD: string = `${prefix}/tradeValueCompanyPrefixMapping/add`;
export const API_TRADE_VAL_COMP_PREFIX_LIST_BY_TRADE_VALUE: string = `${prefix}/tradeValueCompanyPrefixMapping/listByTradeValue`;

export const DYNAMIC_SCAN_REPACK_AGGREGATION: string = `${prefix}/lotMaster/aggregationForDynamicScanRepack/`;
export const DYNAMIC_SCAN_REPACK_DEAGGREGATION_BYCHILD: string = `${prefix}/lotMaster/deAggregationByChildForDynamicScanRepackForDynamicScanRepack/`;
export const DYNAMIC_SCAN_REPACK_DEAGGREGATION_BYPARENT: string = `${prefix}/lotMaster/deAggregationByParentForDynamicScanRepackForDynamicScanRepack/`;
export const DYNAMIC_SCAN_REPACK_VALIDATION: string = `${prefix}/lotMaster/validationForDynamicScanRepack/`

export const API_FILE_UPLOAD_LIST: string = `${prefix}/fileUpload/fileList`;
export const API_FILE_UPLOAD: string = `${prefix}/fileUpload/uploadFile`;
export const API_FILE_DOWNLOAD: string = `${prefix}/fileUpload/downloadFile`;

export const API_CHINA_EVENT_TRIGGER_LIST: string = `${prefix}/chinaVDTS/getEvenTriggerList`
export const API_CHINA_GET_PRODUCT_LIST: string = `${prefix}/chinaVDTS/chinaVDTSProductList`;
export const API_CHINA_GET_LOT_LIST_FROM_PRODUCT_ID: string = `${prefix}/chinaVDTS/getLotMastersFromProductId`;
export const API_CHINA_GET_SN_LIST_AGAINST_LOT: string = `${prefix}/chinaVDTS/getSNListForLot`;
export const API_CHINA_GENERATE_EVENT: string = `${prefix}/chinaVDTS/generateEvent`;

export const API_TRIRX_JSON_SAMPLE_FILE: string = `/assets/sample/tri-rx-sample.json`;

export const API_MULTI_SCAN_SAVE_CODE_LIST: string = `${prefix}/dynamic-scan/saveCodeList/`;
export const API_MULTI_SCAN_GET_PENDING_AND_PRINTED_CODE_LIST: string = `${prefix}/dynamic-scan/getPendingPrintAndPrintedCodeList/`;
export const MYLAN_API_TRACE_IN_LABEL_PRINT_POC: string = `${prefix}/repack/printPoc/`;
export const MYLAN_LOT_MASTER_CODE_PRINT_ACTION_POC: string = `${prefix}/lotMaster/codePrintActionPoc`;
export const MYLAN_LOT_MASTER_VERIFY_CODE_SCAN_ACTION_POC: string = `${prefix}/lotMaster/verifyCodeScanActionPoc`;
export const LOT_MASTER_CODE_SCAN_ACTION_POC: string = `${prefix}/lotMaster/codeScanActionPoc`;
export const API_SHIPMENT_MASTER_ADD_POC: string = `${prefix}/shipment/addPoc/`;
export const API_SHIPMENT_MASTER_ATTACHED_MULTIPLE_LOT: string = `${prefix}/shipment/getMultipleLotDetailsPoc/`;
export const API_REPORT_RELATIONSHIP_SHIPMENT_POC: string = `${prefix}/lotMaster/relationshipReportForShipmentPoc`;
export const API_MULTI_SCAN_CHECK_CODE_AGG: string = `${prefix}/dynamic-scan/checkCodeIfAlreadyAgg/`;
export const API_MULTI_SCAN_SAVE_CODE_LIST_WITH_PARENT: string = `${prefix}/dynamic-scan/saveCodeListWithParent/`;