import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BusinessError } from 'app/reetrak-layout/models/business-error';
import { SecurityPolicy } from 'app/reetrak-layout/models/security-policy';
import { User } from 'app/reetrak-layout/models/user';
import { AliasService } from 'app/reetrak-layout/services/alias.service';
import { CommonService } from 'app/reetrak-layout/services/common.service';
import { LoaderService } from 'app/reetrak-layout/services/loader.service';
import { LovService } from 'app/reetrak-layout/services/lov.service';
import { NotificationService } from 'app/reetrak-layout/services/notification.service';
import { TokenStorageService } from 'app/reetrak-layout/services/token-storage.service';
import { AppSettings } from 'app/reetrak-layout/util/app-settings';
import { Constants } from 'app/reetrak-layout/util/constants';
import { AuthServiceService } from 'app/services/auth-service.service';
import PerfectScrollbar from 'perfect-scrollbar';
import { forkJoin } from 'rxjs';

export function customValidator(controlName: string, ref: ResetPasswordComponent) {
  switch (controlName) {
    case "VALIDATENEW":
      return (control: AbstractControl) => {
        if (ref.resetPasswordForm == undefined) {
          return null;
        }
 
        setTimeout(() => {
          ref.resetPasswordForm.controls.newPassword.updateValueAndValidity();
        }, 1000);
        
        if (ref.resetPasswordForm.value.newPassword != control.value) {
          return { custom: { message: ref.passwordNotMatch } }
        }
  
        return null;
      }
      break;
    case "VALIDATEOLD":
      return (control: AbstractControl) => {
        if (ref.resetPasswordForm == undefined) {
          return null;
        }
        setTimeout(() => {
          // 
        }, 1000);
       
        ref.resetPasswordForm.controls.confirmPassword.updateValueAndValidity();
      
        if (ref.securityPolicy != undefined) {
          if (ref.securityPolicy.dupPwdLst > 0) {
            if (ref.resetPasswordForm.value.oldPassword == control.value || ref.resetPasswordForm.value.token == control.value)
              return { custom: { message: ref.passwordNotEqual } }
          }
          //for cfr policy validations
          if (ref.securityPolicy.minPassLength > control.value.length)
            return { custom: { message: ref.passwordLength.replace("${1}", ref.securityPolicy.minPassLength.toString()) } }
          //for cfr policy validations for upper case check
          if (!/[A-Z]/.test(control.value) && ref.securityPolicy.isUpperCase == 1)
            return { custom: { message: ref.passwordContainUppercase } }
          //for cfr policy validations for number check
          if (!/\d/.test(control.value) && ref.securityPolicy.isNumber == 1)
            return { custom: { message: ref.passwordContainNumber } }
          //for cfr policy validations for special character check
          if (!/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value) && ref.securityPolicy.isSymbol == 1)
            return { custom: { message: ref.passwordContainSpecialChar } }
          if (/\s/.test(control.value))
            return { custom: { message: ref.spaceAllowed } }
          if (ref.securityPolicy.maxPassLength < control.value.length)
            return { custom: { message: ref.maxPasswordLength.replace("${1}", ref.securityPolicy.maxPassLength.toString()) } }

          return null;
        }
      }
      break;
    default:
      break;
  }
}

const TRANSLATE_KEY = "USER_MANAGEMENT.";
const TRANSLATE_KEY_RESET = "RESET_PASSWORD.";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent implements OnInit {

  CT = Constants;
  AS = AppSettings;
  resetPasswordForm: FormGroup;
  resetUser: User;
  securityPolicy: SecurityPolicy;
  passwordNotMatch: string;
  passwordNotEqual: string;
  passwordContainNumber: string;
  passwordContainUppercase: string;
  passwordContainSpecialChar: string;
  passwordLength: string;
  maxPasswordLength: string;
  oldPasswordDoesNotMatch: string;
  spaceAllowed: String;
  private nativeElement: Node;
  private toggleButton: any;
  existingUser: User;
  isReload: Boolean;
  user: User;
  firstTimeFlag: boolean;
  view : boolean;

  constructor( private element: ElementRef, private _location: Location, private fb: FormBuilder, 
    private loaderService: LoaderService, private dialog: MatDialog,
    private notificationService: NotificationService, private router: Router, public AL: AliasService,
    private commonService: CommonService, private authService: AuthServiceService, 
    public translate: TranslateService,
    private tokenStorage: TokenStorageService, private lovService: LovService) {
    forkJoin(
      this.lovService.getSecurityPolicyDetails(),      
    ).subscribe(val => {
      this.securityPolicy = val[0];
      setTimeout(() => {
        this.loaderService.display(false);
      }, 500);
    }, (error) => {
      console.log("-----error" + error);
    })
    this.nativeElement = element.nativeElement;
    this.user = this.authService.getUser();
    this.firstTimeFlag = this.user.firstTimeFlag;
    if (this.dialog != null)
      this.dialog.closeAll();    


    if(this.router.url=="/pages/resetPassword"){
      this.view=true;
    }
    this.createForm();
    this.resetUser = new User();
    this.translate.get("USER_MANAGEMENT.USER_PASSWORD_NOT_MATCH").subscribe(val => this.passwordNotMatch = val);
    this.translate.get("USER_MANAGEMENT.USER_PASSWORD_NOT_EQUAL").subscribe(val => this.passwordNotEqual = val);
    this.translate.get("USER_MANAGEMENT.USER_CONTAIN_NUMBER").subscribe(val => this.passwordContainNumber = val);
    this.translate.get("USER_MANAGEMENT.USER_CONTAIN_UPPERCASE").subscribe(val => this.passwordContainUppercase = val);
    this.translate.get("USER_MANAGEMENT.USER_CONTAIN_SPECIALCHAR").subscribe(val => this.passwordContainSpecialChar = val);
    this.translate.get("USER_MANAGEMENT.USER_PASSWORD_LENGTH").subscribe(val => this.passwordLength = val);
    this.translate.get("USER_MANAGEMENT.PASSWORD_DOES_NOT_MATCH").subscribe(val => this.oldPasswordDoesNotMatch = val);
    this.translate.get("USER_MANAGEMENT.USER_SPACE_ALLOWED").subscribe(val => this.spaceAllowed = val);
    this.translate.get("USER_MANAGEMENT.USER_PASSWORD_MAX_LENGTH").subscribe(val => this.maxPasswordLength = val);
  }

  ngOnInit() {
   
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('reset-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove('card-hidden');
    }, 700);
    // this.authService.validateToken().subscribe((res) => {
    // });
    this.commonService.dialogZIndexAdjustment();
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('reset-page');
    body.classList.remove('off-canvas-sidebar');
  }

  private createForm() {
    if (this.firstTimeFlag == true) {
      this.resetPasswordForm = this.fb.group(
        {
         
          // newPassword: ['', this.AL.VL(this.CT.USER_NEW_PASSWORD, [customValidator("VALIDATEOLD", this)])],
          // confirmPassword: ['', this.AL.VL(this.CT.USER_CONFIRM_PASSWORD, [customValidator("VALIDATENEW", this)])],

           // Nomeneclature not loaded ,so required validator add as per below code.
          token: ['', [Validators.required]],
          newPassword: ['', [Validators.required, customValidator("VALIDATEOLD", this)]],
          confirmPassword: ['',  [Validators.required, customValidator("VALIDATENEW", this)]],

         

        }
      );
    }
    else {
      this.resetPasswordForm = this.fb.group(
        {
          
          // oldPassword: ['', this.AL.VL(this.CT.USER_PASSWORD)],
          // newPassword: ['', this.AL.VL(this.CT.USER_NEW_PASSWORD, [customValidator("VALIDATEOLD", this)])],
          // confirmPassword: ['', this.AL.VL(this.CT.USER_CONFIRM_PASSWORD, [customValidator("VALIDATENEW", this)])],

           // Nomeneclature not loaded ,so required validator add as per below code.
          oldPassword: ['', [Validators.required]],
          newPassword: ['', [Validators.required, customValidator("VALIDATEOLD", this)]],
          confirmPassword: ['',  [Validators.required, customValidator("VALIDATENEW", this)]],
        }
      );
    }

  }

  private updateValue() {
    this.resetUser.id = this.authService.getUser().id;
    this.resetUser.password = this.resetPasswordForm.value.confirmPassword;
    this.resetUser.lastName = this.resetPasswordForm.value.oldPassword;
  }

  changePassword(remark?: string) {
    this.existingUser = this.authService.getUser()
    this.authService.passwordAuthentication(this.existingUser, this.resetPasswordForm.controls.token.value).subscribe(
      (data) => {
        this.updateValue();
        this.loaderService.display(true);
        this.authService.changePassword(this.resetUser, remark).subscribe(
          (data) => {
            this.router.navigate(['pages/login']);
          },
          (err: BusinessError) => {
            this.translate.get(TRANSLATE_KEY + err.message).subscribe((res: string) => {
              this.notificationService.failure(res);
            })
            this.loaderService.display(false);
          }
        );
      },
      (err: BusinessError) => {
        this.translate.get(TRANSLATE_KEY + err.message).subscribe((res: string) => {
          this.notificationService.failure(res);
        })
        this.loaderService.display(false);
      }
    );
  }

  resetPassword(remark?: string) {
    this.updateValue();
    this.authService.changePassword(this.resetUser, remark).subscribe(
      (res) => {
        if (res.code == this.CT.SUCCESS_CODE) {
          this.translate.get(TRANSLATE_KEY_RESET + res.message).subscribe((res: string) => {
            this.notificationService.success(res);
            this.tokenStorage.signOut();
            this.commonService.setRedirectURL(null);
            this.router.navigate(['pages/login']);
          });
        }
      },
      (err: BusinessError) => {
        this.translate.get(TRANSLATE_KEY_RESET + err.message).subscribe((res: string) => {
          this.notificationService.failure(res);
        })
      }
    );
  }

  submit() {
    this.isReload = true;
    // if (this.commonService.userRemarks()) {
    //   this.notificationService.userRemarks("RESET_PASSWORD.REMARKS").subscribe(
    //     (remark) => this.changePassword(remark),
    //     (error) => console.log("remark cancel --:" + error)
    //   );
    // } else {
      this.changePassword()
    // }
  }

  resetSubmit() {
    this.existingUser = this.authService.getUser();
    this.isReload = true;
    this.commonService.formDirty(this.resetPasswordForm);
    if (this.resetPasswordForm.invalid) {
      this.commonService.formInValidFocus(this.resetPasswordForm);
      return;
    }
    if (this.resetPasswordForm.valid) {
      this.authService.passwordAuthentication(this.existingUser, this.resetPasswordForm.controls.oldPassword.value).subscribe((res) => {
        if (res.code == this.CT.SUCCESS_CODE) {
          this.resetPassword();
        }
        else {
          this.notificationService.failure("failed");
        }
      }, (err: BusinessError) => {
        this.translate.get(TRANSLATE_KEY_RESET + err.message).subscribe((res: string) => {
          this.notificationService.failure(res);
        })
      });
      return;
    }

    this.resetPassword()

    this.router.navigate(['pages/login']);
  }
  //for back page
  backPage() {
    if (this.router.url!="/pages/resetPassword" && this.user.firstTimeFlag == false) {
      this.isReload=true;
      // this.dialogRef.close({ 'isReload': this.isReload });
      this.dialog.closeAll();
    } else {
      this.tokenStorage.signOut();
      this.commonService.setRedirectURL(null);
      this.router.navigate(['pages/login']);
      
    }
  }
  // backPage() {
  //     this.tokenStorage.signOut();
  //     this.commonService.setRedirectURL(null);
  //     this.router.navigate(['pages/login']);
      
  // }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      // let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
}
