<div class="row">
    <div class="col-md-1 col-lg-2 col-xl-3"></div>
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{'NOTIFICATIONS.TITLE'|translate}}</h4>
            </div>
            <div class="mat-dialog-content">
                <div class="card-body">
                    <div class=" custom-scroll sn-outbound-scroll">
                        <div class="container">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{'NC.COLONM_NAME'|translate}}</th>
                                        <th colspan="2">{{'NC.COLONM_VALUE'|translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="trow">{{'NC.NOTIFICATIONS_MODULE_ID'|translate}} </td>
                                        <td colspan="2">
                                            <span *ngIf="isNumber(this.NotificationDetail.moduleId) === 'NaN'">{{this.NotificationDetail.moduleId}}</span>
                                            <span *ngIf="isNumber(this.NotificationDetail.moduleId) !== 'NaN' && this.NotificationDetail.moduleId !== '-1'">
                          <span *ngFor="let module of moduleList">
                            <span *ngIf="module.value == NotificationDetail.moduleId">
                              {{module.name}}
                            </span>
                                            </span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{'NC.NOTIFICATIONS_MESSAGE_DETAIL'|translate}} </td>
                                        <td colspan="2" style="word-wrap: normal;"> {{this.NotificationDetail.messageDetail}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{'NC.NOTIFICATIONS_IS_READ'|translate}} </td>
                                        <td colspan="2">
                                            <span *ngIf="this.NotificationDetail.isRead === 1">
                          <i class="material-icons icon-image-preview">visibility</i>
                        </span>
                                            <span *ngIf="this.NotificationDetail.isRead === 0">
                          <i class="material-icons icon-image-preview">visibility_off</i>
                        </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{'NC.NOTIFICATIONS_CREATED_DATE'|translate}} </td>
                                        <td colspan="2"> {{this.NotificationDetail.createdDate | date: 'dd/MM/yyyy hh:mm:ss' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer text-right">
                <div class="ml-auto">
                    <button (click)="this.dialogRef.close({'isReload': true})" mat-raised-button type="submit" class="btn" [ngClass]="[AS.btnClass, AS.btnSm]">{{'COMMON.CLOSE'
              | translate}}
            </button>
                </div>
            </div>

        </div>
    </div>
</div>