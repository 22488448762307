import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../reetrak-layout/services/common.service';

@Injectable()
export class NomenclatureResolver implements Resolve<boolean> {

  constructor(private commonService : CommonService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.commonService.loadNomenclatureOb();
  }

}
