import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_MENU_LIST, API_MENU_FLUSH } from '../util/service-url';
import { Menu } from '../models/menu';

@Injectable()
export class MenuService {

  constructor(private http: HttpClient) { }

  getMenuItems(): Observable<Menu[]> {
    return this.http.get<any[]>(API_MENU_LIST);
  }

  flushMenuItems(): Observable<Menu[]> {
    return this.http.get<any[]>(API_MENU_FLUSH);
  }

}
