import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SecurityPolicy } from 'app/reetrak-layout/models/security-policy';
import { FormBuilder, FormControl, FormGroup, Validators } from '../../../../../../node_modules/@angular/forms';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { BusinessError } from '../../../../reetrak-layout/models/business-error';
import { CommonService } from '../../../../reetrak-layout/services/common.service';
import { NotificationService } from '../../../../reetrak-layout/services/notification.service';
import { TokenStorageService } from '../../../../reetrak-layout/services/token-storage.service';
import { AppSettings } from '../../../../reetrak-layout/util/app-settings';
import { Constants } from '../../../../reetrak-layout/util/constants';
import { AuthServiceService } from '../../../../services/auth-service.service';
import { LoaderService } from '../../../services/loader.service';

const TRANSLATE_KEY = "USER_MANAGEMENT.";
@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {
  test: Date = new Date();
  loggedInUser = this.authService.getUser();
  AS = AppSettings;
  CT = Constants;
  logInForm: FormGroup;
  timedOut = false;
  imageData = this.loggedInUser.imgData;
  profileImage = true;
  securityPolicy: SecurityPolicy;
  duration: number;
  private isFirstTime: boolean;

  constructor(private dialogRef: MatDialogRef<LockComponent>,
    private authService: AuthServiceService, private loaderService: LoaderService,
    private router: Router, public translate: TranslateService,
    private dialog: MatDialog, private fb: FormBuilder, private tokenStorage: TokenStorageService,
    private notificationService: NotificationService, private commonService: CommonService
  ) {
    this.loaderService.display(false);
    this.createForm();
  }
  ngOnInit() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('lock-page');
        body.classList.add('off-canvas-sidebar');
        this.tokenStorage.signOut();
        if (this.loggedInUser.imgData == null) {
          this.profileImage = false;
        }
        this.securityPolicy = this.commonService.getSecurityPolicy();
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('lock-page');
    body.classList.remove('off-canvas-sidebar');
  }

  private createForm() {
    this.logInForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      }
    );
  }
  submit() {
    if (this.logInForm.valid) {
      this.loaderService.display(true);
      this.logIn(this.loggedInUser.userName, this.logInForm.value.password, "");
    } else {
      this.validateAllFormFields(this.logInForm);
    }

  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  logIn(userName: string, password: string, callback: any) {
    this.authService.attemptAuth(userName, password).subscribe(
      (data) => {
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.initializeRefreshToken();
        this.duration = this.securityPolicy.lockDuration * 60;
        this.dialogRef.close("success");
        this.commonService.lockScreenTimeOutReset();
        this.commonService.lockScreenTimeOutStart(this.duration).subscribe(() => this.lockScreen());
        this.loaderService.display(false);
        this.isFirstTime = this.authService.getUser().firstTimeFlag == this.CT.APP_FIRST_TIME_LOGIN;
        if (this.isFirstTime || data.code == 98) {
          this.loaderService.display(false);
          this.router.navigate(['pages/resetPassword']);
          return;
        }
      },
      (err: BusinessError) => {
        this.translate.get(TRANSLATE_KEY + err.message).subscribe((res: string) => {
          this.notificationService.failure(res);
        })
        this.loaderService.display(false);
      }
    );
  }

  lockScreen() {
    let islogInPage = true;
    if (this.router.url == "/pages/login") {
      islogInPage = false;
    }
    if (this.router.url == "/mylanPrintCode") {
      islogInPage = false;
    }
    if (islogInPage) {
      let dialogRef = this.dialog.open(LockComponent, {
        width: '60%',
        // height : '100%',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'mat-dialog-custom-panel'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // if (result !== "success")
        //   this.lockScreen();
      });
    }
  }


}
