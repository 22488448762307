import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeSharedRoutingModule } from './pipe-shared-routing.module';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { ErrorMessagePipe } from '../pipes/error-message.pipe';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { KeysPipe } from '../pipes/keys.pipe';
import { NomenClaturePipe } from '../pipes/nomen-clature.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';


@NgModule({
  declarations: [
    DateFormatPipe,
    ErrorMessagePipe,
    HighlightPipe,
    KeysPipe,
    NomenClaturePipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    PipeSharedRoutingModule
  ],
  exports:[NomenClaturePipe,DateFormatPipe,SafeHtmlPipe,HighlightPipe,KeysPipe]
})
export class PipeSharedModule { }
