import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BusinessError } from '../models/business-error';
import { LevelDetail } from '../models/level-detail';
import { LovMst } from '../models/lov-mst';
import { Menu } from '../models/menu';
import { Nomenclature } from '../models/nomenclature';
import { Plant } from '../models/plant';
import { ResponseObject } from '../models/response-object';
import { Role } from '../models/role';
import { SecurityPolicy } from '../models/security-policy';
import { ThresholdPool } from '../models/threshold-pool';
import { User } from '../models/user';
import { Constants } from '../util/constants';
import { API_ADD_LOV_MST, API_GET_LOV_MST, API_GET_THRESHOLDPOOL_BYTRADETYPE, API_LOV_APP_CONFIG, API_LOV_COMMON_DATA, API_LOV_LEVEL_DETAIL, API_LOV_MST, API_LOV_NOMENCLATURE, API_LOV_PLANT, API_LOV_ROLE, API_LOV_ROLE_RIGHTS, API_LOV_USER, API_SECURITY_LIST, API_UPDATE_LOV_MST, API_GET_LOV_BY_DEPENDANT_PARENT, API_LOV_MENU_LIST, API_LOV_MENU_PRIVILEGES, API_LOV_MENU_PRIVILEGES_BY_ROLE, API_LOV_ROLE_ONLY_READ_RIGHT, API_GET_LOV_VAL_BY_LOV_MST, API_LOV_OTHER_ROLE } from '../util/service-url';
import { LovVal } from '../models/lov-val';
import { AliasService } from './alias.service';
import { MenuPrivileges } from '../models/menu-privileges';
import { RolePrivilegeMapping } from '../models/role-privilege-mapping';






@Injectable()
export class LovService {
  CT = Constants;

  constructor(private http: HttpClient) { }

  handleError(message: string) {
    return (error: HttpErrorResponse) => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error(`${message} An error occurred:`, error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `${message} Backend returned code ${error.status}, ` +
          `${message} body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return observableThrowError(error.error);
    };
  }


  getRole(admin: any = false): Observable<Role[]> {
    const params = new HttpParams().set("admin", admin)
    return this.http.get<Role[]>(API_LOV_ROLE, { params: params });
  }

  getOtherRole(other: any = false): Observable<any> {
    const params = new HttpParams().set("warehouseUser", other)
    return this.http.get<any>(API_LOV_OTHER_ROLE, { params: params });
  }

  getLovMst(lovMst: string, active = false, license: any = false): Observable<LovMst> {
    const params = new HttpParams().set("lovMst", lovMst).set("license", license);
    return new Observable<any>((observer) => {
      this.http.get<LovMst>(API_LOV_MST, { params: params }).subscribe(
        res => {
          if (active) {
            let lovMstObj = new LovMst();
            lovMstObj.name = res.name;
            lovMstObj.descr = res.descr;
            lovMstObj.id = res.id;
            lovMstObj.lovValList = res.lovValList != null ? res.lovValList.filter(val => val.active == this.CT.APP_ACTIVE) : [];
            observer.next(lovMstObj);
            observer.complete();

          }
          else {
            observer.next(res);
            observer.complete();
          }
        }
      )
    })
  }

  getRoleRights(): Observable<Menu[]> {
    return this.http.get<Menu[]>(API_LOV_ROLE_RIGHTS);
  }

  getMenuPrivileges(): Observable<MenuPrivileges[]> {
    return this.http.get<MenuPrivileges[]>(API_LOV_MENU_PRIVILEGES);
  }

  getRoleMenuPrivilegeMappingByRoleId(id): Observable<RolePrivilegeMapping[]> {
    const params = new HttpParams().set("id", id)
    return this.http.get<RolePrivilegeMapping[]>(API_LOV_MENU_PRIVILEGES_BY_ROLE, { params: params });
  }

  getMenuList(): Observable<Menu[]> {
    return this.http.get<Menu[]>(API_LOV_MENU_LIST);
  }

  getNomenclature(): Observable<Map<string, Nomenclature>> {
    return this.http.get<Map<string, Nomenclature>>(API_LOV_NOMENCLATURE).pipe(
      catchError(this.handleError('getNomenclature'))
    );
  }

  getCommonDataFromServer(): Observable<Map<string, any>> {
    return this.http.get<Map<string, any>>(API_LOV_COMMON_DATA).pipe(
      catchError(this.handleError('getCommonDataFromServer'))
    );
  }

  getConfig(): Observable<Map<string, string>> {
    return this.http.get<Map<string, string>>(API_LOV_APP_CONFIG).pipe(
      catchError(this.handleError('getConfig'))
    );
  }

  getPlant(): Observable<Plant[]> {
    return this.http.get<Plant[]>(API_LOV_PLANT);
  }

  getUsers(admin: any = false): Observable<User[]> {
    const params = new HttpParams().set("admin", admin)
    return this.http.get<User[]>(API_LOV_USER, { params: params });
  }

  getSecurityPolicyDetails(): Observable<SecurityPolicy> {
    return this.http.get<ResponseObject<SecurityPolicy>>(API_SECURITY_LIST).pipe(
      map(value => value.res),
    );
  }

  getLevelDetail(): Observable<Map<number, LevelDetail>> {
    return this.http.get<Map<number, LevelDetail>>(API_LOV_LEVEL_DETAIL).pipe(
      catchError(this.handleError('addLovMst'))
    );
  }

  getAllLovMst(): Observable<LovMst[]> {
    return this.http.get<LovMst[]>(API_GET_LOV_MST);
  }

  updateLovMst(lovMst: LovMst, remarks = ""): Observable<BusinessError> {
    const params = new HttpParams().set("remarks", remarks);
    return this.http.post<any>(API_UPDATE_LOV_MST, lovMst, { params: params }).pipe(
      catchError(this.handleError('updateLovMst'))
    )
  }

  addLovMst(lovMst: LovMst, remarks = ""): Observable<BusinessError> {
    const params = new HttpParams().set("remarks", remarks);
    return this.http.post<any>(API_ADD_LOV_MST, lovMst, { params: params }).pipe(
      catchError(this.handleError('addLovMst'))
    )
  }

  getAllThreshholdpoolListByTradeType(): Observable<ThresholdPool[]> {
    return this.http.get<ThresholdPool[]>(API_GET_THRESHOLDPOOL_BYTRADETYPE);
  }

  getAllThreshholdpoolListByTradeType1(): Observable<ThresholdPool[]> {
    return new Observable<any>((observer) => {
      this.http.get<ThresholdPool[]>(API_GET_THRESHOLDPOOL_BYTRADETYPE).subscribe(
        (res: ThresholdPool[]) => {
          observer.next(res);
          observer.complete();
        },
        (err: BusinessError) => {
          console.log("getAllThreshholdpoolListByTradeType catch error-->" + err);
        }
      )
    })
  }



  //   isEmailRegisterd(email: string) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     return this.http.post('http://localhost:8080/api/v1/isEmailRegisterd', JSON.stringify({ email: email }), { headers: headers })
  //         .map((response: Response) => response.json())
  //         .catch(this.handleError);
  // }

  getLovByDependantParent(id): Observable<LovVal[]> {
    const params = new HttpParams().set("id", id)
    return this.http.get<ResponseObject<LovVal[]>>(API_GET_LOV_BY_DEPENDANT_PARENT, { params: params }).pipe(
      map(result => result.res),
      catchError(this.handleError('getUser'))
    );
  }


  getRoleOnlyHavingReadRight(): Observable<Role[]> {
    return this.http.get<ResponseObject<Role[]>>(API_LOV_ROLE_ONLY_READ_RIGHT).pipe(
      map(result => result.res),
      catchError(this.handleError('getRoleOnlyHavingReadRight'))
    );;
  }

  getLovValByLovMstId(id: any): Observable<LovVal[]> {
    const params = new HttpParams().set("id", id)
    return this.http.get<ResponseObject<LovVal[]>>(API_GET_LOV_VAL_BY_LOV_MST, { params: params }).pipe(
      map(result => result.res),
      catchError(this.handleError('getLovValByLovMstId'))
    );
  }
}
