import { LovVal } from "./lov-val";

export class LovMst {
    id:number;
    name:string;   
    descr : string;
    lovValList : LovVal[];
    editable:boolean;

    constructor(){        
        this.lovValList = [];
    }
}
