import configurationMap from "../../../assets/GS1_Barcode_spec.json";
import configurationMapWithBracket from "../../../assets/GS1_Barcode_spec_with_bracket.json";
import { BarcodeMapper } from "../models/BarcodeMapper.js";

export class Parser {


    private static inputString: string = "";
    private static configurationMap: Object = configurationMap;
    private static barcodeMapper: BarcodeMapper;
    private static group_separator: string[];
    private static current_separator: string = null;
    private static stringConatinsBracket = false;

    static setGroupSeparator(gsString: string) {
        Parser.group_separator = gsString.split(",");
    }
    static parse(inputString: string): BarcodeMapper {
        Parser.configurationMap = configurationMap;
        console.log("parser inuput -: "+inputString);
        Parser.barcodeMapper = new BarcodeMapper();
        Parser.stringConatinsBracket = false;
        var symbology = inputString.substr(0, 3);
        //Removes Initial FNC from the input string
        switch (symbology) {
            case "]C1": /** GS1-128 ]C100SSCCCode */
            case "]e0": /** GS1 DataBar */
            case "]d2": /** GS1 DataMatrix */ /** ]d201GTIN21SRNO<GS>10LOTNUMBER*/
            case "]Q3": /** GS1 QR Code*/
                inputString = inputString.substr(3);
                break;
        }
        Parser.inputString = inputString;

        if (Parser.inputString && Parser.inputString.indexOf("(") != -1 && Parser.inputString.indexOf(")") != -1) {
            Parser.configurationMap = configurationMapWithBracket;
            Parser.stringConatinsBracket = true;
        }

        //Algorithm:
        while (Parser.inputString.length != 0) {
            //1.Extract and remove Application Identifier from the input string
            var data = Parser.extractAIFromInputString();
            //2.Check whether the element string has Variable or Fixed length data. (Uses data stored in the json configuration File)
            //  'F' indicates fixed length and 'V' indicates variable length.
            var AI_MAP = Parser.configurationMap[data];
            if (AI_MAP == null) {
                return null;
            }
            //3.Extract and process data accordingly.
            if (AI_MAP["type"] == "F") {
                if (!Parser.processStaticLengthInput(AI_MAP)) {
                    return null;
                }
            } else {
                if (!Parser.processVariableLengthInput(AI_MAP)) {
                    return null;
                }
            }
            AI_MAP = null;
            //4.Repeat steps 1 through 3 untill the input string is empty.
        }
        Parser.current_separator = null;
        console.log("Parse output-: "+JSON.stringify(Parser.barcodeMapper));
        return Parser.barcodeMapper;
    }

    private static processStaticLengthInput(data: Object): boolean {
        var length = Number(data["length"]);
        if (Parser.inputString.length < length) {
            return false;
        }
        var value = Parser.inputString.substr(0, length);
        if (Parser.checkGroupSeparator(value) != -1) {
            return false;
        }
        Parser.setBarcodeMapper(data["name"], value);
        Parser.inputString = Parser.inputString.substring(length);
        if (Parser.checkGroupSeparator(Parser.inputString) == 0) {
            Parser.inputString = Parser.inputString.substr(Parser.current_separator.length);
        }
        return true;
    }

    private static processVariableLengthInput(data: Object): boolean {
        var index = Parser.checkGroupSeparator(Parser.inputString);

        if (index == -1 && Parser.stringConatinsBracket && Parser.inputString.indexOf("(") != -1) {            
            index = Parser.inputString.indexOf("(");            
            Parser.setBarcodeMapper(data["name"], Parser.inputString.substring(0, index));
            Parser.inputString = Parser.inputString.substring(index);
            return true;
        } else if (index == -1) {

            Parser.setBarcodeMapper(data["name"], Parser.inputString);
            Parser.inputString = "";
            return true;
        } else {
            Parser.setBarcodeMapper(data["name"], Parser.inputString.substring(0, index));
            Parser.inputString = Parser.inputString.substring(index + Parser.current_separator.length);
            return true;
        }
    }

    private static extractAIFromInputString(): string {
        for (var i = 2; i <= 4; i++) {
            if (Parser.configurationMap[Parser.inputString.substr(0, i)] != undefined) {
                var AI = Parser.inputString.substr(0, i);
                Parser.inputString = Parser.inputString.substr(i);
                return AI;
            }
        }
        return null;
    }

    private static checkGroupSeparator(value: string): number {
        if (Parser.current_separator == null) {
            Parser.group_separator.forEach(separator => {
                if (value.indexOf(separator) != -1) {
                    Parser.current_separator = separator;
                    return value.indexOf(separator);
                }
            });
        } else {
            return value.indexOf(Parser.current_separator);
        }
        return -1;

    }

    private static setBarcodeMapper(key: string, value: string) {
        switch (key) {
            case "GTIN":
                Parser.barcodeMapper.tradeType = key;
                Parser.barcodeMapper.tradeValue = value;
                break;
            case "SSCC":
                Parser.barcodeMapper.tradeType = "COMPANY_PREFIX";
                Parser.barcodeMapper.tradeValue = value;
                Parser.barcodeMapper.serialNumber = value;
                break;
            case "PRODUCTION_DATE":
                Parser.barcodeMapper.productionDate = value;
                break;
            case "EXPIRATION_DATE":
                Parser.barcodeMapper.expirationDate = value;
                break;
            case "LOT_NUMBER":
                Parser.barcodeMapper.lotNumber = value;
                break;
            case "SERIAL_NUMBER":
                Parser.barcodeMapper.serialNumber = value;
                break;
            case "COUNT_OF_ITEMS":
                Parser.barcodeMapper.countOfItems = value;
                break;
            case "ADDITIONAL_PRODUCT_IDENTIFICATION":
                Parser.barcodeMapper.additionalProductIdentification = value;
                break;
            case "NHRN_DE":
            case "NHRN_FR":
            case "NHRN_ES":
            case "NHRN_BR":
            case "NHRN_PT":
                Parser.barcodeMapper.NHRN = value;
                break;
            case "COMPANY_INTERNAL_INFORMATION_92":
                Parser.barcodeMapper.companyInternalInfo_0 = value;
                break;
            case "COMPANY_INTERNAL_INFORMATION_91":
                Parser.barcodeMapper.companyInternalInfo_1 = value;
                break;
            case "INFO_MUTUALLY_AGREED":
                Parser.barcodeMapper.mutuallyAgreedInfo = value;
                break;
        }
    }
}