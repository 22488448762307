import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from '../../../util/app-settings';
import { PrintingService } from '../../../services/printing.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {
  @ViewChild('printEl', {static: true}) printEl: ElementRef;
  title : string;
  imageName : string;
  imageData : string;
  AS = AppSettings;
  constructor(public dialogRef: MatDialogRef<ImagePreviewComponent>, @Inject(MAT_DIALOG_DATA) data:any, 
  private printingService : PrintingService) { 
    this.title = data.title;
    this.imageName = data.imageName;
    this.imageData = data.imageData; 
    // console.log("imageData--: "+this.imageData);
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

  print(){
    this.printingService.print(this.printEl.nativeElement);
  }

}
