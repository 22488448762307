export class BarcodeMapper {

    id : number;
    tradeType : string;
    tradeValue : string;
    code : string;
    lotNumber : string;
    productCode : string;
	additionalProductIdentification: string;
    NHRN: string;
    productionDate:string;
    expirationDate:string;
    serialNumber:string;
    countOfItems:string;
    companyInternalInfo_0:string;
    companyInternalInfo_1:string;
    mutuallyAgreedInfo:string;
}