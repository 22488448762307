
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, timeout} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpErrorResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';




const TOKEN_HEADER_KEY = 'Authorization';
const LANG = 'selected-lang';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private token: TokenStorageService, private router: Router, private translate : TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
  Observable<HttpEvent<any>> {
    let authReq = req;    
    if (this.token.getToken() != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this .token.getToken())});      
      authReq = authReq.clone({ headers: authReq.headers.set(LANG, (this.translate.currentLang ? this.translate.currentLang : "ENGLISH"))});
    }   

    return next.handle(authReq).pipe(timeout(600000),catchError((error, caught) => {
        //intercept the respons error and displace it to the console
        console.log("Error Occurred");
        console.log(error);
        //return the error to the method that called it

        if(this.router.url && this.router.url != null && 
          (this.router.url.indexOf("traceInfo") != -1 || this.router.url.indexOf("mylan-traceInfo") != -1 )){ // For External-Controller
          return null;
        }

        if ((error.url != undefined && error.url.indexOf("validateToken") == -1 && error.url.indexOf("external") == -1) && (error.status === 401 || error.status === 404)) {
          setTimeout(() => {
            this.router.navigate(['pages/login']);              
          }, 2000);  
          if(error.url.indexOf("validate-token") == -1 && error.url.indexOf("external") == -1)            
            return null;
        }
        return observableThrowError(error);
        })) as any;
      


    // return next.handle(authReq);
    
  }

}
