import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AliasService } from '../services/alias.service';

@Pipe({
  name: 'NC'
})
export class NomenClaturePipe implements PipeTransform {

  constructor(private commonService: CommonService, private translate: TranslateService, private AL: AliasService) { }

  transform(value: any, args?: any): any {
    let val = "";
    switch (args) {
      case "VL":
        this.translate.get("NC." + value).subscribe(
          (res) => {
            val = res;
            if (!res) {
              console.error("NC not found", value);
            }
          },
          (err) => val = err
        );
        // val = this.AL.PH(value);
        break;
        case "CL":
         return this.commonService.getNomenclature(value).value;
        // val = this.AL.PH(value);
        break;
      default:
        val = "Invalid option"
    }

    return val;
  }

}
