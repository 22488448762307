
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AbstractControlDirective, AbstractControl, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { Nomenclature } from '../models/nomenclature';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';
import { Constants } from '../util/constants';

export function CommonValidator(type: string) {

  switch (type) {
    case Constants.NUMBER_VALIDATION:
      return (control: AbstractControl) => {
        if (control.value && control.value.length > 0 && !/^[0-9]+$/.test(control.value)) {
          return { numberValidation: '' };
        }
        return null;
      }
      break;
      case Constants.FLOT_NUMBER_VALIDATION:
        return (control: AbstractControl) => {
          if (control.value && control.value.length > 0 && !/^[0-9]+.[0-9]+$/.test(control.value)) {
            return { flotNumberValidation: '' };
          }
          return null;
        }
        break;
  
    default:
      break;
  }

}

const CT = Constants;

@Injectable()
export class AliasService {

  constructor(public commonService: CommonService, public translate: TranslateService) { }

  PH(key: string): string {
    if (this.translate.currentLang == 'ru')
      return this.commonService.getNomenclature(key).ru;
    else
      return this.commonService.getNomenclature(key).value;
  };

  PHT(key: string) {
    return this.translate.get("NC." + key);
  }

  NC(key: string) {
    return this.commonService.getNomenclature(key);
  }

  NCVL(key: string) {
    if (this.translate.currentLang == 'ru')
      return this.commonService.getNomenclature(key).ru;
    else
      return this.commonService.getNomenclature(key).value;
  }

  NCTR(key: string): string {
    let val;
    this.translate.get("NC." + key/*this.commonService.getNomenclature(key).value*/).subscribe(res => val = res);
    return val;
  }

  EM(control: AbstractControlDirective | AbstractControl) {
    return this.commonService.getErrorMessage(control);
  }

  VL(key: string, custom?: ValidatorFn[]): ValidationErrors | ValidatorFn[] {
    let nomenclature = this.commonService.getNomenclature(key);
    let validationErrorList = new Array<ValidationErrors>();
    if (custom) {
      // custom.forEach(element => {
      //   validationErrorList.push(element);
      // });
      for (let index = 0; index < custom.length; index++) {
        validationErrorList.push(custom[custom.length - 1 - index]);
      }
    }
    if (nomenclature) {
      if (nomenclature.required == 1)
        validationErrorList.push(Validators.required)

      if (nomenclature.min != -1 && nomenclature.min != null)
        validationErrorList.push(Validators.minLength(nomenclature.min));

      if (nomenclature.max != -1 && nomenclature.max != null)
        validationErrorList.push(Validators.maxLength(nomenclature.max));

      if (nomenclature.regularExpression != undefined && nomenclature.regularExpression != null) {
        if (nomenclature.regularExpression == CT.EMAIL_REGEX) {
          validationErrorList.push(Validators.email);
        } else if (nomenclature.regularExpression == CT.NUMBER_VALIDATION) {
          validationErrorList.push(CommonValidator(CT.NUMBER_VALIDATION));
        } else if (nomenclature.regularExpression == CT.FLOT_NUMBER_VALIDATION) {
          validationErrorList.push(CommonValidator(CT.FLOT_NUMBER_VALIDATION));
        } else {
          validationErrorList.push(Validators.pattern(nomenclature.regularExpression));
        }
      }

      if (nomenclature.minValue != -1 && nomenclature.minValue != null)
        validationErrorList.push(Validators.min(nomenclature.minValue));

      if (nomenclature.maxValue != -1 && nomenclature.maxValue != null)
        validationErrorList.push(Validators.max(nomenclature.maxValue));

      return validationErrorList;
    }

    return validationErrorList;;
  }

  handleError(message: string) {
    return (error: HttpErrorResponse) => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error(`${message} An error occurred:`, error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `${message} Backend returned code ${error.status}, ` +
          `${message} body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return observableThrowError(error.error);
    };
  }
}
