import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockComponent } from './lock.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeSharedModule } from 'app/reetrak-layout/pipe-shared/pipe-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [LockComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    PipeSharedModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
  ], entryComponents: [LockComponent],
  exports: [LockComponent],
  providers: []
})
export class LockModule { }
