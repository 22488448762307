
import { of as observableOf, Subscription, timer, Observable } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouterEvent, NavigationCancel, NavigationError } from '@angular/router';
import { LoaderService } from './reetrak-layout/services/loader.service';
import { TranslateService } from '../../node_modules/@ngx-translate/core';
import { LoaderAnimation } from './reetrak-layout/util/animation';
import { filter, tap, debounce } from 'rxjs/operators';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [LoaderAnimation]
})

export class AppComponent implements OnInit {
  private _router: Subscription;
  showLoader: boolean = true;
  constructor(private router: Router, private loaderService: LoaderService, public translate: TranslateService,
    private cdref: ChangeDetectorRef) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })

    translate.addLangs(['ENGLISH', 'FRENCH', 'RUSSIAN', 'GERMAN', 'PORTUGUESE', 'SPANISH']);

    if (window.sessionStorage.getItem('lang')) {
      translate.setDefaultLang(window.sessionStorage.getItem('lang'));
    } else {
      translate.setDefaultLang('ENGLISH');
    }
    const browserLang = translate.getBrowserLang();
    const lang = window.sessionStorage.getItem('lang')
    translate.use((lang != null || lang != undefined) ? lang : translate.getLangs()[0]);
  }

  ngOnInit() {
    this._router = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
    });
    let immediate = true;
    let lastCall;
    let diff = 500;
    this.loaderService.status.pipe(
      debounce((val: boolean) => {
        let currentCall = Date.now()
        if (immediate || (currentCall - lastCall) >= diff) {
          lastCall = Date.now();
          immediate = false;
          return observableOf(val);
        } else {
          lastCall = Date.now();
          return timer(diff);
        }
      })

    ).subscribe((val: boolean) => {
      this.showLoader = val;
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.display(true);
    }
    if (event instanceof NavigationEnd) {
      // this.loaderService.display(false);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loaderService.display(false);
    }
    if (event instanceof NavigationError) {
      this.loaderService.display(false);
    }
  }
}
