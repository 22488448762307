import { Config } from './../models/config';
import { BusinessError } from './../models/business-error';
import { ResponseObject } from './../models/response-object';
import { AliasService } from './alias.service';
import { map, catchError } from 'rxjs/operators';
import { API_CONFIG_UPDATE, API_CONFIG_ADD, API_CONFIG_LIST, API_GET_CONFIG_LIC_DAYS } from './../util/service-url';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient, private AL: AliasService) { }

  getconfig(): Observable<Config[]> {
    return this.http.get<ResponseObject<Config[]>>(API_CONFIG_LIST).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getconfigDetails'))
    );
  }

  addconfigDetails(config: Config, remarks = ""): Observable<BusinessError> {
    const params = new HttpParams().set("remarks", remarks);
    return this.http.post<BusinessError>(API_CONFIG_ADD, config, { params: params }).pipe(
      map(value => value),
      catchError(this.AL.handleError('addconfig'))
    )
  }

  updateconfigDetails(config: Config, remarks = ""): Observable<BusinessError> {
    const params = new HttpParams().set("remarks", remarks);
    return this.http.post<BusinessError>(API_CONFIG_UPDATE, config, { params: params }).pipe(
      map(value => value),
      catchError(this.AL.handleError('updateconfig'))
    );
  }

  getLicDays(): Observable<Map<string, string>> {
    return this.http.get<ResponseObject<Map<string, string>>>(API_GET_CONFIG_LIC_DAYS).pipe(
      map(result => result.res),
      catchError(this.AL.handleError('getLicDays'))
    );
  }

}

