import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BusinessError } from '../models/business-error';
import { API_PRINT_FILE_GET, API_REPACK_PRINT_LABEL_2, GET_PRINT_CONNECTED_STATUS } from '../util/service-url';
import { AliasService } from './alias.service';

@Injectable({
  providedIn: 'root'
})
export class PrintingService {

  constructor(private http: HttpClient, private AL: AliasService) { }

  public print(printEl: HTMLElement) {
    let printContainer: HTMLElement = document.querySelector('#print-container');

    if (!printContainer) {
      printContainer = document.createElement('div');
      printContainer.id = 'print-container';
    } 

    printContainer.innerHTML = '';

    let elementCopy = printEl.cloneNode(true);
    printContainer.appendChild(elementCopy);
    // document.body.appendChild(printContainer);
    // window.print();
    let newWindow = window.open();
    newWindow.document.write(printEl.innerHTML);
    newWindow.print();
    newWindow.close();
  }

  printLabel(data): Observable<BusinessError>{
    return this.http.post<any>(API_REPACK_PRINT_LABEL_2, data).pipe(
      catchError(this.AL.handleError('printLabel'))
    )
  }

  getPrintStatus(printCheck: any): Observable<BusinessError> {
    const params = new HttpParams().set("check", printCheck);
    return this.http.get<any>(GET_PRINT_CONNECTED_STATUS, { params: params }).pipe(
      map(value => value),
      catchError(this.AL.handleError('getPrintStatus'))
    );
  }

  downfile(): Observable<HttpEvent<any>>{
    return this.http.get(API_PRINT_FILE_GET, {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders(
        { 'Content-Type': 'application/json' },
      )
    }).pipe(
      catchError(this.AL.handleError('downfile'))
    );
  }
}
