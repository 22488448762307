import { RoleMenuMapping } from './role-menu-mapping';
import { RolePrivilegeMapping } from './role-privilege-mapping';

export class Role {

    constructor(){        
        this.roleMenuMappings = [];
        this.rolePrivilegeMappings=[];
    }

    id:number;
    name:string;        
    active:boolean = true;
    hierarchy:number;
    roleMenuMappings : Array<RoleMenuMapping>;
    rolePrivilegeMappings:Array<RolePrivilegeMapping>;
    show:boolean;
}
