
import { Injectable } from '@angular/core';
import { LovService } from './lov.service';
import { Nomenclature } from '../models/nomenclature';
import { AbstractControlDirective, AbstractControl, Validator, Validators, ValidationErrors, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Observable, of, forkJoin, Subscribable, Subscriber, Subject, ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ImagePreviewComponent } from '../modules/dialog/image-preview/image-preview.component';
import { TranslateService } from '@ngx-translate/core';
import { SecurityPolicy } from '../models/security-policy';
import { Plant } from '../models/plant';
import { Constants } from '../util/constants';
import { Role } from '../models/role';
import { LovInterface } from '../models/lov-interface';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { takeUntil } from 'rxjs/operators';
import * as _moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { NgxFilter } from '../models/ngx-filter';
import { LovVal } from '../models/lov-val';
import { EventMaster } from 'app/reetrak-layout/models/eventmaster';
import { Parser } from 'app/reetrak-layout/services/Parser';
import { saveAs } from 'file-saver';
import { ThresholdPool } from '../models/threshold-pool';
import { BarcodeMapper } from '../models/BarcodeMapper';
import { ThresholdPoolService } from './thresholdPool.service';
import { BusinessError } from '../models/business-error';

declare const $: any;

@Injectable()
export class CommonService {

  private nomenclature: Map<string, Nomenclature> = new Map();
  private appConfig: Map<string, string> = new Map();
  private specialCharacter: Map<string, string> = new Map();
  private securityPolicy: SecurityPolicy;
  private errorMessage: Map<String, String> = new Map();
  private routes: any[];
  private menuItems: any[] = [];
  private redirectURL: string = null;
  private timeOutObservable: Subscriber<any> = null;
  private commonData: Map<string, any> = new Map();
  CT = Constants;
  remarkFlag = false;
  hierarchyFlag = false;

  constructor(private lovService: LovService, private dialog: MatDialog, private translate: TranslateService, private idle: Idle, private keepalive: Keepalive) {
    this.errorMessage.set("min", "Please enter minimum $1 chacchter");
    this.errorMessage.set("max", "Please enter maximum $1 chacchter");
    this.errorMessage.set("regex", "Please enter valid $1 chacchter");
    this.routes = [];
  }

  private readonly errorMessages = {
    // 'required': () => 'This field is required',
    'required': () => { let value = ""; this.translate.get("COMMON.REQUIRED").subscribe(val => value = val); return value },
    // 'min': (params) => 'The min value ' + params.min,
    'min': (params) => { let value = ""; this.translate.get("COMMON.MIN").subscribe((val: string) => value = val.replace("${1}", params.min)); return value },
    // 'max': (params) => 'The max value ' + params.max,
    'max': (params) => { let value = ""; this.translate.get("COMMON.MAX").subscribe((val: string) => value = val.replace("${1}", params.max)); return value },
    // 'minlength': (params) => 'The min number of characters is ' + params.requiredLength,
    'minlength': (params) => { let value = ""; this.translate.get("COMMON.MIN_LENGTH").subscribe((val: string) => value = val.replace("${1}", params.requiredLength)); return value },
    // 'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'maxlength': (params) => { let value = ""; this.translate.get("COMMON.MAX_LENGTH").subscribe((val: string) => value = val.replace("${1}", params.requiredLength)); return value },
    // 'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
    'pattern': () => { let value = ""; this.translate.get("COMMON.PATTERN").subscribe(val => value = val); return value },
    'email': () => { let value = ""; this.translate.get("COMMON.EMAIL").subscribe(val => value = val); return value },
    'years': (params) => params.message,
    'countryCity': (params) => params.message,
    'uniqueName': (params) => params.message,
    'telephoneNumbers': (params) => params.message,
    'telephoneNumber': (params) => params.message,
    'custom': (params) => params.message,
    'matDatepickerParse': (params) => { let value = ""; this.translate.get("COMMON.INVALID_DATE").subscribe(val => value = val); return value },
    'matDatepickerMax': (params) => { let value = ""; this.translate.get("COMMON.MIN_DATE").subscribe(val => value = val.replace("${1}", this.getDate(params.max))); return value },
    'matDatepickerMin': (params) => { let value = ""; this.translate.get("COMMON.GRATER_DATE").subscribe(val => value = val.replace("${1}", this.getDate(params.min))); return value },
    'numberValidation': () => { let value = ""; this.translate.get("COMMON.NUMBER_VALIDATION").subscribe(val => value = val); return value },
    'flotNumberValidation': () => { let value = ""; this.translate.get("COMMON.NUMBER_FLOAT_VALIDATION").subscribe(val => value = val); return value },
  };

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private getMessage(type: string, params: any) {
    // if(type == "matDatepickerMax"){
    //   return;
    // }
    return this.errorMessages[type](params);
  }

  loadNomenclature() {
    if (this.nomenclature.size == 0) {
      this.lovService.getNomenclature().subscribe(val => {
        this.nomenclature = val;
      })
    }
  }

  loadNomenclatureOb(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      // if (this.nomenclature.size == 0) {
      /**this.lovService.getNomenclature().subscribe(val => {
        this.nomenclature = val;
        observer.next(true);
        observer.complete();
      })*/
      forkJoin(
        this.lovService.getNomenclature(),
        this.lovService.getConfig(),
        //this.lovService.getSpecialCharacter(),
        this.lovService.getSecurityPolicyDetails(),
        //this.lovService.getCommonDataFromServer()
      ).subscribe(
        (result) => {
          this.nomenclature = result[0];
          this.appConfig = result[1];
          Parser.setGroupSeparator(this.getAppConfig("GROUP_SEPERATOR_VALUE"));
          //this.specialCharacter = result[2];
          this.securityPolicy = result[2];
          //this.commonData = result[4];
          this.remarkFlag = (this.getAppConfig(this.CT.AC_ISREMARKREQ) == 1 + "");
          observer.next(true);
          observer.complete();
          this.CT.UI_DATE_TIME_FORMAT = this.getAppConfig("DATATABLE_DATE_FORMAT");
        },
        (err) => {
          observer.next(false);
          observer.complete();
        }
      )
      // } else {
      //   observer.next(true);
      //   observer.complete();
      // }
    })
  }

  getNomenclature(key: string): Nomenclature {
    const res = this.nomenclature[key];
    if (!res) {
      console.error('Nomenclature key not found! ', key);
    }
    return res;
  }

  getCommonData(key: string): any {
    return this.commonData[key];
  }

  getAppConfig(key: string): string {
    return this.appConfig[key];
  }

  getSpecialCharacter(key: string): String {
    return this.specialCharacter[key];
  }

  getSecurityPolicy() {
    return this.securityPolicy;
  }

  getErrorMessage(control: AbstractControlDirective | AbstractControl): string {
    if (control != undefined && control.errors) {
      let key = Object.keys(control.errors)[0];
      return this.getMessage(key, control.errors[key]);
    }
    else null;
  }

  userRemarks(): boolean {
    return this.remarkFlag;
  }

  isHierarchy(): boolean {
    return this.hierarchyFlag;
  }

  setRoutes(routes: any[]) {
    this.routes = routes;
  }

  getRoutes(): any[] {
    return this.routes;
  }

  // dataTableIntialize(id: string): DataTables.Api {
  //   let lang = this.translate.currentLang;
  //   $('#' + id).DataTable({
  //     "pagingType": "full_numbers",
  //     "lengthMenu": [
  //       [10, 25, 50, -1],
  //       [10, 25, 50, "All"]
  //     ],
  //     responsive: false,
  //     language: {
  //       search: "_INPUT_",
  //       // searchPlaceholder: "Search records",
  //       url: "/assets/i18n/datatable/" + lang + ".json"
  //     },
  //     destroy: true
  //   });

  //   return $('#' + id).DataTable();
  // }

  // dataTableDestroy(table: DataTables.Api) {
  //   table.clear();
  //   table.destroy();
  // }

  imagePreview(title: string, imageName: string, imageData: any) {
    // return Observable.create((observer)=>{

    let dialogRef = this.dialog.open(ImagePreviewComponent, {
      width: '75%',
      // height: '90%',
      data: {
        title: title,
        imageName: imageName,
        imageData: imageData
      },
      hasBackdrop: true,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        // observer.next(result);
      } else {
        // observer.error("No remarks");

      }

      // observer.complete();
    });
    // })
  }

  validateGTIN(gtin: number): boolean {

    let str = gtin + ""/**  Number(gtin).toString() */;
    if (str.length == 0 || str == "0" || gtin == undefined || str.length != 14)
      return false;
    let sum = 0;
    let checkDigit = 0;
    let higherMultiPleOf10;
    let multipler1 = str.length % 2 == 0 ? 3 : 1;
    let multipler2 = str.length % 2 == 0 ? 1 : 3;
    for (let index = 0; index < str.length - 1; index++) {
      if (index % 2 == 0)
        sum += Number(str.charAt(index)) * multipler1;
      else
        sum += Number(str.charAt(index)) * multipler2;
    }

    higherMultiPleOf10 = sum;

    checkDigit += Number(str.charAt(str.length - 1));

    for (let index = 0; index < 11; index++) {
      if (higherMultiPleOf10 % 10 == 0)
        break;

      higherMultiPleOf10++;
    }


    if (checkDigit == higherMultiPleOf10 - sum)
      return true;

    return false;
  }

  validateCheckDigit(input: string) {
    const currentCheckDigit = parseInt(input.substring(input.length - 1));
    input = input.substring(0, input.length - 1);

    let sum = 0;
    let higherMulipleOf10 = 0;
    for (let i = 0; i < input.length; i++) {
      sum += parseInt(input.substring(input.length - i - 1, input.length - i - 1 + 1)) * (i % 2 == 0 ? 3 : 1);
    }

    higherMulipleOf10 = sum;
    while (true) {
      if (higherMulipleOf10++ % 10 == 0)
        break;
    }
    return currentCheckDigit == (higherMulipleOf10 - 1 - sum);
  }

  dialogZIndexAdjustment() {
    $(".cdk-overlay-backdrop").removeAttr('style').css("z-index", "900");
    $(".cdk-overlay-container").removeAttr('style').css("z-index", "1030");
    // $(".cdk-global-overlay-wrapper").removeAttr('style').css("z-index", "1035");
    // $(".cdk-overlay-pane").removeAttr('style').css("z-index", "1050");

  }

  customScroller(options, selector?: string) {
    const elemMainPanel = <HTMLElement>document.querySelector(selector ? selector : '.custom-scroll');
    let ps = new PerfectScrollbar(elemMainPanel, options);
  }

  formDirty(formGroup: FormGroup) {
    formGroup.updateValueAndValidity({ onlySelf: true });
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formDirty(control);
      } else if (control instanceof FormArray) {
        this.formArrayDirty((control as FormArray));
      } else {
        console.log("check here");
      }
    });
  }

  formArrayDirty(formArray: FormArray) {
    Object.keys(formArray.controls).forEach(field => {
      const control = formArray.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formDirty(control);
      } else if (control instanceof FormArray) {
        this.formArrayDirty((control as FormArray));
      } else {
        console.log("check here");
      }
    });
  }

  formReValidate(formGroup: FormGroup) {
    formGroup.updateValueAndValidity({ onlySelf: true });
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.setValue(formGroup.getRawValue()[field]);
      } else if (control instanceof FormGroup) {
        this.formReValidate(control);
      } else if (control instanceof FormArray) {
        this.formArrayReValidate((control as FormArray));
      } else {
        console.log("check here");
      }
    });
  }

  formArrayReValidate(formArray: FormArray) {
    Object.keys(formArray.controls).forEach(field => {
      const control = formArray.get(field);
      if (control instanceof FormControl) {
        control.setValue(formArray.getRawValue()[field]);
      } else if (control instanceof FormGroup) {
        this.formReValidate(control);
      } else if (control instanceof FormArray) {
        this.formArrayReValidate((control as FormArray));
      } else {
        console.log("check here");
      }
    });
  }

  formInValidFocus(formGroup: FormGroup) {
    let keys = Object.keys(formGroup.controls);
    for (let index = 0; index < keys.length; index++) {
      let field = keys[index];
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.invalid) {
        this.focusToField('#' + field);
        return;
      } else if (control instanceof FormGroup) {
        this.formInValidFocus(control);
      } else if (control instanceof FormArray) {
        this.formArrayInValidFocus((control as FormArray));
      } else {
        console.log("check here");
      }
    }

  }

  formInValidFocusForFields(formGroup: FormGroup, fields: string[]) {
    for (let index = 0; index < fields.length; index++) {
      let field = fields[index];
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.invalid) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formInValidFocus(control);
      } else if (control instanceof FormArray) {
        this.formArrayInValidFocus((control as FormArray));
      } else {
        console.log("check here");
      }
    }
  }

  formUntouchedForFields(formGroup: FormGroup, fields: string[]) {
    // Note: This will only work if form button has attribute type="button" and NOT type="submit"
    for (let index = 0; index < fields.length; index++) {
      let field = fields[index];
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.invalid) {
        control.markAsUntouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formInValidFocus(control);
      } else if (control instanceof FormArray) {
        this.formArrayInValidFocus((control as FormArray));
      } else {
        console.log("check here");
      }
    }
  }

  formArrayInValidFocus(formArray: FormArray) {
    let keys = Object.keys(formArray.controls);
    for (let index = 0; index < keys.length; index++) {
      let field = keys[index];
      const control = formArray.get(field);
      if (control instanceof FormControl && control.invalid) {
        this.focusToField('#' + field);
        return;
      } else if (control instanceof FormGroup) {
        this.formInValidFocus(control);
      } else if (control instanceof FormArray) {
        this.formArrayInValidFocus((control as FormArray));
      } else {
        console.log("check here");
      }
    }
  }

  focusToField(selector: string) {
    let target, offset = 0;
    target = $(selector);
    if (target.length == 0)
      return;
    offset = $(target).parent().parent().parent().parent().offset().top - $(".custom-scroll").offset().top;

    console.log("selector-: " + selector + "   offset " + offset);
    if (target) {
      $('.custom-scroll').animate({ scrollTop: offset }, 'slow', () => { target.focus() });
    }
  }

  plantFilter(plants: Plant[]): Plant[] {
    return plants;
  }

  roleFilter(roles: Role[], childRole?: number): Role[] {

    if (!childRole)
      return roles != null ? roles.filter(val => val.active == this.CT.APP_ACTIVE) : [];
    else
      return roles != null ? roles.filter(val => val.active == this.CT.APP_ACTIVE && val.hierarchy > childRole) : [];
  }

  downloadFile(data: string, name: string) {
    // let blob = new Blob([data], { type: 'application/octet-stream' });
    // let url = window.URL.createObjectURL(blob);
    // // window.open(url);
    // let link = document.createElement('a');
    // link.href = url;
    // link.download = name + '.txt';
    // link.click();
    // window.URL.revokeObjectURL(url);

    // var link = document.createElement('a');
    // // Add the element to the DOM
    // link.setAttribute("type", "hidden"); // make it hidden if needed
    // link.download = name + '.txt';
    // link.href = url;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    let blob = new Blob([data], { type: 'application/octet-stream' });
    saveAs(blob, name + '.txt');
  }

  setMenuItems(val: any[]) {
    this.menuItems = val;
  }

  getMenuItems(): any[] {
    return this.menuItems;
  }

  setRedirectURL(url: string) {
    this.redirectURL = url;
  }

  getRedirectURL() {
    return this.redirectURL != null ? this.redirectURL : "/dashboard";
  }

  public getProductionMapConfigLov(productionMapConfig, type) {
    let temp: Array<LovInterface> = [];
    productionMapConfig[type].split(",").forEach(element => {
      temp.push({ name: element.split("#")[1], value: Number(element.split("#")[0]) })
    });
    console.log(temp);
    return temp;
  }

  public lockScreenTimeOutStart(duration): Observable<any> {
    if (this.timeOutObservable != null) {
      this.timeOutObservable.unsubscribe();
    }
    return new Observable<any>((observer) => {
      this.timeOutObservable = observer;
      // sets an idle timeout in seconds.
      this.idle.setIdle(duration / 2);
      // sets a timeout period in seconds.
      this.idle.setTimeout(duration / 2);
      // console.log(duration)
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      // this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
      this.idle.onTimeout.subscribe(() => {
        // this.idleState = 'Timed out!';
        // this.timedOut = true;
        observer.next();
        observer.complete();
      });
      // this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
      // this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
      // console.log(this.idleState);
      // sets the ping interval in seconds
      this.keepalive.interval(duration);
      this.lockScreenTimeOutReset();
      // this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    });
  }

  public lockScreenTimeOutReset() {
    this.idle.stop();
    this.idle.watch();
    // this.idleState = 'Started.';
    // this.timedOut = false;
  }

  public lockScreenTimeOutStop() {
    this.idle.stop();
    console.log("lockScreenTimeOutStop");
  }


  public getDate(input) {
    if (input == undefined || input == null) {
      return;
    }

    return _moment(input, this.CT.SERVER_DATE_FORMAT).format(this.CT.UI_DATE_FORMAT);
  }

  public getRetestDateMylan(input) {
    if (input == undefined || input == null) {
      return;
    }

    return _moment(input, this.CT.MYLAN_DATE_FORMAT).format(this.CT.UI_DATE_FORMAT);
  }



  public getLocalDate(input) {
    return _moment.utc(input, this.CT.SERVER_DATE_FORMAT).local().format(this.CT.UI_DATE_FORMAT);
  }

  public getDateTime(input) {
    return _moment(input, this.CT.SERVER_DATE_FORMAT).format(this.CT.UI_DATE_TIME_FORMAT);
  }

  public getLocalDateTime(input) {
    return _moment.utc(input, this.CT.SERVER_DATE_FORMAT).local().format(this.CT.UI_DATE_TIME_FORMAT);
  }

  public getLocalDateTimeMoment(input) {
    return _moment.utc(input, this.CT.SERVER_DATE_FORMAT).local();
  }

  public getDateTimeMoment(input) {
    return _moment(input, this.CT.SERVER_DATE_FORMAT);
  }

  public getDateWithoutTimeMoment(input) {
    return _moment(input, this.CT.SERVER_DATE_FORMAT_WITHOUT_TIME);
  }

  public getDateFullTimeMoment(input) {
    return _moment(input, this.CT.SERVER_DATE_FORMAT_FULL_TIME);
  }


  public getServerDateFromMoment(input) {
    return _moment(input, this.CT.UI_DATE_FORMAT).format(this.CT.SERVER_DATE_FORMAT);
  }
  public getServerDateWithoutTime(input) {
    return _moment(input, this.CT.UI_DATE_FORMAT).format(this.CT.SERVER_DATE_FORMAT_WITHOUT_TIME);
  }

  public getServerDateFullTime(input) {
    return _moment(input, this.CT.UI_DATE_FORMAT).format(this.CT.SERVER_DATE_FORMAT_FULL_TIME);
  }

  public ngxMatSelectSearch<T>(control: AbstractControl, listSub: Subject<T[]>, _onDestroy: Subject<void>, search: NgxFilter): ReplaySubject<T[]> {
    let filterSubject: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
    let list: Array<T> = [];
    listSub.subscribe(
      res => {
        list = res;
        filterSubject.next(list);
      }
    )
    control.valueChanges
      .pipe(takeUntil(_onDestroy))
      .subscribe(() => {
        this.filter(control, list, filterSubject, search);
      });
    return filterSubject;
  }

  private filter<T>(control: AbstractControl, list: Array<T>, filteredBatch: ReplaySubject<T[]>, searchCall: NgxFilter) {

    let search = control.value;
    if (!search) {
      filteredBatch.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the batch
    filteredBatch.next(
      list.filter(val => searchCall.getName(val).toLowerCase().indexOf(search) > -1)
    );
  }

  getLovValue(lovlist: Array<LovVal>, value) {
    if (lovlist.length) {
      let temp = lovlist.find(val => val.value == value);
      return temp ? temp.name : "NA";
    }
    else
      return "NA";
  }

  /**
   * This combo filter used for access only active member of the Lov Val list.
   * @author sarthak oza
   * @date 05/06/2019
   * @param lovVals 
   */
  comboValueFilter(lovVals: LovVal[]): LovVal[] {
    return lovVals != null ? lovVals.filter(val => val.active == this.CT.APP_ACTIVE) : [];
  }

  /**
  * This eventValue filter used for access only active event of the Event Master list.
  */
  evantValueFilter(eventVals: EventMaster[]): EventMaster[] {
    return eventVals != null ? eventVals.filter(val => val.active == this.CT.APP_ACTIVE) : [];
  }

  getYesNo(val) {
    if (val === 0) {
      return "No";
    } else {
      return "Yes";
    }
  }

  copyMessage(val, id?) {
    const selBox = document.createElement('textarea');
    document.getElementById(id ? id : val).className = "fa fa-check";
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      document.getElementById(id ? id : val).className = "fa fa-file-text-o";
    }, 2000);
  }

  thresholdExtract(thresholdPoolList: ThresholdPool[], barcodeMapper: BarcodeMapper): ThresholdPool {
    let thresholdPool;
    thresholdPoolList.filter(threshold => {
      if (threshold.tradeType == this.CT.TRADE_TYPE.COMPANY_PREFIX) {
        let extTradeValue = threshold.extentionDigit + threshold.tradeValue;
        if (barcodeMapper.tradeValue.startsWith(extTradeValue)) {
          thresholdPool = threshold;
        }
      }
    })
    return (thresholdPool == null || thresholdPool == undefined) ? null : thresholdPool;
  }


  parser(unParsed: string) {
    let parseMap: Map<string, string> = new Map();
    let barcode: BarcodeMapper = Parser.parse(unParsed);

    if (barcode != null) {
      parseMap.set(this.CT.PARSER.SERIAL_NUMBER, barcode.serialNumber);
      parseMap.set(this.CT.PARSER.LOT_NUMBER, barcode.lotNumber);
      if (barcode.expirationDate) {
        parseMap.set(this.CT.PARSER.EXPIRY_DATE, this.getExpDateFromYYMMDD(barcode.expirationDate));
      }
      parseMap.set(this.CT.PARSER.TRADE_TYPE, barcode.tradeType);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, barcode.tradeValue);
    }

    return parseMap;

  }

  parser1(unParsed: string) {
    let parseMap: Map<string, string> = new Map();
    // 17 Expiry Date YYMMDD
    // 10 Lot Number
    // 00 SSCC
    // 01 GTIN
    // 21 Serial Number(Code)
    // 11 Manufacture Date YYMMDD
    // When it is not necessary to specify the day (the day field is filled with two zeros), the resultant data string SHALL be interpreted as the last day
    // of the noted month including any adjustment for leap years (e.g., “130200” is “2013 February 28”, “160200” is “2016 February 29”, etc.).

    // GTIN + Batch/Lot + Serial Number + Expiry Date
    // Ex:- (01)00614141123452(17)180426(10)ABC1(21)12345
    // https://id.gs1.org/gtin/614141123452/lot/ABC1/ser/12345?exp=180426
    // https://id.gs1.org/01/614141123452/10/ABC1/21/12345?17=180426
    // 180426 == YYMMDD
    if (unParsed.includes("(01)") && unParsed.includes("(17)") && unParsed.includes("(10)")
      && unParsed.includes("(21)")) {
      let serialNumber = unParsed.split("(21)")[1];
      let lotNumber = unParsed.split("(21)")[0].split("(10)")[1];
      let date = unParsed.split("(21)")[0].split("(10)")[0].split("(17)")[1];
      let tradeValue = unParsed.split("(21)")[0].split("(10)")[0].split("(17)")[0].split("(01)")[1];
      parseMap.set(this.CT.PARSER.SERIAL_NUMBER, serialNumber);
      parseMap.set(this.CT.PARSER.LOT_NUMBER, lotNumber);
      parseMap.set(this.CT.PARSER.EXPIRY_DATE, this.getExpDateFromYYMMDD(date));
      parseMap.set(this.CT.PARSER.TRADE_TYPE, this.CT.TRADE_TYPE.GTIN);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, tradeValue);
    }

    // (01)13112345678906(17)010615(10)A123456
    // (01)GTIN(17)Expiry Date YYMMDD(10)Lot Number
    else if (unParsed.includes("(01)") && unParsed.includes("(17)") && unParsed.includes("(10)")
      && !unParsed.includes("(21)")) {
      let lotNumber = unParsed.split("(10)")[1];
      let date = unParsed.split("(10)")[0].split("(17)")[1];
      let tradeValue = unParsed.split("(10)")[0].split("(17)")[0].split("(01)")[1];
      parseMap.set(this.CT.PARSER.LOT_NUMBER, lotNumber);
      parseMap.set(this.CT.PARSER.EXPIRY_DATE, this.getExpDateFromYYMMDD(date));
      parseMap.set(this.CT.PARSER.TRADE_TYPE, this.CT.TRADE_TYPE.GTIN);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, tradeValue);
    }

    // GTIN + Serial Number (also known as SGTIN) Ex:- (01)00614141123452(21)12345
    // https://id.gs1.org/gtin/614141123452/ser/12345
    // https://id.gs1.org/01/614141123452/21/12345
    // https://brand.example.com/gtin/614141123452/ser/1234
    // https://brand.example.com/01/614141123452/21/12345
    // https://retailer.example.com/01/614141123452/21/12345
    else if (unParsed.includes("(01)") && unParsed.includes("(21)")
      && !unParsed.includes("(17)") && !unParsed.includes("(10)")) {
      let serialNumber = unParsed.split("(21)")[1];
      let tradeValue = unParsed.split("(21)")[0].split("(01)")[1];
      parseMap.set(this.CT.PARSER.SERIAL_NUMBER, serialNumber);
      parseMap.set(this.CT.PARSER.TRADE_TYPE, this.CT.TRADE_TYPE.GTIN);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, tradeValue);
    }

    // GTIN + Batch/Lot Ex:- (01)00614141123452(10)ABC123
    // https://id.gs1.org/gtin/614141123452/lot/ABC123
    // https://id.gs1.org/01/614141123452/10/ABC123
    // https://brand.example.com/gtin/614141123452/lot/ABC123
    // https://brand.example.com/01/614141123452/10/ABC123
    // https://retailer.example.com/01/614141123452/10/ABC123
    else if (unParsed.includes("(01)") && unParsed.includes("(10)")
      && !unParsed.includes("(21)") && !unParsed.includes("(17)")) {
      let lotNumber = unParsed.split("(10)")[1];
      let tradeValue = unParsed.split("(01)")[1].split("(10)")[0];
      parseMap.set(this.CT.PARSER.LOT_NUMBER, lotNumber);
      parseMap.set(this.CT.PARSER.TRADE_TYPE, this.CT.TRADE_TYPE.GTIN);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, tradeValue);
    }

    // SSCC Ex:- (00)106141412345678908
    // https://id.gs1.org/sscc/106141412345678908
    // https://id.gs1.org/00/106141412345678908
    else if (unParsed.substring(0, 2) == '00') {
      let serialNumber = unParsed.substring(2,)
      parseMap.set(this.CT.PARSER.SERIAL_NUMBER, serialNumber);
      parseMap.set(this.CT.TRADE_TYPE.COMPANY_PREFIX, this.CT.TRADE_TYPE.COMPANY_PREFIX);
    }

    // (01)GTIN(10)LOTNUMBER(17)EXPIRYDATE(21)SERIALNUMBER
    // (01)GTIN(10)LOTNUMBER(21)SERIALNUMBER(17)EXPIRYDATE
    // (01)GTIN(21)SERIALNUMBER(17)EXPIRYDATE(10)LOTNUMBER
    // (21)SERIALNUMBER(10)LOTNUMBER(17)EXPIRYDATE(01)GTIN

    else if (unParsed.substring(0, 2) == '01') {
      let serialNumber;
      let lotNumber;
      let expDate;
      let tradeValue = unParsed.substring(2, 15);
      if (unParsed.substring(0, 2) == '01') {
        if ((unParsed.indexOf('21')) > (unParsed.indexOf('10'))) {
          if (unParsed.indexOf('21') > unParsed.indexOf('17')) {// (01)GTIN(10)LOTNUMBER(17)EXPIRYDATE(21)SERIALNUMBER
            // let remainingString = unParsed.substring(unParsed.indexOf('10') + 2,);
            let indexOf17 = unParsed.indexOf('17');
            lotNumber = unParsed.substring(unParsed.indexOf('10') + 2, unParsed.indexOf('17'))
            expDate = unParsed.substring(indexOf17 + 2, indexOf17 + 8);
            serialNumber = unParsed.substring(indexOf17 + 10,);
          } else {// (01)GTIN(10)LOTNUMBER(21)SERIALNUMBER(17)EXPIRYDATE
            let index = unParsed.indexOf('21');
            lotNumber = unParsed.substring(unParsed.indexOf('10') + 2, unParsed.indexOf('21'));
            let indexOf17 = unParsed.indexOf('17');
            serialNumber = unParsed.substring(index + 2, indexOf17);
            expDate = unParsed.substring(unParsed.indexOf('17') + 2,);
          }
        }
        else {// (01)GTIN(21)SERIALNUMBER(17)EXPIRYDATE(10)LOTNUMBER
          let indexOf17 = unParsed.indexOf('17');
          serialNumber = unParsed.substring(unParsed.indexOf('10') + 2, unParsed.indexOf('17'));
          expDate = unParsed.substring(indexOf17, indexOf17 + 6);
          lotNumber = unParsed.substring(unParsed.indexOf('17') + 10);
        }
      }
      parseMap.set(this.CT.PARSER.SERIAL_NUMBER, serialNumber);
      parseMap.set(this.CT.PARSER.LOT_NUMBER, lotNumber);
      parseMap.set(this.CT.PARSER.EXPIRY_DATE, this.getExpDateFromYYMMDD(expDate));
      parseMap.set(this.CT.PARSER.TRADE_TYPE, this.CT.TRADE_TYPE.GTIN);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, tradeValue);
    }
    else if (unParsed.substring(0, 2) == '21') {//(21)SERIALNUMBER(10)LOTNUMBER(17)EXPIRYDATE(01)GTIN
      let indexOf17 = unParsed.indexOf('17');
      let serialNumber = unParsed.substring(2, 16);
      let lotNumber = unParsed.substring(16 + 2, indexOf17);
      let expDate = unParsed.substring(unParsed.indexOf('17') + 2, unParsed.indexOf('17') + 8);
      let tradeValue = unParsed.substring(unParsed.indexOf('17') + 10,);
      parseMap.set(this.CT.PARSER.SERIAL_NUMBER, serialNumber);
      parseMap.set(this.CT.PARSER.LOT_NUMBER, lotNumber);
      parseMap.set(this.CT.PARSER.EXPIRY_DATE, this.getExpDateFromYYMMDD(expDate));
      parseMap.set(this.CT.PARSER.TRADE_TYPE, this.CT.TRADE_TYPE.GTIN);
      parseMap.set(this.CT.PARSER.TRADE_VALUE, tradeValue);
    }
    return parseMap;
  }

  getExpDateFromYYMMDD(date: string) {
    let YY = date.substr(0, 2);
    let MM = date.substr(2, 2);
    let DD = date.substr(4, 2);
    return YY + "-" + MM + "-" + DD;
  }


  copyMessageOperationId(val) {
    const selBox = document.createElement('textarea');
    document.getElementById(val).className = "fa fa-check";
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      document.getElementById(val).className = "fa fa-file-o";
    }, 2000);
  }

  getDaysDifference(fromDate, toDate) {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    var diff = Math.abs(startDate.getTime() - endDate.getTime());
    var diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  public getDynamicMessage(key: string, translaeKey: string): string {
    let keydata: string[] = key.split("##");
    let message;
    this.translate.get(translaeKey + keydata[0].trim()).subscribe(res => {
      message = res;
    })
    if (keydata.length > 1) {
      keydata.forEach((element, index) => {
        message = message.replace("${" + index + "}", element);
      });
    }
    return message;
  }

  parseErrorMessage(error: BusinessError, resMsg) {
    let msg = resMsg;
    if (error && error.params?.length) {
      let counter = 1;
      error.params.forEach(item => {
        msg = resMsg.replace("${" + counter + "}", item);
        counter++;
      });
    }
    return msg;
  }

  parseMessage(message: string, params: string[]) {
    let msg = message;
    let counter = 1;
    params.forEach(item => {
      msg = message.replace("${" + counter + "}", item);
      counter++;
    });
    return msg;
  }
}
