import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AliasService } from './alias.service';
import { Observable } from 'rxjs';
import { NotificationDetail } from '../models/notification-details';
import { catchError, map, tap } from 'rxjs/operators';
import { ResponseObject } from '../models/response-object';
import { API_UNREAD_NOTIFICATIONS_LIST, API_NOTIFICATIONS_FILTER_LIST, API_MARK_NOTIFICATION, API_UNREAD_NOTIFICATION,
  API_ADD_NOTIFICATION, API_ADD_NOTIFICATION_TEMPLATE, API_READ_ALL_NOTIFICATION,
  API_LOAD_NOTIFICATION_MAIL_CONFIG, API_LOAD_NOTIFICATION_TEMPLATE } from '../util/service-url';
import { BusinessError } from '../models/business-error';
import { NotificationTemplate } from '../models/notification-template';
import { NotificationMailConfig } from '../models/notification-mail-config';


@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  constructor(private http: HttpClient, private AL: AliasService) { }

  getUnreadNotificationList(): Observable<NotificationDetail[]> {
    return this.http.get<ResponseObject<NotificationDetail[]>>(API_UNREAD_NOTIFICATIONS_LIST).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getUnreadNotification'))
    );
  }

  getNotificationFilterList(fromDate: string, toDate: string, selectModule: string, reetrakAdmin = false): Observable<NotificationDetail[]> {
    const params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate).set("selectModule", selectModule).set("reetrakAdmin", "" + reetrakAdmin);
    return this.http.get<ResponseObject<NotificationDetail[]>>(API_NOTIFICATIONS_FILTER_LIST, { params: params }).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getNotificationFilter'))
    );
  }

  markNotificationRead(id: number): Observable<BusinessError> {
    return this.http.get<BusinessError>(API_MARK_NOTIFICATION + id, { }).pipe(
      map(value => value),
      catchError(this.AL.handleError('markNotificationRead'))
    );
  }

  viewUnReadNotification(): Observable<NotificationDetail[]> {
    return this.http.get<ResponseObject<NotificationDetail[]>>(API_UNREAD_NOTIFICATION).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getUnreadNotification'))
    );
  }

  markNotificationReadAll(ids): Observable<BusinessError> {
    const list = JSON.stringify(ids);
    return this.http.post<ResponseObject<BusinessError>>(API_READ_ALL_NOTIFICATION,
      { ids: list }).pipe(
        map(value => value.res),
        catchError(this.AL.handleError('markNotificationReadAll'))
      );
  }

  saveNotification(notification: NotificationDetail): Observable<BusinessError> {
    return this.http.post<BusinessError>(API_ADD_NOTIFICATION, notification, { }).
      pipe(
        tap(res => console.log("UserNotificationService -> saveNotification--: " + res)),
        catchError(this.AL.handleError('saveNotification'))
      );
  }

  saveTemplate(template: NotificationTemplate, notificationMailConfig: NotificationMailConfig): Observable<BusinessError> {
    return this.http.post<BusinessError>(API_ADD_NOTIFICATION_TEMPLATE, 
      {template:template, notificationMailConfig: notificationMailConfig}, { }).
      pipe(
        tap(res => console.log("UserNotificationService -> saveTemplate--: " + res)),
        catchError(this.AL.handleError('saveTemplate'))
      );
  }

  getNotificationTemplate(errorId: string): Observable<NotificationTemplate> {
    const params = new HttpParams().set("errorId", errorId);
    return this.http.get<ResponseObject<NotificationTemplate>>(API_LOAD_NOTIFICATION_TEMPLATE, {params: params}).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getNotificationTemplate'))
    );
  }

  getNotificationMailConfig(): Observable<NotificationMailConfig> {
    return this.http.get<ResponseObject<NotificationMailConfig>>(API_LOAD_NOTIFICATION_MAIL_CONFIG).pipe(
      map(value => value.res),
      catchError(this.AL.handleError('getNotificationMailConfig'))
    );
  }
}
