<div class="row custom-scroll">
  <div class="col-md-12">
    <div class="card">
      <div class="mat-dialog-title">
        <div class="card-header card-header-icon" [ngClass]="AS.cardHeaderClass">
          <div class="row">
            <!-- <div class="col-md-12">
              <div class="card-icon">
                <i class="material-icons">info</i>
              </div>
              <h4 class="card-title">{{title | translate}}</h4>
            </div> -->
            <div class="col-md-12">
              <div class="card-icon">
                <i *ngIf="!icon" class="material-icons">info</i>
                <i *ngIf="icon" class="material-icons">{{icon}}</i>
              </div>
              <h4 class="card-title">{{title | translate}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="mat-dialog-content">
        <div class="card-body">
          <div >
            <p [innerHtml]="message">
              
            </p>

            <!-- <div [innerHTML]="post.body | SafeHtml"></div> -->
          </div>
        </div>
      </div>

      <div class="card-footer text-right">
        <div class="ml-auto">
          <button (click)="submitRemark()" id="submitRemark" mat-raised-button type="submit" class="btn"
            [ngClass]="AS.btnClass">
            {{buttonSubmit| translate}}</button>

          <button *ngIf="this.buttonCancel" (click)="closeRemark()" id="closeRemark" mat-raised-button type="submit" class="btn"
            [ngClass]="AS.btnClass">
            {{buttonCancel | translate}}</button>
          
        </div>
      </div>
    </div>
  </div>
</div>