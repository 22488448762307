import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './auth/auth-guard.service';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'pages',
      loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    }]
  },
  {
    path: '', loadChildren: () => import('./reetrak-layout/reeSource-layout.module').then(m => m.ReeSourceLayoutModule),
    canActivate: [AuthGuard],
  }
];