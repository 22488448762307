<div class="row custom-scroll">
  <div class="col-md-12">
    <div class="card">
      <div class="mat-dialog-title">
        <div class="card-header card-header-icon" [ngClass]="AS.cardHeaderClass">
          <div class="row">
            <div class="col-md-12">
              <div class="card-icon">
                <i class="material-icons">announcement</i>
              </div>
              <h4 class="card-title">{{title | translate}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="mat-dialog-content custom-scroll1">
        <div class="card-body">
          <div class="row" [formGroup]="remarkForm">
            <div class="col-md-12 col-sm-12">
              <mat-form-field>
                <textarea id="remarks" name="remarks" rows="5" formControlName="remarks" required matInput
                  placeholder="{{'REMARKS.INPUT' | translate}}" style="overflow:hidden">
                </textarea>
                <mat-error id="{{CT.USER_REMARK | NC:'CL'}}-Error" *ngIf="remarkForm.controls.remarks.invalid">
                  {{AL.EM(remarkForm.controls.remarks)}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-right">
        <div class="ml-auto">
          <button (click)="submitRemark()" id="submitRemark" [disabled]="!remarkForm.valid" mat-raised-button
            type="submit" class="btn" [ngClass]="[AS.btnClass, AS.btnSm]">{{'REMARKS.SUBMIT' | translate}}</button>
          <button (click)="closeRemark()" id="closeRemark" mat-raised-button type="submit" class="btn"
            [ngClass]="[AS.btnClass, AS.btnSm]">
            {{'REMARKS.CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>