import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'app/reetrak-layout/services/loader.service';
import { AuthServiceService } from 'app/services/auth-service.service';
import { User } from 'app/reetrak-layout/models/user';
import { NotificationService } from 'app/reetrak-layout/services/notification.service';
import { AppSettings } from 'app/reetrak-layout/util/app-settings';
import { Constants } from 'app/reetrak-layout/util/constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageUploadService } from 'app/reetrak-layout/services/image-upload.service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessError } from 'app/reetrak-layout/models/business-error';

import { CommonService } from 'app/reetrak-layout/services/common.service';
import { LovService } from 'app/reetrak-layout/services/lov.service';
import { ResetPasswordComponent } from "../../../pages/login/reset-password/reset-password.component";
import PerfectScrollbar from 'perfect-scrollbar';
import { ConfigService } from 'app/reetrak-layout/services/config.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})

export class EditProfileComponent implements OnInit {

  @ViewChild("profilePicture ", { static: true }) fileInput;
  loggedInUser: User = this.authService.getUser();
  imageSize: string;
  firstName: string;
  lastName: string;
  desg: string;
  AS = AppSettings;
  CT = Constants;
  imageData: any = this.loggedInUser.imgData;
  imageUploadSizeMessage: string;
  imageUploadExtensionMessage: string;
  intialImage: Boolean = false;
  profileImage = true;
  isReload: boolean;
  TRANSLATE_KEY = "EDIT_PROFILE.";

  constructor(public dialogRef: MatDialogRef<EditProfileComponent>, private loaderService: LoaderService, private authService: AuthServiceService,
    private notificationService: NotificationService, private dialog: MatDialog, private translate: TranslateService,
    private imageService: ImageUploadService, private commonService: CommonService, private lovService: LovService
    , private configService: ConfigService) {
    this.firstName = this.loggedInUser.firstName;
    this.lastName = this.loggedInUser.lastName;
    this.desg = this.loggedInUser.title;
  }


  ngOnInit() {
    this.updatePS();
    this.loaderService.display(false);
    this.translate.get("COMMON.IMAGE_UPLOAD").subscribe(val => this.imageUploadSizeMessage = val);
    this.translate.get("COMMON.IMAGE_EXTENSION").subscribe(val => this.imageUploadExtensionMessage = val);
    this.imageSize = this.commonService.getAppConfig(this.CT.AC_IMAGE_SIZE);
    if (this.loggedInUser.imgData == null) {
      this.intialImage = true;
      this.profileImage = false;
    }


    // this.imageData=this.loggedInUser.imgData;
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('reset-page');
    body.classList.remove('off-canvas-sidebar');
  }

  fileChange(file: FileList) {
    this.loaderService.display(true);
    let fi = file[0];
    let reader = new FileReader();
    if (fi && fi.size > 0) {
      reader.readAsDataURL(fi);
    }
    reader.onloadend = (e: any) => {
      if (!(fi.name.indexOf(".png") != -1 || fi.name.indexOf(".PNG") != -1 || fi.name.indexOf(".jpg") != -1 || fi.name.indexOf(".jpeg") != -1 || fi.name.indexOf(".JPG") != -1 || fi.name.indexOf(".JPEG") != -1)) {
        this.notificationService.warning(this.imageUploadExtensionMessage);
        this.loaderService.display(false);
      }
      else if ((fi.size / 1024) > Number(this.imageSize)) {
        this.notificationService.warning(this.imageUploadSizeMessage.replace("${1}", this.imageSize));
        this.loaderService.display(false);
      } else {
        this.setImageData(fi.name, (reader.result as string).substring((reader.result as string).indexOf(",") + 1));
        this.loaderService.display(false);
      }
    }
  }

  setImageData(imgName: string, imgData: any) {
    const newUser = { imgData: '', imgName: '' };
    newUser.imgData = imgData;
    newUser.imgName = imgName;
    this.submit(newUser);

  }

  updateUser(user: User, remarks?: string) {
    this.loggedInUser.imgData = user.imgData;
    this.loggedInUser.imgName = user.imgName;
    user = this.loggedInUser;
    this.imageService.updateUserImage(user, remarks).subscribe(
      (res: User) => {
        this.dialogRef.close({ 'user': res });
        this.translate.get("EDIT_PROFILE.SUCCESS").subscribe((res: string) => {
          this.notificationService.success(res);
        });
      }, (err: BusinessError) => {
        this.translate.get("EDIT_PROFILE.FAILURE").subscribe((res: string) => {
          this.notificationService.failure(res);
        })
      })
  }
  submit(user: any) {
    if (this.commonService.userRemarks()) {
      this.notificationService.userRemarks("USER_MANAGEMENT.REMARKS").subscribe(
        (remark) => this.updateUser(user, remark),
        (error) => console.log("remark cancel --:" + error)
      );
    }else {
      this.updateUser(user);
    }
  }

  changePassword() {

    let dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: '100%',
      hasBackdrop: true,
      panelClass: 'mat-dialog-custom-panel'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loaderService.display(false);
    });

  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
  closeCard()
  {
    this.dialogRef.close({'isReloade':this.isReload});
  }
}