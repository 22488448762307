<div class="container">
    <div class="col-lg-5 col-md-6 col-sm-12 ml-auto mr-auto">
        <div class="card card-profile">
            <div class="card-header">
                <a [routerLink]="null" (click)="closeCard()"><span class="pull-right clickable close-icon"><i
                            [ngClass]="AS.color" class="fa fa-times"></i></span></a>
                <div class="card-avatar">
                    <img class="img" [src]="'data:image/png;base64,' + imageData" *ngIf="profileImage" />
                    <img class="img" *ngIf="intialImage" src="./assets/img/new_logo.png" />
                </div>

            </div>
            <div class="card-body ">
                <div class="row">
                    <div class="col-md-12">
                        <h6 class="card-category text-gray">{{desg}}</h6>
                    </div>

                    <div class="col-md-12">
                        <h4 class="card-title">{{firstName}} {{lastName}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6" style="white-space: nowrap;">
                                <button class="btn btn-sm" [ngClass]="[AS.btnClass, AS.btnSm]"
                                    matTooltip="{{'EDIT_PROFILE.UPLOAD_BUTTON' | translate}}"
                                    onclick="FileInput.click()">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input id="FileInput" style="display:none;" #profilePicture type="file"
                                        accept=".jpg,.jpeg,.png" (change)="fileChange($event.target.files)" />
                                    {{'EDIT_PROFILE.UPLOAD_BUTTON' | translate}}
                                </button>
                            </div>

                            <div class="col-md-6" style="white-space: nowrap;">
                                <button class="btn btn-sm " [ngClass]="[AS.btnClass, AS.btnSm]"
                                    matTooltip="{{'EDIT_PROFILE.RESET_BUTTON' | translate}}" (click)="changePassword()">
                                    <i class="fa fa-key" id="btnChangePwd" aria-hidden="true"></i>
                                    {{'EDIT_PROFILE.RESET_BUTTON'
                                    | translate}}
                                </button>
                            </div>
                        </div>

                        <!-- <div class="col-md-12" style="white-space: nowrap;">
                            <button class="btn btn-sm " [ngClass]="[AS.btnClass, AS.btnSm]"
                                matTooltip="{{'EDIT_PROFILE.RESET_BUTTON' | translate}}" (click)="changePassword()">
                                <i class="fa fa-key" id="btnChangePwd" aria-hidden="true"></i>
                                {{'EDIT_PROFILE.RESET_BUTTON'
                                | translate}}
                            </button>
                        </div> -->
                        <!-- <span class="btn btn-round btn-file" [ngClass]="AS.btnClass" >{{'EDIT_PROFILE.UPLOAD_BUTTON' | translate}}
                                <input id="FileInput" (change)="fileChange($event.target.files)" #profilePicture type="file" accept=".jpg,.jpeg,.png"  matTooltip="{{'EDIT_PROFILE.UPLOAD_BUTTON' | translate}}" />
                            </span>
                    </div>
                    <div class="col-md-6">
                            <button id="btnChangePwd" class="btn btn-round" [ngClass]="AS.btnClass"  (click)="changePassword()"    matTooltip="{{'EDIT_PROFILE.RESET_BUTTON' | translate}}" >{{'EDIT_PROFILE.RESET_BUTTON' | translate}}</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>