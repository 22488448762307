import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from '../../../util/app-settings';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AliasService } from 'app/reetrak-layout/services/alias.service';
import { Constants } from '../../../util/constants';
import { LovService } from 'app/reetrak-layout/services/lov.service';
import { LovVal } from 'app/reetrak-layout/models/lov-val';
import { CommonService } from 'app/reetrak-layout/services/common.service';

@Component({
  selector: 'app-user-remarks-dropdown',
  templateUrl: './user-remarks-dropdown.component.html',
  styleUrls: ['./user-remarks-dropdown.component.scss']
})
export class UserRemarksDropdownComponent implements OnInit {
  title: string;
  remarks: string;
  AS = AppSettings;
  CT = Constants;
  remarkList: Array<LovVal>;
  show: boolean = false;

  remarkForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<UserRemarksDropdownComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private fb: FormBuilder, private AL: AliasService,
    private commonService: CommonService,
    private lovService: LovService
  ) {
    this.title = data.title;
    this.createForm();
    this.lovService.getLovMst(this.CT.LOV_MYLAN_REMARK_TYPE, true).subscribe(data => {
      this.remarkList = data.lovValList;
      this.setValue();
    })
  }

  ngOnInit() {
  }

  createForm() {
    this.remarkForm = this.fb.group({
      remarks: [{ value: '' }, this.AL.VL(this.CT.USER_REMARK)],
      other: ["", this.AL.VL(this.CT.REASON_OTHER)]
    });
  }
  setValue() {
    this.remarkForm.patchValue({
      remarks: '',
      other: ''
    })
  }
  submitRemark() {

    if (this.remarkForm.controls.remarks.value == "ANY OTHERS") {
      if (this.remarkForm.controls.other.value == "") {
        let tempArr = ['other'];
        this.commonService.formInValidFocusForFields(this.remarkForm, tempArr);
        return;
      }

      this.dialogRef.close(this.remarkForm.value.other);
    } else {

      if (this.remarkForm.controls.remarks.value == "") {
        let tempArr = ['remarks'];
        this.commonService.formInValidFocusForFields(this.remarkForm, tempArr);
        return;
      }
      this.dialogRef.close(this.remarkForm.value.remarks);
    }

  }
  closeRemark() {
    this.dialogRef.close();
  }

  enableVoid() {
    if (this.remarkForm.controls.remarks.value == "ANY OTHERS") {
      this.show = true;
    } else {
      // this.remarkForm.controls.remarks.markAsUntouched();
      this.show = false;
    }
  }

}
