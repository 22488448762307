<div class="wrapper wrapper-full-page">
  <div class="page-header" style="background: transparent;background-size: cover; background-position: top center;">
    <!--   you can change the color of the filter page using: data-color="blue | green | orange | red | purple" -->
    <div class="container">
      <div class="col-md-4 ml-auto mr-auto">
        <div class="card card-profile text-center">
          <div class="card-header ">
            <div class="card-avatar">
                <img class="img" [src]="'data:image/png;base64,' + imageData" *ngIf="profileImage"/>
            </div>
          </div>
          <div class="card-body ">
            <form class="form" [formGroup]="logInForm">
              <h4 class="card-title">{{loggedInUser.userName}}</h4>
              <mat-form-field class="example-full-width">
                <input id="password" id="password" matInput placeholder="Enter Password" class="form-control" formControlName="password" type="password" style="color: black">
                <mat-error *ngIf="logInForm.controls.password.invalid && logInForm.controls.password.touched">Please
                  enter valid password</mat-error>
              </mat-form-field>
            </form>
          </div>
          <div class="card-footer justify-content-center">
            <button [ngClass]="AS.btnClass" id="submit" type="submit" (click)="submit()" class="btn btn-round">Unlock</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer ">
  </footer>
</div>