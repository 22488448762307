import {Role} from "./role";
import {UserPlantMapping} from "./user-plant-mapping";
import {UserBrandMapping} from "./user-brand-mapping";
import { UserWarehouseMapping } from "./user-warehouse-mapping";

export class User {

    constructor() {
        this.role = new Role();
        this.userPlantMapping = [];
        this.userMstWarehouseMappings = [];
    }

    id: number;
    firstName: string;
    lastName: string;
    active: boolean;
    userName: string;
    password: string;
    email: string;
    phone: string;
    title: string;
    role: Role;
    firstTimeFlag: boolean;
    isAccountLock: number;
    lastLogin: Date;
    ipAddress : string;
    isPassSend : boolean;
    isLoggedIn : boolean;
    token : string;
    type : number;
    userPlantMapping : Array<UserPlantMapping>;
    imgName:string;
    imgData:Blob;
    userBrandMapping:Array<UserBrandMapping>;
    passChangeDate:Date;
    passwordExpired:boolean;
    loyaltyPoints: string;
    userMstWarehouseMappings: Array<UserWarehouseMapping>;
}

