<!-- First Time Set Password Screen -->
<div *ngIf="firstTimeFlag" class="wrapper">
  <div class="page-header login-page header-filter"
    style="background-image: url('./assets/img/login-bg1.jpg'); background-size: bottom cover; background-position: center; -moz-background-size: bottom cover ;">
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 mx-auto">
        <form class="form" [formGroup]="resetPasswordForm">
          <div class="card card-login">
            <div class="card-header text-center" [ngClass]="AS.cardHeaderClass">
              <h4 class="card-title">Set Password</h4>
            </div>
            <div class="card-body1">
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">face</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.token.valid}">
                    <input formControlName="token" matInput placeholder="{{CT.USER_TOKEN | NC:'VL'}}" type="password">
                    <mat-error *ngIf="resetPasswordForm.controls.token.invalid">
                      {{AL.EM(resetPasswordForm.controls.token)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.newPassword.valid}">
                    <input matInput formControlName="newPassword" placeholder="{{CT.USER_NEW_PASSWORD | NC:'VL'}}"
                      type="password">
                    <mat-error *ngIf="resetPasswordForm.controls.newPassword.invalid ">
                      {{AL.EM(resetPasswordForm.controls.newPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.confirmPassword.valid}">
                    <input matInput formControlName="confirmPassword"
                      placeholder="{{CT.USER_CONFIRM_PASSWORD | NC:'VL'}}" type="password">
                    <mat-error *ngIf="resetPasswordForm.controls.confirmPassword.invalid">
                      {{AL.EM(resetPasswordForm.controls.confirmPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="card-footer justify-content-center">
              <button (click)="submit()" [disabled]="!resetPasswordForm.valid" type="submit"
                [ngClass]="AS.btnClass" class="btn mat-raised-button">{{'Set'| translate}}</button>
              <button class="btn mat-raised-button" (click)="backPage()"
                [ngClass]="AS.btnClass">{{'Back'| translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<!-- Reset Password Screen -->
<div class="wrapper" *ngIf="!firstTimeFlag && this.view">
  <div class="page-header login-page header-filter" filter-color="black"
    style="background-image: url('./assets/img/login-bg1.jpg'); background-size: cover; background-position: top center;">
    <!-- <div class="page-header login-page header-filter" filter-color="black"> -->
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 mx-auto">
        <form class="form" [formGroup]="resetPasswordForm">
          <div class="card card-login">
            <div class="card-header text-center" [ngClass]="AS.cardHeaderClass">
              <h4 class="card-title">Reset Password</h4>
            </div>
            <div class="card-body1">
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">vpn_key</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field>
                    <input matInput formControlName="oldPassword" placeholder="{{CT.USER_PASSWORD | NC:'VL'}}"
                      type="password" id="oldPassword">
                    <mat-error *ngIf="resetPasswordForm.controls.oldPassword.invalid">
                      {{AL.EM(resetPasswordForm.controls.oldPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.newPassword.valid}">
                    <input matInput formControlName="newPassword" placeholder="{{CT.USER_NEW_PASSWORD | NC:'VL'}}"
                      type="password" id="newPassword">
                    <mat-error *ngIf="resetPasswordForm.controls.newPassword.invalid ">
                      {{AL.EM(resetPasswordForm.controls.newPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.confirmPassword.valid}">
                    <input matInput formControlName="confirmPassword" id="confirmPassword"
                      placeholder="{{CT.USER_CONFIRM_PASSWORD | NC:'VL'}}" type="password">
                    <mat-error *ngIf="resetPasswordForm.controls.confirmPassword.invalid">
                      {{AL.EM(resetPasswordForm.controls.confirmPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="card-footer justify-content-center">
              <button (click)="resetSubmit()" id="btnResetSubmit" [disabled]="!resetPasswordForm.valid" type="submit"
                class="btn mat-raised-button" [ngClass]="AS.btnClass">{{'Reset'| translate}}</button>
              <button class="btn mat-raised-button" (click)="backPage()"
                [ngClass]="AS.btnClass">{{'Back'| translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="!firstTimeFlag && !this.view">
  <div class="page-header login-page">
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 mx-auto">
        <form class="form" [formGroup]="resetPasswordForm">
          <div class="card card-login">
            <div class="card-header text-center" [ngClass]="AS.cardHeaderClass">
              <h4 class="card-title">Reset Password</h4>
            </div>
            <div class="card-body1" id="mainBody">
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">vpn_key</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field>
                    <input matInput id="oldPassword" formControlName="oldPassword" placeholder="{{CT.USER_PASSWORD | NC:'VL'}}"
                      type="password">
                    <mat-error *ngIf="resetPasswordForm.controls.oldPassword.invalid" id="oldPassword-Error">
                      {{AL.EM(resetPasswordForm.controls.oldPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.newPassword.valid}">
                    <input matInput formControlName="newPassword" placeholder="{{CT.USER_NEW_PASSWORD | NC:'VL'}}"
                      type="password" id="newPassword">
                    <mat-error *ngIf="resetPasswordForm.controls.newPassword.invalid" id="newPassword-Error">
                      {{AL.EM(resetPasswordForm.controls.newPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <!-- <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div> -->
                </div>
                <div class="col-md-10">
                  <mat-form-field [ngClass]="{'has-success':resetPasswordForm.controls.confirmPassword.valid}">
                    <input matInput formControlName="confirmPassword" id="confirmPassword"
                      placeholder="{{CT.USER_CONFIRM_PASSWORD | NC:'VL'}}" type="password">
                    <mat-error *ngIf="resetPasswordForm.controls.confirmPassword.invalid" id="confirmPassword-Error">
                      {{AL.EM(resetPasswordForm.controls.confirmPassword)}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="card-footer justify-content-center" id="footer">
              <button (click)="resetSubmit()" id="btnResetSubmit" [disabled]="!resetPasswordForm.valid" type="submit"
                class="btn mat-raised-button" [ngClass]="AS.btnClass">{{'Reset'| translate}}</button>
              <button class="btn mat-raised-button" (click)="backPage()" id="btnResetBack"
                [ngClass]="AS.btnClass">{{'Back'| translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>