import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { of, Observable, throwError as observableThrowError, forkJoin } from 'rxjs';
import { AuthServiceService } from '../services/auth-service.service';
import { Constants } from '../reetrak-layout/util/constants';
import { MenuService } from '../reetrak-layout/services/menu.service';
import { CommonService } from '../reetrak-layout/services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {

  CT = Constants;
  constructor(private authService: AuthServiceService, private router: Router, private menuService: MenuService,
  private commonService : CommonService) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead
      if (error.status === 401 || error.status === 404) {
        this.router.navigate(['login']);
      }
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  canActivate(): Observable<any> {
    return Observable.create((observer) => {
      forkJoin(
        this.authService.validateToken(),
        this.menuService.getMenuItems(),
        this.commonService.loadNomenclatureOb()
      ).subscribe(val => {        
        let validateToken = val[0];
        this.commonService.setMenuItems(val[1]);
        let user = this.authService.getUser();
        if(user && user.role.hierarchy == -1) {
          observer.next(true);
          this.router.navigate(['pages/login']);
          observer.complete();
          return;
        }
        // console.log("Auth guard service...");
        if (validateToken != this.CT.APP_FIRST_TIME_LOGIN) {
          observer.next(true);
          observer.complete();
          return;
        }
        observer.next(false);
        this.router.navigate(['pages/login']);
        observer.complete();
      }, (err) => {
        console.log(err);
        observer.next(false);
        observer.complete();
        // return observableThrowError(false);          
      });
      /**this.authService.validateToken().subscribe(
        (res) => {
          console.log(res);
          if (res != this.CT.APP_FIRST_TIME_LOGIN) {
            observer.next(true);
            observer.complete();
            return;
          }
          observer.next(false);
          this.router.navigate(['pages/login']);
          observer.complete();
        },
        (err) => {
          console.log(err);
          observer.next(false);
          observer.complete();
          // return observableThrowError(false);          
        }
      ); **/
    });
    // return this.authService.validateToken().pipe(
    //   catchError(this.handleError('error', true))
    // )


  }

}
