import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { ReeSourceInitModule } from './reetrak-layout/reeSource-init/reeSource-init.module';
import { StringFilterByPipe } from './reetrak-layout/pipes/string-filter-by.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthGuardCustomer } from './auth/auth-guard-customer.service';
import { ZXingScannerComponent, ZXingScannerModule } from '@zxing/ngx-scanner';
import { RecaptchaModule } from "ng-recaptcha";

@NgModule({
  exports: [
    ZXingScannerModule
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    MatNativeDateModule,
    ReeSourceInitModule,
    RecaptchaModule
  ],
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    StringFilterByPipe,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuardCustomer
  ]
})

export class AppModule { }