import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import { catchError, map} from 'rxjs/operators';
import { AliasService } from './alias.service';
import { API_USER_IMAGE_UPLOAD } from '../util/service-url';


@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  constructor(private http: HttpClient, private AL: AliasService) { }
  updateUserImage(user: User, remarks?: string) 
  {
    const params = new HttpParams().set("remarks", remarks);
    return this.http.post<any>(API_USER_IMAGE_UPLOAD, user, { params: params }).pipe(
      map(result => result.res),
      catchError(this.AL.handleError("ImageUploadService -> updateUser--: "))
    );
  }

}
