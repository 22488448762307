import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from '../../../util/app-settings';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AliasService } from 'app/reetrak-layout/services/alias.service';
import { Constants } from '../../../util/constants';

@Component({
  selector: 'app-user-remarks',
  templateUrl: './user-remarks.component.html',
  styleUrls: ['./user-remarks.component.scss']
})
export class UserRemarksComponent implements OnInit {
  title: string;
  remarks: string;
  AS = AppSettings;
  CT = Constants;

  remarkForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<UserRemarksComponent>, @Inject(MAT_DIALOG_DATA) data: any, private fb: FormBuilder, private AL: AliasService) {
    this.title = data.title;
    this.createForm();
    this.setValue();

  }

  ngOnInit() {    
  }

  createForm() {
    this.remarkForm = this.fb.group({
      remarks: [{ value: '' }, this.AL.VL(this.CT.USER_REMARK)]
    });
  }
  setValue()
  {
    this.remarkForm.patchValue({
      remarks:''
    })
  }
  submitRemark() {
    this.dialogRef.close(this.remarkForm.value.remarks);

  }
  closeRemark() {
    this.dialogRef.close();
  }

}
