const DATE_FORMAT = "MMM-DD-YYYY";
const DATE_TIME_FORMAT = "MMM-DD-YYYY HH:mm:ss";
export const Constants = {
  /** Application constants */

  COMPANY_TYPE: 0,
  CUSTOMER_TYPE: 1,

  ADMIN_ROLE: 1,
  MANUF_ROLE: 2,
  PLANT_ROLE: 3,
  USER_ROLE: 4,
  LOGISTIC_ROLE: 10,
  REESOURCE_ADMIN: 0,

  TYPE: {
    CORPORATE: 1,
    BRAND_OWNER: 2,
    LOGISTIC: 3
  },

  PRIVILEGES:
  {
    ADD: "Add",
    EDIT: "Edit",
    READ: "View",
    EXPORT: "Export",
    APPROVAL: "Approval",
    VOID_LOT_APPROVAL:"Void Lot Approval",
    PRINT_LABEL: "Print Label",
    DEACTIVE_LABEL:"Deactive Label",
    ACTIVATE_LABEL:"Activate Label"
  },
  ADDRESS_ICON: "fa fa-map-marker fa-2x",
  SHIPMENT: "SHIPMENT",

  REESOURCE_HIRERACHY: 1,
  SYSTEM_ADMIN_HIRERACHY: 2,
  MYLAN_DATE_FORMAT: "yyyyMMdd",
  SERVER_DATE_FORMAT: "MM/DD/YYYY HH:mm:ss",
  SERVER_DATE_FORMAT_WITHOUT_TIME: "MM/DD/YYYY 00:00:00",
  SERVER_DATE_FORMAT_FULL_TIME: "MM/DD/YYYY 23:59:59",
  UI_DATE_FORMAT: DATE_FORMAT,
  DATE_PICKER_FORMAT: {
    parse: {
      dateInput: DATE_FORMAT
    },
    display: {
      dateInput: DATE_FORMAT,
      monthYearLabel: "MMM YYYY",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MMMM YYYY"
    }
  },
  UI_DATE_TIME_FORMAT: DATE_TIME_FORMAT,

  COMMON_RESET_ICON: "autorenew",
  COMMON_ACTIVE_ICON: "done",
  COMMON_DEACTIVE_ICON: "block",
  COMMON_EDIT_ICON: "edit",
  COMMON_ADD_ICON: "add",
  COMMON_INFO_ICON: "info",
  COMMON_DELETE_FOREVER_ICON: "delete_forever",
  COMMON_DELETE_SWEEP_ICON: "flash_on",
  COMMON_REFRESH_ICON: "refresh",
  COMMON_VIEW_ICON: "remove_red_eye",
  COMMON_SHOW_PASSWORD: "visibility",
  COMMON_HIDE_PASSWORD: "visibility_off",
  COMMON_LOCK_ICON: "lock",
  COMMON_ACTION_ICON: "assignment_ind",
  COMMON_REGENERATE_KEY_ICON: "toys",
  COMMON_CANCEL_ICON: "cancel",
  COMMON_REORDER_ICON: "reorder",
  COMMON_CAMPARE_ICON: "swap_horizontal_circle",
  COMMON_ARROW_RIGHT_ICON: "subdirectory_arrow_right",
  COMMON_DOWNLOAD_ICON: "cloud_download",
  COMMON_ASSIGNMENT: "assignment",
  MULTIPLE_DOWNLOAD_ICON: "download-multiple",
  COMMON_UPLOAD_ICON: "cloud_upload",
  COMMON_QR_ICON: "center_focus_strong",
  COMMON_CODE_ICON: "code",
  COMMON_CODE_LIST: "list",
  COMMON_CODE_AUDIT_TRAIL: "receipt",
  COMMON_NO_RECORDS_TO_EXPORT: "COMMON.NO_RECORDS_TO_EXPORT",
  COMMON_NO_KEY_FOUND: "COMMON.NO_PRIVATE_KEY_FOUND",
  APP_FIRST_TIME_LOGIN: true,
  APP_ACTIVE: true,
  APP_DEACTIVE: false,
  APP_ACTIVE_1: 1,
  APP_DEACTIVE_0: -1,
  APP_VIEW: true,
  APP_PENDING_FOR_APPROVAL: 3,
  APP_PRODUCT_REJECT: 0,
  SUCCESS_CODE: 0,
  FAILURE_CODE: 99,
  LICENSE_EROOR_CODE: 98,
  BUSINESS_ERROR_CODE: 97,
  APP_USER_LOCKED: 1,
  EMAIL_REGEX: "EMAIL",
  NUMBER_VALIDATION: "NUMBER_VALIDATION",
  FLOT_NUMBER_VALIDATION: "FLOT_NUMBER_VALIDATION",
  SN_MANAGER: "JEKSON",
  PARTIAL_SSCC_ACTIVE: 1,
  PRI_LVL: 1,
  SEC_LVL: 2,
  TER_LVL: 3,
  FOURTH_LVL: 4,
  FIFTH_LVL: 5,
  SIXTH_LVL: 6,
  TEMP_CLOSE: 2,
  SERVER_NOT_CONNECTED: "Server is not connected",
  CODE_LENGTH_SSCC_ERROR: "Code length must be 18 for SSCC",
  EPCIS_REPROCESS_ICON: "settings_backup_restore",
  RE_TRIGGER_ICON: "settings_backup_restore",
  TRADE_TYPE: {
    GTIN: "GTIN",
    COMPANY_PREFIX: "COMPANY_PREFIX",
    NTIN: "NTIN"
  },

  SN_MANAGER_TYPE: {
    JEKSON: "JEKSON",
    RFXCEL: "RFXCEL",
    CRPT: "CRPT",
    TRACELINK: "TRACELINK",
    SYSTECH: "SYSTECH",
    ARVATO: "ARVATO",
    SAPICH: "SAP-ICH",
    NATIVETRACE: "NATIVETRACE"
  },

  COMPLIANCE: {
    MDLP: "MDLP",
    NA: "NA",
    EMVO: "EMVO",
    IVEDA: "IVEDA",
    NHRA: "NHRA",
    TATMEEN: "TATMEEN",
    AS2: "AS2",
    UZBEKISTAN: "UZBEKISTAN",
    DQSA: "DQSA",
    CHINA_VDTS: "CHINA-VDTS",
    WITH_CRYPTO: "WITH_CRYPTO",
    WITH_93: "WITH_93"
  },

  SN_MASTER_STATUS: {
    PACKING: "packing",
    COMMISSIONING: "commissioning",
    UNPACKING: "unpacking",
    DECOMMISSIONING: "decommissioning",
    DESTROYING: "destroying",
    INSPECTING: "Inspect",
    SHIPPING: "shipping",
    REPACKAGING: "repackaging"
  },

  LOCATION_TYPE: {
    SELF: "Self",
    CMO: "CMO",
    PLANT: "Plant",
    WAREHOUSE: "Warehouse"
  },

  SERIALCODE_GENERATION_TYPE: {
    SEQUENCE: 1,
    RANDOM: 2
  },

  APP_ID: {
    MANUFACTURER: 1,
    PLANT: 2,
    USER: 3,
    PRODUCT: 4,
    LINE: 5,
    BATCHREQUEST: 6,
    EPCIS: 7,
    SHIPMENT: 8,
    SECURITY_POLICY: 9,
    MDLP: 10,
    COMPANYPREFIXNAME: 11,
    ROLES: 12,
    SNMANAGER: 16,
    NOMENCLATURE: 17,
    EVENTMASTER: 18,
    CUSTOMER: 19,
    CONFIG: 20,
    THRESHOLDPOOL: 21,
    TRACING: 23,
    LOCATION_MASTER: 24,
    LOV_MST: 25,
    LABEL_MST: 26,
    REPACK: 27,
    SN_IN_BOUND: 28,
    SN_OUT_BOUND: 29,
    LOT_MASTER: 30,
    CORPORATE: 31,
    RULE_CONFIG: 32,
    NOTIFICATION: 34,
    MANAGE_MARKET: 40,
    //     MANAGE_MARKET: 37, // ToDo Merge
    AS2_INBOX: 46,
    MYLAN_PRINT_CODE: 101,
    PRINT_CODE: 51,
    SCAN_CODE: 52,
    WAREHOUSE_COMPLIANCE: 61,
    WAREHOUSE_MASTER: 62,
    WAREHOUSE_LOCATION_MASTER: 64,
    TATMEEN_SSCC_GENERATION: 82,
    MYLAN_SCAN_CODE: 102,
    FILE_UPLOAD: 131,
  },

  EMVO: {
    SEND_PROCESS_PRODUCT_SERVICE_SUBSCRIPTION_MESSAGE: "EMVO_SendProcessProductServiceSubscriptionMessage",
    SEND_PROCESS_PRODUCT_SERVICE_SUBSCRIPTION_ROLL_MESSAGE: "EMVO_SendProcessProductServiceSubscriptionRollMessage",
    SEND_PROCESS_PRODUCT_MASTER_DATA_MESSAGE: "EMVO_SendProcessProductMasterDataMessage",
    SEND_PROCESS_PRODUCT_PACK_DATA_MESSAGE: "EMVO_SendProcessProductPackDataMessage",
    SEND_PROCESS_PRODUCT_BATCH_RECALL_MESSAGE: "EMVO_SendProcessProductBatchRecallMessage",
    SEND_PROCESS_PRODUCT_PACK_STATE_UPDATE_MESSAGE: "EMVO_SendProcessProductPackStateUpdateMessage",
    SEND_PROCESS_PRODUCT_PACK_VERIFICATION_MESSAGE: "EMVO_SendProcessProductPackVerificationMessage",
    SEND_PROCESS_PRODUCT_PACK_DATA_UPDATE_MESSAGE: "EMVO_SendProcessProductPackDataUpdateMessage",
    SEND_PROCESS_PRODUCT_SERVICE_CONNECTION_TEST_MESSAGE: "EMVO_SendProcessProductServiceConnectionTestMessage",
    SEND_PRODUCT_WITHDRAWAL_MESSAGE: "EMVO_SendProductWithdrawalMessage",

    /** EMVO REPORTING  */

    SEND_REPORTING_RECALL_BATCH_REPORT: "EMVO_ReportingRecallBatchReport",
    SEND_REPORTING_PRODUCT_MASTER_DATA: "EMVO_ReportingProductMasterData",
    SEND_REPORTING_PACK_DISCLOSURE: "EMVO_ReportingPackDisclosure",
    SEND_REPORTING_PACKS_BY_BATCH: "EMVO_ReportingPacksByBatch",
    SEND_REPORTING_WITHDRAWN_PRODUCT: "EMVO_ReportingWithdrawnProduct",
    SEND_REPORTING_REPACKAGED_PACKS: "EMVO_ReportingRepackagedPacks",
    SEND_REPORTING_LINKING_REPORT_OF_BATCHES: "EMVO_ReportingLinkingReportOfBatches",
  },

  EVENT_552_WITHDRAWAL_TYPE: {
    SPOT_CHECK: 6,
    CUSTOMS_CONTROL: 7,
    FEDERAL_OVERSIGHT: 8,
    FOR_CLINICAL_TRIALS: 9,
    FOR_PHARMACEUTICAL_EXPERTISE_PURPOSES: 10,
    SHORTFALL: 11,
    DEMO_SAMPLING: 12,
    WRITE_OFF_WITHOUT_TRANSFER_FOR_DESTRUCTION: 13,
    WITHDRAWAL_OF_FEEDS_ACCUMULATED_DURING_THE_EXPERIMENT: 14,
    PRODUCTION_FAULT: 15,
    DECOMMISSIONING_OF_UNCOMPLETED_CONSUMER_PACKAGING: 16,
    MANUFACTURE_OF_MEDICAL_DEVICES: 17,
    MANUFACTURE_OF_MEDICAL_PRODUCTS: 18,
    CONTROL_SAMPLE_SELECTION_AS_PART_OF_THE_QUALITY_CONTROL_PROCESS: 19,
    ARCHIVE_SAMPLING: 20,
  },

  DAVA: 4,
  TRACELINK: 1,
  ANVISA: 2,
  ASTRAZ: 3,
  XMLINTEGRATION: 4,
  CSVINTEGRATION: 5,
  PHARMASECURE: 6,
  ATTP: 7,
  AXWAY: 8,
  RFXCEL: 9,
  SFDA: 10,
  ARVATO: 11,
  SAP_ICH: 12,

  SN_AGGREGATION: "SN_AGGREGATION",
  SN_COMMUNICATION: "SN_COMMUNICATION",
  SN_PRINT: "SN_PRINT",
  SN_PROVIDER: "SN_PROVIDER",
  PRODUCT_TRIGGER_SHIPPING: "Shipping",
  PRODUCT_TRIGGER_COMMISSION: "Commission",

  BATCH_HIERARCHY_FOR: "Batch Request",

  USER_BASE_HIERARCHY_TYPE: "User Based",
  ROLE_BASE_HIERARCHY_TYPE: "Role Based",
  REVIEW: "REVIEW",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  BATCH_HIERARCHY: 1,
  PRODUCT_HIERARCHY: 2,
  USER_BASE_HIERARCHY: 0,
  ROLE_BASE_HIERARCHY: 1,

  BY_LOT: 1,
  BY_SSCC: 2,
  BY_GTIN_CHILD: 3,
  BY_SSCC_CHILD: 4,
  BIND_WITH_SSCC: "3",
  BIND_WITH_LOT_SSCC: "2",
  BIND_WITH_GTIN: "1",
  DATE_DIFFERENCE_ERROR:
    "Please select From Date and To Date duration less than or equals to 180 days.",
  FILL_OUT: "Please Fill out this Field",
  ACTIVE: "Active",
  DEACTIVE: "Deactive",
  LOCKED: "Locked",
  UNPROCESSED: "Unprocessed",
  SUCCESS: "Success",
  FAILURE: "Failure",
  LICENSE_EXPIRED_ERROR_MSG: "LICENSE_EXPIRED_ERROR_MSG",
  LICENSE_NOT_FOR_REESOURCE: "LICENSE_NOT_FOR_REESOURCE",
  EDIT: "EDIT",
  NEW: "NEW",
  VIEW: "VIEW",

  /* App config*/

  AC_ISREMARKREQ: "ISREMARKREQ",
  AC_IMAGE_SIZE: "IMAGE_SIZE",
  AC_PRN_FILE_SIZE: "PRN_FILE_SIZE",
  AC_FILE_SIZE: "FILE_SIZE",
  AC_NO_OF_PLANTS: "NO_OF_PLANTS",
  AC_NO_OF_RULE: "NO_OF_RULE",
  AC_NO_OF_CUSTOMERS: "NO_OF_CUSTOMERS",
  AC_IMAGE_SIZE_FOR_USER: "USER_IMAGE_UPLOAD_SIZE",
  AC_REPORT_DATE_FORMAT: "REPORT_DATE_FORMAT",
  AC_APP_VERSION_TEXT: "APP_VERSION",
  AC_GMT_TIME_SHOW: "GMT_TIME_SHOW",
  AC_PRODUCT_APPROVAL: "PRODUCT_APPROVAL",
  AC_SN_MANAGER_APPROVAL: "SN_MANAGER_APPROVAL",
  REESOURCE_MODE: "REESOURCE_MODE",
  WAREHOUSE: "WAREHOUSE",

  MIN: "min",
  MAX: "max",
  REGEX: "regularExpression",

  COMMON_LABEL_ACTION: "COMMON_LABEL_ACTION",
  COMMON_OK: "COMMON.COMMON_OK",
  COMMON_CLOSE: "COMMON.COMMON_CLOSE",
  COMMON_YES: "COMMON.COMMON_YES",
  COMMON_ALERT: "COMMON.COMMON_ALERT",
  COMMON_RESET_PASSWORD: "COMMON.COMMON_RESET_PASSWORD",
  COMMON_NO: "COMMON.COMMON_NO",
  MESSAGE: "Are you sure to permanently Void ?",

  NA: "NA",
  REASON_OTHER: "REASON_OTHER",
  FILE_NAME: "FILE_NAME",
  PASSWORD: "PASSWORD",

  MANUFACTURER_ADDRESS: "MANUFACTURER_ADDRESS",
  MANUFACTURER_NAME: "MANUFACTURER_NAME",
  MANUFACTURER_GLN: "MANUFACTURER_GLN",
  MANUFACTURER_SGLN: "MANUFACTURER_SGLN",

  MANUFACTURER_COMPANY_PREFIX: "MANUFACTURER_COMPANY_PREFIX",
  MANUFACTURER_ADDRESS1: "MANUFACTURER_ADDRESS1",
  MANUFACTURER_ADDRESS2: "MANUFACTURER_ADDRESS2",
  MANUFACTURER_PIN_CODE: "MANUFACTURER_PIN_CODE",
  MANUFACTURER_CITY: "MANUFACTURER_CITY",
  MANUFACTURER_COUNTRY: "MANUFACTURER_COUNTRY",
  MANUFACTURER_UPLOAD_LOGO: "MANUFACTURER_UPLOAD_LOGO",
  MANUFACTURER_STATE: "MANUFACTURER_STATE",
  MANUFACTURER_PHONE: "MANUFACTURER_PHONE",
  MANUFACTURER_EMAIL: "MANUFACTURER_EMAIL",
  MANUFACTURER_BRAND_TYPE: "MANUFACTURER_BRAND_TYPE",
  MANUFACTURER_TYPE: "MANUFACTURER_TYPE",
  MANUFACTURER_CUSTOM_IDENTIFIER: "MANUFACTURER_CUSTOM_IDENTIFIER",
  MANUFACTURER_DISTRICT: "MANUFACTURER_DISTRICT",

  /**  Security Policy */

  COLONM_NAME: "COLONM_NAME",
  COLONM_VALUE: "COLONM_VALUE",

  CFR21_MINPASS_LENGTH: "CFR21_MINPASS_LENGTH",
  CFR21_PASSEXP_DAYS: "CFR21_PASSEXP_DAYS",
  CFR21_PROMTUSER_DAYS: "CFR21_PROMTUSER_DAYS",
  CFR21_USRELOCKOUT_ATTEMPTS: "CFR21_USRELOCKOUT_ATTEMPTS",
  CFR21_SCRLOCKOUT_DURATION: "CFR21_SCRLOCKOUT_DURATION",
  CFR21_DUPLICATE_PASSWORD: "CFR21_DUPLICATE_PASSWORD",
  CFR21_PASSMUST_CONTAIN_NUMBERS: "CFR21_PASSMUST_CONTAIN_NUMBERS",
  CFR21_NUMBERS_CHECK: "CFR21_NUMBERS_CHECK",
  CFR21_PASSMUST_CONTAIN_SYMBOLS: "CFR21_PASSMUST_CONTAIN_SYMBOLS",
  CFR21_SYMBOLS_CHECK: "CFR21_SYMBOLS_CHECK",
  CFR21_PASSMUST_CONTAIN_UPRCASE: "CFR21_PASSMUST_CONTAIN_UPRCASE",
  CFR21_UPPERCASE_CHECK: "CFR21_UPPERCASE_CHECK",

  /** Manage Plants */

  PLANT_NAME: "PLANT_NAME",
  PLANT_CODE: "PLANT_CODE",
  PLANT_LICENSE_NUMBER: "PLANT_LICENSE_NUMBER",
  PLANT_LICENSE_AGENCY: "PLANT_LICENSE_AGENCY",
  PLANT_LICENSE_STATE_OR_REGION: "PLANT_LICENSE_STATE_OR_REGION",
  PLANT_GLN_PREFIX: "PLANT_GLN_PREFIX",
  PLANT_GLOBAL_LOCATION_NUMBER: "PLANT_GLOBAL_LOCATION_NUMBER",
  PLANT_SGLN: "PLANT_SGLN",
  PLANT_SAME_AS_CMPADDRESS: "PLANT_SAME_AS_CMPADDRESS",
  PLANT_ADDRESS1: "PLANT_ADDRESS1",
  PLANT_ADDRESS2: "PLANT_ADDRESS2",
  PLANT_CITY: "PLANT_CITY",
  PLANT_PIN_CODE: "PLANT_PIN_CODE",
  PLANT_GTIN_PREFIX: "PLANT_GTIN_PREFIX",
  PLANT_STATE: "PLANT_STATE",
  PLANT_DISTRICT: "PLANT_DISTRICT",
  PLANT_COUNTRY: "PLANT_COUNTRY",
  PLANT_PHONE: "PLANT_PHONE",
  PLANT_EMAIL: "PLANT_EMAIL",
  PLANT_STATUS: "PLANT_STATUS",
  PLANT_ENDPOINT_DETAILS_USER_NAME: "PLANT_ENDPOINT_DETAILS_USER_NAME",
  PLANT_ENDPOINT_DETAILS_PASSWORD: "PLANT_ENDPOINT_DETAILS_PASSWORD",
  PLANT_GLN_TYPE: "PLANT_GLN_TYPE",
  PLANT_CUSTOM_IDENTIFIER: "PLANT_CUSTOM_IDENTIFIER",

  PLANT_GLN_TYPE_LIST: {
    GLN: "GLN",
    CUSTOM_GLN: "CUSTOM_GLN"
  },

  /** Manage Role */

  ROLE_NAME: "ROLE_NAME",
  ROLE_SELECT: "ROLE_TYPE",
  ROLE_PLANT: "ROLE_PLANT",
  ROLE_AVAILABLE_RIGHTS: "ROLE_AVAILABLE_RIGHTS",
  ROLE_ASSIGNED_RIGHTS: "ROLE_ASSIGNED_RIGHTS",
  ROLE_STATUS: "ROLE_STATUS",
  ROLE_TYPE: "ROLE_TYPE",
  ROLE_PLANT_NAME: "ROLE_PLANT_NAME",
  ROLE_HIERARCHY: "ROLE_HIERARCHY",

  /** Config */

  CONFIG_DESCRIPTION: "CONFIG_DESCRIPTION",
  CONFIG_NAME: "CONFIG_NAME",
  CONFIG_VALUE: "CONFIG_VALUE",
  REG_KEY: "REG_KEY",

  /** Nomenclature */

  NOMENCLATURE_NAME: "NOMENCLATURE_NAME",
  NOMENCLATURE_VALUE: "NOMENCLATURE_VALUE",
  NOMENCLATURE_DEFAULT_VALUE: "NOMENCLATURE_DEFAULT_VALUE",
  NOMENCLATURE_MIN: "NOMENCLATURE_MIN",
  NOMENCLATURE_MAX: "NOMENCLATURE_MAX",
  NOMENCLATURE_REGULAR_EXP: "NOMENCLATURE_REGULAR_EXP",
  NOMENCLATURE_REQUIRED: "NOMENCLATURE_REQUIRED",
  NOMENCLATURE_MIN_VALUE: "NOMENCLATURE_MIN_VALUE",
  NOMENCLATURE_MAX_VALUE: "NOMENCLATURE_MAX_VALUE",

  /** Sn Manager */

  SNMANAGER_NAME: "SNMANAGER_NAME",
  SNMANAGER_TYPE: "SNMANAGER_TYPE",
  SNMANAGER_JEKSON_COMPLIANCE: "SNMANAGER_JEKSON_COMPLIANCE",
  SNMANAGER_CUSTOM_SGLN: "SNMANAGER_CUSTOM_SGLN",
  SNMANAGER_WSDL_LOCATION: "SNMANAGER_WSDL_LOCATION",
  SNMANAGER_DOWNLOAD_USER_NAME: "SNMANAGER_DOWNLOAD_USER_NAME",
  SNMANAGER_DOWNLOAD_PASSWORD: "SNMANAGER_DOWNLOAD_PASSWORD",
  SNMANAGER_SENDER_ID: "SNMANAGER_SENDER_ID",
  SNMANAGER_RECEIVER_ID: "SNMANAGER_RECEIVER_ID",
  SNMANAGER_POSTING_TYPE: "SNMANAGER_POSTING_TYPE",
  SNMANAGER_POSTING_DETAILS: "SNMANAGER_POSTING_DETAILS",
  SNMANAGER_POSTING_USER_NAME: "SNMANAGER_POSTING_USER_NAME",
  SNMANAGER_POSTING_PASSWORD: "SNMANAGER_POSTING_PASSWORD",
  SNMANAGER_POSTING_FILE_LOCATION: "SNMANAGER_POSTING_FILE_LOCATION",
  SNMANAGER_TIMEZONE: "SNMANAGER_TIMEZONE",
  SNMANAGER_SPLIT_SIZE: "SNMANAGER_SPLIT_SIZE",
  SNMANAGER_ACTIVE: "SNMANAGER_ACTIVE",
  SNMANAGER_OLDER_VERSION: "SNMANAGER_OLDER_VERSION",
  SNMANAGER_DOWNLOAD_TIME_OUT: "SNMANAGER_DOWNLOAD_TIME_OUT",
  SNMANAGER_UPLOAD_PORT_NUMBER: "SNMANAGER_UPLOAD_PORT_NUMBER",
  SNMANAGER_POSTING_URL: "SNMANAGER_POSTING_URL",
  SNMANAGER_CUSTOM_IDENTIFIER_PLANT:
    "Custom Identifier value is not set for selected sender or receiver.",
  SNMANAGER_CUSTOM_IDENTIFIER_RECEIVER:
    "Custom Identifier is not present for selected Brand Owner",
  SNMANAGER_EXCLUDE_GLN: "SNMANAGER_EXCLUDE_GLN",

  THRESHOLD_TRADE_VALUE: "THRESHOLD_TRADE_VALUE",
  THRESHOLD_AVAILABLE_BUFFER: "THRESHOLD_AVAILABLE_BUFFER",
  THRESHOLD_MAXIMUM_BUFFER: "THRESHOLD_MAXIMUM_BUFFER",
  THRESHOLD_EXTENSION_DIGIT: "THRESHOLD_EXTENSION_DIGIT",
  THRESHOLD_LAST_GENERATED_VALUE: "THRESHOLD_LAST_GENERATED_VALUE",
  THRESHOLD_THRESHOLD: "THRESHOLD_THRESHOLD",
  THRESHOLD_CUST_CAPTION: "THRESHOLD_CUST_CAPTION",
  IVEDA_INFO_ICON: "filter_1",

  /** EVENT MASTER */

  ENTER_PROPER_THRESHOLD: "not update",

  EVENT_MASTER_NAME: "EVENT_MASTER_NAME",
  EVENT_MASTER_ACTION: "EVENT_MASTER_ACTION",
  EVENT_MASTER_BIZ_STEP: "EVENT_MASTER_BIZ_STEP",
  EVENT_MASTER_DISPOSITION: "EVENT_MASTER_DISPOSITION",
  EVENT_MASTER_EVENT_TYPE: "EVENT_MASTER_EVENT_TYPE",
  EVENT_MASTER_STATUS: "EVENT_MASTER_STATUS",
  EVENT_MASTER_SPECIMEN: "EVENT_MASTER_SPECIMEN",
  EVENT_MASTER_ACTIVE: "EVENT_MASTER_ACTIVE",
  EVENT_MASTER_READ_POINT: "EVENT_MASTER_READ_POINT",
  EVENT_MASTER_BIZ_LOCATION: "EVENT_MASTER_BIZ_LOCATION",
  EVENT_MASTER_BIZ_TRANSACTION: "EVENT_MASTER_BIZ_TRANSACTION",
  EVENT_MASTER_SAP_EXTENSION: "EVENT_MASTER_SAP_EXTENSION",
  EVENT_MASTER_TYPE: "EVENT_MASTER_TYPE",
  EVENT_MASTER_EXTENSION: "EVENT_MASTER_EXTENSION",
  EVENT_ADD_EXTENTION: "EVENT_ADD_EXTENTION",
  EVENT_LOCATION: "EVENT_LOCATION",
  ADD_ELEMENT: "ADD_ELEMENT",
  EVENT_MASTER_EXTENTION_NAME: "EVENT_MASTER_EXTENTION_NAME",
  EVENT_MASTER_EXTENSION_VALUE: "EVENT_MASTER_EXTENSION_VALUE",
  EVENT_MASTER_EXTENSION_PREFIX_VALUE: "EVENT_MASTER_EXTENSION_PREFIX_VALUE",
  EVENT_MASTER_EXTENSION_IS_SSCC: "EVENT_MASTER_EXTENSION_IS_SSCC",
  EVENT_MASTER_EXTENSION_IS_GTIN: "EVENT_MASTER_EXTENSION_IS_GTIN",
  ADD_EVENT_LOCATION: "ADD_EVENT_LOCATION",
  EVENT_MASTER_JEKSON_COMPLIANCE: "EVENT_MASTER_JEKSON_COMPLIANCE",
  EVENT_MASTER_DIRECTORY_NAME: "EVENT_MASTER_DIRECTORY_NAME",
  EVENT_MASTER_REASON: "EVENT_MASTER_REASON",

  /** SN Request Master */

  SN_INBOUND_FROM_DATE: "SN_INBOUND_FROM_DATE",
  SN_INBOUND_TO_DATE: "SN_INBOUND_TO_DATE",
  SN_INBOUND_REQUEST_ID: "SN_INBOUND_REQUEST_ID",
  SN_INBOUND_SN_RULE_TYPE: "SN_INBOUND_SN_RULE_TYPE",
  SN_INBOUND_SENDER: "SN_INBOUND_SENDER",
  SN_INBOUND_RECEIVER: "SN_INBOUND_RECEIVER",
  SN_INBOUND_SN_REQUEST_TYPE: "SN_INBOUND_SN_REQUEST_TYPE",
  SN_INBOUND_RESPONSE_TYPE: "SN_INBOUND_RESPONSE_TYPE",
  SN_INBOUND_RESPONSE_CODE: "SN_INBOUND_RESPONSE_CODE",
  SN_INBOUND_TRADE_TYPE: "SN_INBOUND_TRADE_TYPE",
  SN_INBOUND_TRADE_VALUE: "SN_INBOUND_TRADE_VALUE",
  SN_INBOUND_EXPORT_TYPE: "SN_INBOUND_EXPORT_TYPE",
  SN_INBOUND_COUNT: "SN_INBOUND_COUNT",
  SN_INBOUND_DATE: "SN_INBOUND_DATE",

  /**LOT Master */
  LOTMST_FROM_DATE: "LOTMST_FROM_DATE",
  LOTMST_TO_DATE: "LOTMST_TO_DATE",
  LOTMST_REQUEST_ID: "LOTMST_REQUEST_ID",
  LOTMST_SN_RULE_TYPE: "LOTMST_SN_RULE_TYPE",
  LOTMST_SENDER: "LOTMST_SENDER",
  LOTMST_RECEIVER: "LOTMST_RECEIVER",
  LOTMST_SN_REQUEST_TYPE: "LOTMST_SN_REQUEST_TYPE",
  LOTMST_RESPONSE_TYPE: "LOTMST_RESPONSE_TYPE",
  LOTMST_RESPONCE_CODE: "LOTMST_RESPONCE_CODE",
  LOTMST_TRADE_TYPE: "LOTMST_TRADE_TYPE",
  LOTMST_TRADE_VALUE: "LOTMST_TRADE_VALUE",
  LOTMST_EXPORT_TYPE: "LOTMST_EXPORT_TYPE",
  LOTMST_LOTNUMBER: "LOTMST_LOTNUMBER",
  LOTMST_PONUMBER: "LOTMST_PONUMBER",
  LOTMST_EXPDATE: "LOTMST_EXPDATE",
  LOTMST_MFGDATE: "LOTMST_MFGDATE",
  LOTMST_VOIDDATE: "LOTMST_VOIDDATE",
  LOTMST_STATUS: "LOTMST_STATUS",
  LOTMST_EVENTSTARTTIMING: "LOTMST_EVENTSTARTTIMING",
  LOTMST_EPCISEVENTID: "LOTMST_EPCISEVENTID",
  LOTMST_PRODUCTID: "LOTMST_PRODUCTID",
  LOT_MASTER_VOID_REASON: "LOT_MASTER_VOID_REASON",
  LOT_MASTER_REASON_OTHER: "LOT_MASTER_REASON_OTHER",
  /** Reetrak SN Request Master */

  SN_OUTBOUND_FROM_DATE: "SN_OUTBOUND_FROM_DATE",
  SN_OUTBOUND_TO_DATE: "SN_OUTBOUND_TO_DATE",
  SN_OUTBOUND_REQUEST_ID: "SN_OUTBOUND_REQUEST_ID",
  SN_OUTBOUND_SN_RULE_TYPE: "SN_OUTBOUND_SN_RULE_TYPE",
  SN_OUTBOUND_SENDER: "SN_OUTBOUND_SENDER",
  SN_OUTBOUND_RECEIVER: "SN_OUTBOUND_RECEIVER",
  SN_OUTBOUND_SN_REQUEST_TYPE: "SN_OUTBOUND_SN_REQUEST_TYPE",
  SN_OUTBOUND_RESPONSE_TYPE: "SN_OUTBOUND_RESPONSE_TYPE",
  SN_OUTBOUND_RESPONSE_CODE: "SN_OUTBOUND_RESPONSE_CODE",
  SN_OUTBOUND_TRADE_TYPE: "SN_OUTBOUND_TRADE_TYPE",
  SN_OUTBOUND_TRADE_VALUE: "SN_OUTBOUND_TRADE_VALUE",
  SN_OUTBOUND_EXPORT_TYPE: "SN_OUTBOUND_EXPORT_TYPE",
  SN_OUTBOUND_COUNT: "SN_OUTBOUND_COUNT",
  SN_OUTBOUND_DATE: "SN_OUTBOUND_DATE",

  /** User Management */

  USER_PLANT: "USER_PLANT",
  USER_ROLES: "USER_ROLES",
  USER_TYPE: "USER_TYPE",
  USER_NAME: "USER_NAME",
  USER_DESIGNATION: "USER_DESIGNATION",
  USER_FIRST_NAME: "USER_FIRST_NAME",
  USER_LAST_NAME: "USER_LAST_NAME",
  USER_EMAIL: "USER_EMAIL",
  USER_PHONE: "USER_PHONE",
  USER_STATUS: "USER_STATUS",
  USER_CHANGE_PASSWORD: "USER_CHANGE_PASSWORD",
  USER_NEW_PASSWORD: "USER_NEW_PASSWORD",
  USER_CONFIRM_PASSWORD: "USER_CONFIRM_PASSWORD",
  USER_PASSWORD: "USER_OLD_PASSWORD",
  USER_TOKEN: "USER_TOKEN",
  USER_PASSWORD_CHANGED: "USER_PASSWORD_CHANGED",
  USER_BRAND_OWNER: "USER_BRAND_OWNER",

  /** Product Management */

  PRODUCT_NAME: "PRODUCT_NAME",
  PRODUCT_COMPANY: "PRODUCT_COMPANY",
  PRODUCT_PLANT: "PRODUCT_PLANT",
  PRODUCT_COMPANY_DETAIL: "PRODUCT_COMPANY_DETAIL",
  PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
  PRODUCT_PRODUCT_TYPE: "PRODUCT_PRODUCT_TYPE",
  PRODUCT_CODE: "PRODUCT_CODE",
  PRODUCT_NUMBER: "PRODUCT_NUMBER",
  PRODUCT_INT_MATIRIAL_NUMBER: "PRODUCT_INT_MATIRIAL_NUMBER",
  PRODUCT_DOSE_FORM: "PRODUCT_DOSE_FORM",
  PRODUCT_PACK_TYPE: "PRODUCT_PACK_TYPE",
  PRODUCT_STRENGTH: "PRODUCT_STRENGTH",
  PRODUCT_GTIN_TYPE: "PRODUCT_GTIN_TYPE",
  PRODUCT_CUSTOMER_CODE: "PRODUCT_CUSTOMER_CODE",
  PRODUCT_PACKAGE_SIZE: "PRODUCT_PACKAGE_SIZE",
  PRODUCT_CONTROL_ID: "PRODUCT_CONTROL_ID",
  PRODUCT_PACKING_ID: "PRODUCT_PACKING_ID",
  PRODUCT_COUNTRY: "PRODUCT_COUNTRY",
  PRODUCT_OWNER_ID: "PRODUCT_OWNER_ID",
  PRODUCT_MAH_ID: "PRODUCT_MAH_ID",
  PRODUCT_UNIT_PRICE: "PRODUCT_UNIT_PRICE",
  PRODUCT_ORDER_TYPE: "PRODUCT_ORDER_TYPE",
  PRODUCT_CATEGORY_TYPE: "PRODUCT_CATEGORY_TYPE",
  PRODUCT_SUBJECT_ID: "PRODUCT_SUBJECT_ID",
  PRODUCT_PRIMARY_COUNT: "PRODUCT_PRIMARY_COUNT",
  PRODUCT_UPLOAD_IMAGE: "PRODUCT_UPLOAD_IMAGE",
  PRODUCT_LOYALTY_POINTS: "PRODUCT_LOYALTY_POINTS",
  PRODUCT_PACKING_TYPE: "PRODUCT_PACKING_TYPE",
  PRODUCT_GTIN_PREFIX: "PRODUCT_GTIN_PREFIX",
  //  PRODUCT_BATCH_PRODUCTION_TYPE        :    'PRODUCT_BATCH_PRODUCTION_TYPE',
  //   PRODUCT_UNITIZATION                  :    'PRODUCT_UNITIZATION',
  //   PRODUCT_SHIPMENT                     :    'PRODUCT_SHIPMENT',
  PRODUCT_BUFFER_CAPTION: "PRODUCT_BUFFER_CAPTION",
  PRODUCT_THRESHOLD_CAPTION: "PRODUCT_THRESHOLD_CAPTION",
  PRODUCT_OCR_DATE_FORMAT: "PRODUCT_OCR_DATE_FORMAT",
  // PRODUCT_BARCODE_DATE_FORMAT          :    'PRODUCT_BARCODE_DATE_FORMAT',
  // PRODUCT_PACKAGING_LEVEL_CAPTION      :    'PRODUCT_PACKAGING_LEVEL_CAPTION',
  PRODUCT_THRESHOLD_LEVEL_CAPTION: "PRODUCT_THRESHOLD_LEVEL_CAPTION",
  PRODUCT_BUFFER_COUNT_CAPTION: "PRODUCT_BUFFER_COUNT_CAPTION",
  PRODUCT_PACKAGING_TYPE_CAPTION: "PRODUCT_PACKAGING_TYPE_CAPTION ",
  PRODUCT_SN_MANAGER: "PRODUCT_SN_MANAGER",
  PRODUCT_DATA_STATUS: "PRODUCT_DATA_STATUS",
  PRODUCT_STATUS: "PRODUCT_STATUS",
  PRODUCT_NO_OF_FIELDS: "PRODUCT_NO_OF_FIELDS",
  PRODUCT_FIELD_NAME: "PRODUCT_FIELD_NAME",
  PRODUCT_FIELD_VALUE: "PRODUCT_FIELD_VALUE",
  PRODUCT_SKU_TYPE: "PRODUCT_SKU_TYPE",
  PRODUCT_TRADE_VALUE: "PRODUCT_TRADE_VALUE",
  PRODUCT_REQUIRED_SN: "PRODUCT_REQUIRED_SN",
  PRODUCT_LEVEL_SSCC: "PRODUCT_LEVEL_SSCC",
  PRODUCT_LEVEL_CAPTION: "PRODUCT_LEVEL_CAPTION",
  PRODUCT_TRADE_TYPE: "PRODUCT_TRADE_TYPE",
  PRODUCT_EXTENTION_DIGIT: "PRODUCT_EXTENTION_DIGIT",
  PRODUCT_CUSTOMER_CAPTION: "PRODUCT_CUSTOMER_CAPTION",
  PRODUCT_CAPTION: "PRODUCT_CAPTION",
  PRODUCT_LENGTH: "PRODUCT_LENGTH",
  PRODUCT_GENERATION_TYPE: "PRODUCT_GENERATION_TYPE",
  PRODUCT_LAST_GENERATED_VALUE: "PRODUCT_LAST_GENERATED_VALUE",
  PRODUCT_REFUSED_CHARACTER: "PRODUCT_REFUSED_CHARACTER",
  PRODUCT_REFUSED_CHARACTER_NOTE: "PRODUCT_REFUSED_CHARACTER_NOTE",
  PRODUCT_CUSTOMER_DETAIL: "PRODUCT_CUSTOMER_DETAIL",
  PRODUCT_PREFIX: "PRODUCT_PREFIX",
  PRODUCT_SERIAL_CODE_IE: "PRODUCT_SERIAL_CODE_IE",
  PRODUCT_COMPLIANCE_NAME: "PRODUCT_COMPLIANCE_NAME",
  PRODUCT_COMPLIANCE_CHECK_UNCHECK_ALL: "PRODUCT_COMPLIANCE_CHECK_UNCHECK_ALL",
  PRODUCT_OLDER_VERSION: "PRODUCT_OLDER_VERSION",
  PRODUCT_TRADEVALUE_CAN_NOT_BIND_WITH_RANDOM_AND_SEQUENCE: "PRODUCT_TRADEVALUE_CAN_NOT_BIND_WITH_RANDOM_AND_SEQUENCE",
  PRODUCT_EVENT_TRIGGER_VALUE: "PRODUCT_EVENT_TRIGGER_VALUE",
  PRODUCT_MDLP: "MDLP",
  PRODUCT_NA: "NA",
  PRODUCT_EMVO: "EMVO",
  PRODUCT_NHRA: "NHRA",
  PRODUCT_UZBEKISTAN: "UZBEKISTAN",
  PRODUCT_DQSA: "DQSA",
  PRODUCT_CHINA_VDTS: "CHINA-VDTS",
  PRODUCT_TRACELINK_SNXO: "TRACELINK_SNX",
  PRODUCT_TRACELINK_EPCIS: "TRACELINK_EPCIS",
  PRODUCT_WITH_CRYPTO: "PRODUCT_WITH_CRYPTO",
  PRODUCT_IS_CODE_GENERATED_BY_THIRD_PARTY: "PRODUCT_IS_CODE_GENERATED_BY_THIRD_PARTY",
  SNMANAGER_JEKSON_GENERATED_CODE: "SNMANAGER_JEKSON_GENERATED_CODE",
  PRODUCT_THRESHOLD_ZERO_ERROR:
    "Required Value must be greater than Threshold Value & Threshold Value must be greater than 0.",
  PRODUCT_THRESHOLD_REQUIRED_SN_ERROR:
    "Required Value must be greater than Threshold Value.",
  PRODUCT_COMMON_NAME: "PRODUCT_COMMON_NAME",
  PRODUCT_FORM: "PRODUCT_FORM",
  PRODUCT_VERSION_NO: "PRODUCT_VERSION_NO",
  PRODUCT_MARKET: "PRODUCT_MARKET",
  PRODUCT_IVEDA: "IVEDA-PRODUCT",
  PRODUCT_VAT_VALUE: "PRODUCT_VAT_VALUE",
  PRODUCT_COST_TAXES: "PRODUCT_COST_TAXES",
  PRODUCT_COUNTRY_DRUG_CODE_VALUE: "PRODUCT_COUNTRY_DRUG_CODE_VALUE",
  PRODUCT_COUNTRY_DRUG_CODE_TYPE: "PRODUCT_COUNTRY_DRUG_CODE_TYPE",
  PRODUCT_SALES_DISTRIBUTION_TYPE: "PRODUCT_SALES_DISTRIBUTION_TYPE",
  SNMANAGER_CUSTOM_IDENTIFEIR_SENDER_ARVATO: "SNMANAGER_CUSTOM_IDENTIFEIR_SENDER_ARVATO",
  SNMANAGER_CUSTOM_IDENTIFEIR_RECIEVER_ARVATO: "SNMANAGER_CUSTOM_IDENTIFEIR_RECIEVER_ARVATO",
  SNMANAGER_CUSTOM_IDENTIFEIR_SENDER_RFXCEL: "SNMANAGER_CUSTOM_IDENTIFEIR_SENDER_RFXCEL",
  SNMANAGER_CUSTOM_IDENTIFEIR_RECIEVER_RFXCEL: "SNMANAGER_CUSTOM_IDENTIFEIR_RECIEVER_RFXCEL",
  PRODUCT_TARDE_VALUE_BIND_WITH_MULTIPLE_PRODUCTS: "Note : This TradeValue Bind With Multiple Products",

  /** Report Module */
  REPORT_FROM_DATE: "REPORT_FROM_DATE",
  REPORT_TO_DATE: "REPORT_TO_DATE",
  REPORT_BATCH: "REPORT_BATCH",
  REPORT_BATCH_GRADES: "REPORT_BATCH_GRADES",
  REPORT_BATCH_LEVEL: "REPORT_BATCH_LEVEL",
  REPORT_BATCH_CODE: "REPORT_BATCH_CODE",
  REPORT_MODULES: "REPORT_MODULES",
  REPORT_SELECT_LINE: "REPORT_SELECT_LINE",
  REPORT_MODULE_NAME: "REPORT_MODULE_NAME",
  REPORT_USER_NAME: "REPORT_USER_NAME",

  // SHIPMENT Data Module
  SHIPMENT_BIZTRANSACTION_VALUE: "SHIPMENT_BIZTRANSACTION_VALUE",
  SHIPMENT_SOURCE_OWN_PARTY: "SHIPMENT_SOURCE_OWN_PARTY",
  SHIPMENT_SOURCE_LOCATION: "SHIPMENT_SOURCE_LOCATION",
  SHIPMENT_DESTINATION_OWN_PARTY: "SHIPMENT_DESTINATION_OWN_PARTY",
  SHIPMENT_DESTINATION_LOCATION: "SHIPMENT_DESTINATION_LOCATION",
  SHIPMENT_STATUS: "SHIPMENT_STATUS",

  // WAREHOUSE DISPATCH Data Module
  WAREHOUSE_DISPATCH_BIZTRANSACTION_VALUE: "WAREHOUSE_DISPATCH_BIZTRANSACTION_VALUE",
  WAREHOUSE_DISPATCH_SOURCE: "WAREHOUSE_DISPATCH_SOURCE",
  WAREHOUSE_DISPATCH_DESTINATION: "WAREHOUSE_DISPATCH_DESTINATION",
  WAREHOUSE_DISPATCH_STATUS: "WAREHOUSE_DISPATCH_STATUS",

  // WAREHOUSE RECEIPT Data Module
  WAREHOUSE_RECEIPT_BIZTRANSACTION_VALUE: "WAREHOUSE_RECEIPT_BIZTRANSACTION_VALUE",
  WAREHOUSE_RECEIPT_SOURCE: "WAREHOUSE_RECEIPT_SOURCE",
  WAREHOUSE_RECEIPT_DESTINATION: "WAREHOUSE_RECEIPT_DESTINATION",
  WAREHOUSE_RECEIPT_STATUS: "WAREHOUSE_RECEIPT_STATUS",

  // Lov Config
  LOV_LOVNAME: "LOV_LOVNAME",
  LOV_DESCR: "LOV_DESCR",
  LOV_ACTIVE: "LOV_ACTIVE",
  LOV_NAME: "LOV_NAME",
  LOV_VALUE: "LOV_VALUE",

  /** Lov Mst */
  LOV_USER_TYPE: "USER_TYPE",
  LOV_SNMANAGER_TYPE: "SN_MANAGER_SN_TYPE",
  LOV_ACTION_EVENT_TYPE: "ACTION_EVENT_TYPE",
  LOV_MDLP_EVENT_TYPE: "MDLP_EVENT_TYPE",
  LOV_SNMANAGER_POSTING_TYPE: "SN_MANAGER_SN_POSTING_TYPE",
  LOV_EVENTMASTER_TYPE: "EVENT_MASTER_EVENT_TYPE",
  LOV_MANAGE_PRODUCT_SKU_TYPE: "MANAGE_PRODUCT_SKU_TYPE",
  LOV_MANAGE_PRODUCT_PRODUCT_LEVEL: "MANAGE_PRODUCT_PRODUCT_LEVEL",
  LOV_MANAGE_PRODUCT_SCHEDULED: "MANAGE_PRODUCT_SCHEDULED",
  LOV_TRADE_TYPE: "TRADE_TYPE",
  LOV_EXPORTCSV_TYPE: "SN_REQUEST_EXPORT_CSV_FORMAT_TYPE",
  LOV_EXPORTCSV_TYPE_FOR_SSCC: "SN_CSV_EXPORT_FOR_SSCC",
  LOV_MANAGE_PRODUCT_CAPTION: "MANAGE_PRODUCT_CAPTION",
  LOV_MANAGE_PRODUCT_TRADE_TYPE: "MANAGE_PRODUCT_TRADE_TYPE",
  LOV_SN_MANAGER_TRADE_TYPE: "SN_MANAGER_TRADE_TYPE",
  LOV_PRODUCT_GENERATION_TYPE: "PRODUCT_GENERATION_TYPE",
  LOV_BRAND_ONER_TYPE: "BRAND_OWNER_TYPE",
  LOV_MDLP_LOT_TYPE: "MDLP_LOT_TYPE",
  LOV_LOCATION_TYPE: "LOCATION_TYPE",
  LOV_MODULE_FOR_AUDIT_TRAIL: "LOV_MODULE_FOR_AUDIT_TRAIL",
  LOV_SN_MANAGER_JEKSON_COMPLIANCE: "SN_MANAGER_JEKSON_COMPLIANCE",
  LOV_SN_MANAGER_TRACELINK_COMPLIANCE: "SN_MANAGER_TRACELINK_COMPLIANCE",
  LOV_SN_MANAGER_SAPICH_COMPLIANCE: "SN_MANAGER_SAPICH_COMPLIANCE",
  LOV_SN_MANAGER_NATIVETRACE_COMPLIANCE: "SN_MANAGER_NATIVETRACE_COMPLIANCE",
  LOV_BIZTRANSACTION_TYPE_CBV_LIST: "BIZTRANSACTION_TYPE_CBV_LIST",
  LOV_REESOURCE_EXT: "REESOURCE_EXT",
  LOV_REPACK_RADIO_BUTTON: "REPACK_RADIO_BUTTON",
  LOV_REPACK_BIND_WITH_RADIO_BUTTON: "LOV_REPACK_BIND_WITH_RADIO_BUTTON",
  LOV_MDLP_EVENT_LIST: "LOV_MDLP_EVENT_LIST",
  LOV_MDLP_CONTRACT_TYPE_333: "LOV_MDLP_CONTRACT_TYPE_333",
  LOV_MDLP_WITHDRAWAL_TYPE_552: "LOV_MDLP_WITHDRAWAL_TYPE_552",
  LOV_IVEDA_REGION_LIST: "IVEDA_REGION_LIST",
  LOV_IVEDA_SUPPLY_TYPE: "IVEDA_SUPPLY_TYPE",
  LOV_SN_OUTBOUND_CODE_TYPE: "LOV_SN_OUTBOUND_CODE_TYPE",
  LOV_PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  LOV_PRINT_TYPE: "PRINT_TYPE",
  LOV_API_PRINTER_PRINT_MODE: "API_PRINTER_PRINT_MODE",
  LOV_COMPLIANCE_TYPE: "LOV_COMPLIANCE_TYPE",
  LOV_MYLAN_REMARK_TYPE:"MYLAN_REMARK_TYPE",
  /**EPCIS MASTER */
  EPCIS_MASTER_REQUESTID: "EPCIS_MASTER_REQUESTID",
  EPCIS_MASTER_SENDERID: "EPCIS_MASTER_SENDERID",
  EPCIS_MASTER_REQUESTERID: "EPCIS_MASTER_REQUESTERID",
  EPCIS_MASTER_CREATIONDATE: "EPCIS_MASTER_CREATIONDATE",
  EPCIS_MASTER_DATE: "EPCIS_MASTER_DATE",
  EPCIS_MASTER_PROCESSINGSTATUS: "EPCIS_MASTER_PROCESSINGSTATUS",
  EPCIS_MASTER_PROCESSINGCODE: "EPCIS_MASTER_PROCESSINGCODE",
  EPCIS_MASTER_PROCESSINGDTLS: "EPCIS_MASTER_PROCESSINGDTLS",
  EPCIS_MASTER_FROM_DATE: "EPCIS_MASTER_FROM_DATE",
  EPCIS_MASTER_TO_DATE: "EPCIS_MASTER_TO_DATE",
  LABEL_JSON_NAME: "LABEL_JSON_NAME",

  /** User Remark */

  USER_REMARK: "REMARK_VALIDATION",


  /** Shipment POC */
  SENDER_GLN : "SENDER_GLN",
  RECEIVER_GLN : "RECEIVER_GLN",
  BIZ_TRANSACTION : "BIZ_TRANSACTION",
  BIZ_TRANSACTION_VALUE : "BIZ_TRANSACTION_VALUE",


  /** Trace In */

  TRACE_IN_TRADE_TYPE: "TRACE_IN_TRADE_TYPE",
  TRACE_IN_TRADE_VALUE: "TRACE_IN_TRADE_VALUE",
  TRACE_IN_CODE: "TRACE_IN_CODE",
  DASHBOARD_TRACE_IN_CODE: "DASHBOARD_TRACE_IN_CODE",
  SSCC: "SSCC",

  /** Deaggregation Child */

  DEAGGREGATION_CHILD_FROM_DATE: "DEAGGREGATION_CHILD_FROM_DATE",
  DEAGGREGATION_CHILD_TO_DATE: "DEAGGREGATION_CHILD_TO_DATE",
  DEAGGREGATION_CHILD_CODE: "DEAGGREGATION_CHILD_CODE",
  DEAGGREGATION_CHILD_LOT: "DEAGGREGATION_CHILD_LOT",
  DEAGGREGATION_CHILD_TRADEVALUE: "DEAGGREGATION_CHILD_TRADEVALUE",
  DEAGGREGATION_CHILD_LIST_TITLE: "DEAGGREGATION_CHILD_LIST_TITLE",
  DEAGGREGATION_CHILD_SEARCH_CODE: "DEAGGREGATION_CHILD_SEARCH_CODE",
  DEAGGREGATION_CHILD_LIST_ICON: "list",
  DEAGGREGATION_CHILD_BARCODE: "DEAGGREGATION_CHILD_BARCODE",
  DEAGGREGATION_CHILD_SELECTION: "DEAGGREGATION_CHILD_SELECTION",

  CODE_ALLOCATION_LOT: "CODE_ALLOCATION_LOT",
  CODE_ALLOCATION_REQUEST_COUNT: "CODE_ALLOCATION_REQUEST_COUNT",
  CODE_ALLOCATION_FROM_DATE: "CODE_ALLOCATION_FROM_DATE",
  CODE_ALLOCATION_TO_DATE: "CODE_ALLOCATION_TO_DATE",
  
  /** Deaggregation Code */

  DEAGGREGATE_CODE_LIST_TITLE: "DEAGGREGATE_CODE_LIST_TITLE",
  DEAGGREGATE_CODE_FROM_DATE: "DEAGGREGATE_CODE_FROM_DATE",
  DEAGGREGATE_CODE_TO_DATE: "DEAGGREGATE_CODE_TO_DATE",
  DEAGGREGATE_CODE_LOT: "DEAGGREGATE_CODE_LOT",
  DEAGGREGATE_CODE_TRADEVALUE: "DEAGGREGATE_CODE_TRADEVALUE",
  DEAGGREGATE_CODE: "DEAGGREGATE_CODE",
  DEAGGREGATE_CODE_SEARCH_CODE: "DEAGGREGATE_CODE_SEARCH_CODE",
  DEAGGREGATE_CODE_LIST_ICON: "list",
  DEAGGREGATE_CODE_BARCODE: "DEAGGREGATE_CODE_BARCODE",

  /** Aggregation */

  AGGREGATION_FROM_DATE: "AGGREGATION_FROM_DATE",
  AGGREGATION_TO_DATE: "AGGREGATION_TO_DATE",
  AGGREGATION_LOT: "AGGREGATION_LOT",
  AGGREGATION_TRADEVALUE: "AGGREGATION_TRADEVALUE",
  AGGREGATION_LIST_TITLE: "AGGREGATION_LIST_TITLE",
  AGGREGATION_LIST_ICON: "list",
  AGGREGATION_PARENT_CODE: "AGGREGATION_PARENT_CODE",
  AGGREGATION_SEARCH_CODE: "AGGREGATION_SEARCH_CODE",
  AGGREGATION_BARCODE: "AGGREGATION_BARCODE",
  AGGREGATION_PARENT_TRADEVALUE: "AGGREGATION_TRADEVALUE",

  //DISPOSITION
  DISPOSITION_BATCH: "DISPOSITION_BATCH",
  DISPOSITION_LEVEL: "DISPOSITION_LEVEL",
  DISPOSITION_CODE: "DISPOSITION_CODE",
  DISPOSITION_LISTCODE: "DISPOSITION_LISTCODE",
  DISPOSITION_SHOWLIST: "DISPOSITION_SHOWLIST",
  DISPOSITION_TRADE_VALUE: "DISPOSITION_TRADE_VALUE",
  DISPOSITION_SN_STATUS: "DISPOSITION_SN_STATUS",
  DISPOSITION_POSTING_STATUS: "DISPOSITION_POSTING_STATUS",
  DISPOSITION_SN_ACTION: "DISPOSITION_SN_ACTION",
  DISPOSITION_CHECK_UNCHECK_ALL: "DISPOSITION_CHECK_UNCHECK_ALL",
  DISPOSITION_COMMOM_SEARCH_ICON: "pageview",
  DISPOSITION_SEARCH_TITLE: "DISPOSITION_SEARCH_TITLE",
  DISPOSITION_COMMOM_LIST_ICON: "list",
  DISPOSITION_CODE_LIST: "DISPOSITION_CODE_LIST",
  DISPOSITION_FROM_DATE: "DISPOSITION_FROM_DATE",
  DISPOSITION_TO_DATE: "DISPOSITION_TO_DATE",
  LOV_DISPOSITION_SN_STATUS: "LOV_DISPOSITION_SN_STATUS",

  /** Event Trigger Module */
  POSTING_TYPE_LOV: "POSTING_TYPE_LOV",
  EVENT_TRIGGER_MODULE_FROM_DATE: "EVENT_TRIGGER_MODULE_FROM_DATE",
  EVENT_TRIGGER_MODULE_TO_DATE: "EVENT_TRIGGER_MODULE_TO_DATE",
  EVENT_TRIGGER_MODULE_LOT: "EVENT_TRIGGER_MODULE_LOT",
  EVENT_TRIGGER_MODULE_TRADE_VALUE: "EVENT_TRIGGER_MODULE_TRADE_VALUE",
  EVENT_TRIGGER_MODULE_STATUS: "EVENT_TRIGGER_MODULE_STATUS",
  EVENT_TRIGGER_MODULE_POSTING_STATUS: "EVENT_TRIGGER_MODULE_POSTING_STATUS",
  EVENT_TRIGGER_POSTING_STATUS: "EVENT_TRIGGER_POSTING_STATUS",

  /** Location Master */

  LOCATION_NAME: "LOCATION_NAME",
  LOCATION_GLN: "LOCATION_GLN",
  LOCATION_SGLN: "LOCATION_SGLN",
  LOCATION_ADDRESS1: "LOCATION_ADDRESS1",
  LOCATION_ADDRESS2: "LOCATION_ADDRESS2",
  LOCATION_CITY: "LOCATION_CITY",
  LOCATION_PIN_CODE: "LOCATION_PIN_CODE",
  LOCATION_STATE: "LOCATION_STATE",
  LOCATION_DISTRICT: "LOCATION_DISTRICT",
  LOCATION_COUNTRY: "LOCATION_COUNTRY",
  LOCATION_OWNER_TYPE: "LOCATION_OWNER_TYPE",
  LOCATION_STATUS: "LOCATION_STATUS",

  /** Shipment Void Form */
  SHIPMENT_POSTING_TYPE: "SHIPMENT_POSTING_TYPE",
  SHIPMENT_VOID_REASON: "SHIPMENT_VOID_REASON",
  SHIPMENT_VOID_OTHER_REASON: "SHIPMENT_VOID_OTHER_REASON",
  SHIPMENT_VOID_FROM_DATE: "SHIPMENT_VOID_FROM_DATE",
  SHIPMENT_VOID_TO_DATE: "SHIPMENT_VOID_TO_DATE",
  CAPS_LOCK: "CapsLock",

  /**Label master */
  LABEL_MASTER_UPLOAD_IMAGE: "LABEL_MASTER_UPLOAD_IMAGE",
  LABEL_MASTER_NAME: "LABEL_MASTER_NAME",
  LABEL_MASTER_DESC: "LABEL_MASTER_DESC",
  LABEL_IMG_NAME: "LABEL_IMG_NAME",
  LABEL_XML_NAME: "LABEL_XML_NAME",
  LABEL_MASTER_ACTIVE: "LABEL_MASTER_ACTIVE",
  LABEL_MST_IMAGE_NOT_FOUND: "LABEL_MST_IMAGE_NOT_FOUND",
  LABEL_MST_PRN_NOT_FOUND: "LABEL_MST_PRN_NOT_FOUND",

  /** Shipment Create Form */
  SHIPMENT_CREATE_PLANT: "SHIPMENT_CREATE_PLANT",
  SHIPMENT_CREATE_CUSTOMER_DETAIL: "SHIPMENT_CREATE_CUSTOMER_DETAIL",
  SHIPMENT_CREATE_BRAND_OWNER: "SHIPMENT_CREATE_BRAND_OWNER",
  SHIPMENT_CREATE_BIZTRANSACTION: "SHIPMENT_CREATE_BIZTRANSACTION",
  SHIPMENT_CREATE_OWNING_PARTY: "SHIPMENT_CREATE_OWNING_PARTY",
  SHIPMENT_CREATE_LOCATION: "SHIPMENT_CREATE_LOCATION",
  SHIPMENT_CREATE_SOURCE: "SHIPMENT_CREATE_SOURCE",
  SHIPMENT_CREATE_DESTINATION: "SHIPMENT_CREATE_DESTINATION",
  SHIPMENT_CREATE_SOURCE_OWNING_PARTY: "SHIPMENT_CREATE_SOURCE_OWNING_PARTY",
  SHIPMENT_CREATE_SOURCE_LOCATION: "SHIPMENT_CREATE_SOURCE_LOCATION",
  SHIPMENT_CREATE_DESTINATION_OWNING_PARTY:
    "SHIPMENT_CREATE_DESTINATION_OWNING_PARTY",
  SHIPMENT_CREATE_DESTINATION_LOCATION: "SHIPMENT_CREATE_DESTINATION_LOCATION",
  SHIPMENT_CREATE_BIZTRANSACTION_VALUE: "SHIPMENT_CREATE_BIZTRANSACTION_VALUE",
  SHIPMENT_CREATE_FROM_DATE: "SHIPMENT_CREATE_FROM_DATE",
  SHIPMENT_CREATE_TO_DATE: "SHIPMENT_CREATE_TO_DATE",
  SHIPMENT_CREATE_LOT: "SHIPMENT_CREATE_LOT",
  SHIPMENT_CREATE_SEARCH_CODE: "SHIPMENT_CREATE_SEARCH_CODE",
  SHIPMENT_CREATE_GLN: "SHIPMENT_CREATE_GLN",
  SHIPMENT_CREATE_EXTENSION_VALUE: "SHIPMENT_CREATE_EXTENSION_VALUE",
  SHIPMENT_CREATE_EXTENSION_KEY: "SHIPMENT_CREATE_EXTENSION_KEY",
  SHIPMENT_EXTENSION: "SHIPMENT_EXTENSION",
  BIZTRANSACTION: "BizTransaction",
  DESTINATIONLOCATION: "destinationLocation",
  SOURCELOCATION: "sourceLocation",
  DESTINATIONOWNINGPARTY: "destinationOwningParty",
  SOURCEOWNINGPARTY: "sourceOwningParty",
  CBV_VALUE: "urn:epcglobal:cbv:bt:",
  SHIPMENT_CREATE_TRADEVALUE: "SHIPMENT_CREATE_TRADEVALUE",
  SHIPMENT_CREATE_STATUS: "SHIPMENT_CREATE_STATUS",


  /** WAREHOUSE RECEIPT Create Form */
  WAREHOUSE_RECEIPT_CREATE_SOURCE: "WAREHOUSE_RECEIPT_CREATE_SOURCE",
  WAREHOUSE_RECEIPT_CREATE_SOURCE_NAME: "WAREHOUSE_RECEIPT_CREATE_SOURCE_NAME",
  WAREHOUSE_RECEIPT_CREATE_DESTINATION_NAME: "WAREHOUSE_RECEIPT_CREATE_DESTINATION_NAME",
  WAREHOUSE_RECEIPT_CREATE_DESTINATION: "WAREHOUSE_RECEIPT_CREATE_DESTINATION",
  WAREHOUSE_RECEIPT_RETURN_REASON: "WAREHOUSE_RECEIPT_RETURN_REASON",

  /** WAREHOUSE_DISPATCH Create Form */
  WAREHOUSE_DISPATCH_CREATE_SOURCE: "WAREHOUSE_DISPATCH_CREATE_SOURCE",
  WAREHOUSE_DISPATCH_CREATE_SOURCE_NAME: "WAREHOUSE_DISPATCH_CREATE_SOURCE_NAME",
  WAREHOUSE_DISPATCH_CREATE_DESTINATION_NAME: "WAREHOUSE_DISPATCH_CREATE_DESTINATION_NAME",
  WAREHOUSE_DISPATCH_CREATE_DESTINATION: "WAREHOUSE_DISPATCH_CREATE_DESTINATION",
  WAREHOUSE_DISPATCH_VOID_REASON: "WAREHOUSE_DISPATCH_VOID_REASON",

  /** Dynamic Form */

  DYNAMIC_FORM_NAME: "DYNAMIC_FORM_NAME",
  DYNAMIC_FORM_NOMENCLATURE_KEY: "DYNAMIC_FORM_NOMENCLATURE_KEY",
  DYNAMIC_FORM_DESC: "DYNAMIC_FORM_DESC",
  DYNAMIC_FORM_INPUT_TYPE: "DYNAMIC_FORM_INPUT_TYPE",
  /** MDLP Code */

  MDLP_CODE_LIST_TITLE: "MDLP_CODE_LIST_TITLE",
  MDLP_FROM_DATE: "MDLP_FROM_DATE",
  MDLP_TO_DATE: "MDLP_TO_DATE",
  MDLP_LOT: "MDLP_LOT",
  MDLP_LOT_TYPE: "MDLP_LOT_TYPE",
  MDLP_EVENT: "MDLP_EVENT",
  MDLP_CODE: "MDLP_CODE",
  MDLP_INFO_ICON: "filter_1",
  MDLP_DOC_DATE: "MDLP_DOC_DATE",
  MDLP_DOC_NUM: "MDLP_DOC_NUM",
  MDLP_CONFIRMATION_NUM: "MDLP_CONFIRMATION_NUM",
  MDLP_CONTROL_ID: "MDLP_CONTROL_ID",
  MDLP_SUBJECT_ID: "MDLP_SUBJECT_ID",
  MDLP_PACKING_ID: "MDLP_PACKING_ID",
  MDLP_OWNER_ID: "MDLP_OWNER_ID",
  MDLP_INN_NO: "MDLP_INN_NO",
  MDLP_KPP: "MDLP_KPP",
  MDLP_ORGANIZATION_ID: "MDLP_ORGANIZATION_ID",
  MDLP_RECEIVER_ID: "MDLP_RECEIVER_ID",
  MDLP_FIAS_ADDRESS: "MDLP_FIAS_ADDRESS",
  CONTRACT_TYPE_333: "CONTRACT_TYPE_333",
  WITHDRAWAL_TYPE_552: "WITHDRAWAL_TYPE_552",
  CUSTOM_RECEIVER_ID: "CUSTOM_RECEIVER_ID",
  SELLER_ID: "SELLER_ID",

  /** NHRA */

  NHRA_INFO_ICON: "filter_1",
  TATMEEN_INFO_ICON: "filter_1",
  UZBEKISTAN_INFO_ICON: "filter_1",
  SAP_ICH_INFO_ICON: "filter_1",
  DQSA_INFO_ICON: "filter_1",

  EVENT_313_DATE: "EVENT_313_DATE",
  EVENT_313_DOCUMENT_NO: "EVENT_313_DOCUMENT_NO",
  EVENT_313_CONFIRM_NO: "EVENT_313_CONFIRM_NO",

  EVENT_552_DATE: "EVENT_552_DATE",
  EVENT_552_DOCUMENT_NO: "EVENT_552_DOCUMENT_NO",
  EVENT_552_WITHDRAWAL: "EVENT_552_WITHDRAWAL",
  EVENT_552_STATUS: "EVENT_552_STATUS",

  EVENT_333_DOCUMENT_NO: "EVENT_333_DOCUMENT_NO",
  EVENT_333_DOC_DATE: "EVENT_333_DOC_DATE",
  EVENT_333_CONTRACT_TYPE: "EVENT_333_CONTRACT_TYPE",
  EVENT_333_CUSTOM_RECEIVE_ID: "EVENT_333_CUSTOM_RECEIVE_ID",
  EVENT_333_SELLER_ID: "EVENT_333_SELLER_ID",

  /* Event 335 */

  EVENT_335_REGISTRATION_DATE: "EVENT_335_REGISTRATION_DATE",
  EVENT_335_GTD_NO: "EVENT_335_GTD_NO",
  EVENT_335_CUSTOM_PROCEDURE_CODE: "EVENT_335_CUSTOM_PROCEDURE_CODE",
  EVENT_335_CUSTOM_CODE: "EVENT_335_CUSTOM_CODE",
  EVENT_335_CUSTOM_VALUE: "EVENT_335_CUSTOM_VALUE",

  EVENT_472_DOC_DATE: "EVENT_472_DOC_DATE",
  EVENT_472_DOCUMENT_NO: "EVENT_472_DOCUMENT_NO",
  EVENT_472_RECEIVER_ID: "EVENT_472_RECEIVER_ID",
  EVENT_472_TURN_OVER_TYPE: "EVENT_472_TURN_OVER_TYPE",

  EVENT_471_DOC_DATE: "EVENT_471_DOC_DATE",
  EVENT_471_DOCUMENT_NO: "EVENT_471_DOCUMENT_NO",
  EVENT_471_RECEIVER_ID: "EVENT_471_RECEIVER_ID",

  EVENT_415_DATE: "EVENT_415_DATE",
  EVENT_415_DOCUMENT_NO: "EVENT_415_DOCUMENT_NO",
  EVENT_415_DOC_DATE: "EVENT_415_DOC_DATE",
  EVENT_415_CONTRACT_TYPE: "EVENT_415_CONTRACT_TYPE",
  EVENT_415_CONTRACT_NO: "EVENT_415_CONTRACT_NO",
  EVENT_415_OPERATION_DATE: "EVENT_415_OPERATION_DATE",
  EVENT_415_VAT_VALUE: "EVENT_415_VAT_VALUE",
  EVENT_415_COST: "EVENT_415_COST",
  EVENT_415_TURNOVER_TYPE: "EVENT_415_TURNOVER_TYPE",
  EVENT_415_SOURCE: "EVENT_415_SOURCE",

  EVENT_542_DOCUMENT_NO: "EVENT_542_DOCUMENT_NO",
  EVENT_542_DESTRUCTION_METHOD: "EVENT_542_DESTRUCTION_METHOD",
  EVENT_542_DOC_DATE: "EVENT_542_DOC_DATE",

  EVENT_342_DOC_DATE: "EVENT_342_DOC_DATE",
  EVENT_342_CONFIRMATION_NO: "EVENT_342_CONFIRMATION_NO",
  EVENT_342_OPERATION_DATE: "EVENT_342_OPERATION_DATE",

  EVENT_341_DOCUMENT_NO: "EVENT_341_DOCUMENT_NO",
  EVENT_341_CUSTOM_SHIPPER_ID: "EVENT_341_CUSTOM_SHIPPER_ID",
  EVENT_341_DOC_DATE: "EVENT_341_DOC_DATE",

  EVENT_541_DOCUMENT_NO: "EVENT_541_DOCUMENT_NO",
  EVENT_541_ACT_NUMBER: "EVENT_541_ACT_NUMBER",
  EVENT_541_DOC_DATE: "EVENT_541_DOC_DATE",
  EVENT_541_ACT_DATE: "EVENT_541_ACT_DATE",
  EVENT_541_DESTRUCTION_TYPE: "EVENT_541_DESTRUCTION_TYPE",
  EVENT_541_DECISION: "EVENT_541_DECISION",
  EVENT_541_INN_NO: "EVENT_541_INN_NO",
  EVENT_541_FIAS_ADDRESS: "EVENT_541_FIAS_ADDRESS",
  EVENT_541_KPP: "EVENT_541_KPP",

  EVENT_391_DOC_DATE: "EVENT_391_DOC_DATE",
  EVENT_391_DOCUMENT_NO: "EVENT_391_DOCUMENT_NO",
  EVENT_391_WITHDRAWAL_REASON: "EVENT_391_WITHDRAWAL_REASON",
  EVENT_391_REASON_RETURN_TYPE: "EVENT_391_REASON_RETURN_TYPE",

  EVENT_250_REASON: "EVENT_250_REASON",

  EVENT_916_TRADE_VALUE: "EVENT_916_TRADE_VALUE",

  EVENT_215_RECEIVER_ID: "EVENT_215_RECEIVER_ID",

  EVENT_MASTER_TRACELINK: 'EVENT_MASTER_TRACELINK',

  EVENT_MASTER_SAPICH: 'EVENT_MASTER_SAPICH',

  EVENT_10319_EXPIRY_DATE: 'EVENT_10319_EXPIRY_DATE',
  EVENT_10319_SERIES_NUMBER: 'EVENT_10319_SERIES_NUMBER',
  EVENT_10319_DEVICE_ID: 'EVENT_10319_DEVICE_ID',
  EVENT_10319_ORIGIN_MSG_ID: 'EVENT_10319_ORIGIN_MSG_ID',
  EVENT_10319_REPORT_ID: 'EVENT_10319_REPORT_ID',

  EVENT_TYPE_913: "EVENT_TYPE_913",
  EVENT_TYPE_916: "EVENT_TYPE_916",
  APP_VERSION: "Version",

  EVENT_481_DOC_DATE: "EVENT_481_DOC_DATE",
  EVENT_481_DOCUMENT_NO: "EVENT_481_DOCUMENT_NO",
  EVENT_481_COUNTERPARTY_ID: "EVENT_481_COUNTERPARTY_ID",
  EVENT_481_SIGN_ARBITRATION: "EVENT_481_SIGN_ARBITRATION",
  ACCEPTED: "Accepted",
  OTHERS: "Others",

  MDLP_EVENT_CODE: {
    313: 313,
    915: 915,
    321: 321,
    415: 415,
    552: 552,
    333: 333,
    342: 342,
    10319: 10319,
    251: 251,
    335: 335,
    541: 541,
    341: 341,
    391: 391,
    472: 472,
    481: 481,
    542: 542,
    10311: 10311
  },

  // Iveda LotMaster Fields
  IVEDA_LOT_SUPPLY_TYPE: "IVEDA_LOT_SUPPLY_TYPE",
  IVEDA_LOT_EWAY_BILL_NO: "IVEDA_LOT_EWAY_BILL_NO",
  IVEDA_LOT_BILL_DATE: "IVEDA_LOT_BILL_DATE",
  IVEDA_LOT_PORT_NAME: "IVEDA_LOT_PORT_NAME",
  IVEDA_LOT_LENDING_PORT: "IVEDA_LOT_LENDING_PORT",
  IVEDA_LOT_PROCUREMENT_SOURCE_GSTN: "IVEDA_LOT_PROCUREMENT_SOURCE_GSTN",
  IVEDA_LOT_PROCUREMENT_SOURCE_NAME: "IVEDA_LOT_PROCUREMENT_SOURCE_NAME",
  IVEDA_LOT_PROCUREMENT_SOURCE_ADDRESS: "IVEDA_LOT_PROCUREMENT_SOURCE_ADDRESS",
  IVEDA_LOT_REGIONCD: "IVEDA_LOT_REGIONCD",

  MDLP_RULE_CONFIG: "MDLP_RULE_CONFIG",
  /** Notification Master */

  NOTIFICATIONS_FROM_DATE: "NOTIFICATIONS_FROM_DATE",
  NOTIFICATIONS_TO_DATE: "NOTIFICATIONS_TO_DATE",
  NOTIFICATIONS_ID: "NOTIFICATIONS_ID",
  NOTIFICATIONS_NOTIFICATION_ID: "NOTIFICATIONS_NOTIFICATION_ID",
  NOTIFICATIONS_MODULE_ID: "NOTIFICATIONS_MODULE_ID",
  NOTIFICATIONS_USER_ID: "NOTIFICATIONS_USER_ID",
  NOTIFICATIONS_MESSAGE_DETAIL: "NOTIFICATIONS_MESSAGE_DETAIL",
  NOTIFICATIONS_IS_MAIL_SENT: "NOTIFICATIONS_IS_MAIL_SENT",
  NOTIFICATIONS_IS_READ: "NOTIFICATIONS_IS_READ",
  NOTIFICATIONS_SENT_DATE: "NOTIFICATIONS_SENT_DATE",
  NOTIFICATIONS_READ_DATE: "NOTIFICATIONS_READ_DATE",
  NOTIFICATIONS_CREATED_DATE: "NOTIFICATIONS_CREATED_DATE",
  READ_SUCCESS_MESSAGE: "Successfully Marked as Read",
  NOTIFICATION_SETTINGS_ICON: "settings",
  NOTIFICATION_EMAILID: "NOTIFICATION_EMAILID",
  NOTIFICATION_PASSWORD: "NOTIFICATION_PASSWORD",
  NOTIFICATION_EMAIL_HOST: "NOTIFICATION_EMAIL_HOST",
  NOTIFICATION_EMAIL_PORT: "NOTIFICATION_EMAIL_PORT",
  NOTIFICATION_TEMPLATE: "NOTIFICATION_TEMPLATE",
  NOTIFICATION_ERROR_TYPE: "NOTIFICATION_ERROR_TYPE",

  /** Error Details */

  ERROR_DETAILS_ID: "ERROR_DETAILS_ID",
  ERROR_DETAILS_DESC: "ERROR_DETAILS_DESC",
  ERROR_CODE: "ERROR_CODE",
  ERROR_TYPE: "ERROR_TYPE",

  /**  Manage Market */

  MANAGE_MARKET_NAME: "MANAGE_MARKET_NAME",
  MANAGE_MARKET_OMS_ORG_ID: "MANAGE_MARKET_OMS_ORG_ID",
  MANAGE_MARKET_NATIONAL_CODE: "MANAGE_MARKET_NATIONAL_CODE",
  MANAGE_MARKET_ARTICLE_57_CODE: "MANAGE_MARKET_ARTICLE_57_CODE",
  MANAGE_MARKET_GLN: "MANAGE_MARKET_GLN",
  MANAGE_MARKET_ADDRESS1: "MANAGE_MARKET_ADDRESS1",
  MANAGE_MARKET_ADDRESS2: "MANAGE_MARKET_ADDRESS2",
  MANAGE_MARKET_CITY: "MANAGE_MARKET_CITY",
  MANAGE_MARKET_TARGET_MARKET: "MANAGE_MARKET_TARGET_MARKET",
  MANAGE_MARKET_COUNTRY_CODE: "MANAGE_MARKET_COUNTRY_CODE",
  MANAGE_MARKET_POSTAL_CODE: "MANAGE_MARKET_POSTAL_CODE",
  MANAGE_MARKET_IS_ACTIVE: "MANAGE_MARKET_IS_ACTIVE",

  /**  Manage Market */
  EMVO_GENERATE_TYPE: "EMVO_GENERATE_TYPE",
  //SN Status Update
  SN_STATUS_UPDATE_FROM_DATE: "SN_STATUS_UPDATE_FROM_DATE",
  SN_STATUS_UPDATE_TO_DATE: "SN_STATUS_UPDATE_TO_DATE",
  SN_STATUS_UPDATE_BATCH: "SN_STATUS_UPDATE_BATCH",
  SN_STATUS_UPDATE_FROM_EVENT: "SN_STATUS_UPDATE_FROM_EVENT",
  SN_STATUS_UPDATE_COMMON_LIST_ICON: "list",
  SN_STATUS_UPDATE_CODE_LIST: "SN_STATUS_UPDATE_CODE_LIST",
  SN_STATUS_UPDATE_EXPIRY_DATE: "SN_STATUS_UPDATE_EXPIRY_DATE",
  SN_STATUS_UPDATE_NEW_LOT_NO: "SN_STATUS_UPDATE_NEW_LOT_NO",

  /**Recall Batch */
  RECALL_BATCH_EFFECTIVE_DATE: "RECALL_BATCH_EFFECTIVE_DATE",
  RECALL_BATCH_INFORMATION: "RECALL_BATCH_INFORMATION",
  EMVO_RECALL_BATCH: "EMVO_RECALL_BATCH",


  /** Withdrawn Component */
  EMVO_WITHDRAWN_MESSAGE: "EMVO_WITHDRAWN_MESSAGE",
  EMVO_WITHDRAWN_EFFECTIVE_DATE: "EMVO_WITHDRAWN_EFFECTIVE_DATE",


  /**Product pack Data */

  EMVO_PRODUCT_PACK_DATA_LOT: "EMVO_PRODUCT_PACK_DATA_LOT",
  EMVO_PRODUCT_PACK_DATA_TRADEVALUE: "EMVO_PRODUCT_PACK_DATA_TRADEVALUE",

  /** Emvo Verification */

  EMVO_VERIFICATION_LOT: "EMVO_VERIFICATION_LOT",
  EMVO_VERIFICATION_TRADEVALUE: "EMVO_VERIFICATION_TRADEVALUE",

  /**Product pack status update */

  EMVO_PRODUCT_PACK_STATE_UPDATE_LOT: "EMVO_PRODUCT_PACK_STATE_UPDATE_LOT",
  EMVO_PRODUCT_PACK_STATE_UPDATE_TRADEVALUE: "EMVO_PRODUCT_PACK_STATE_UPDATE_TRADEVALUE",
  EMVO_PRODUCT_PACK_STATE_TO_DATE: "EMVO_PRODUCT_PACK_STATE_TO_DATE",
  EMVO_PRODUCT_PACK_STATE_BATCH: "EMVO_PRODUCT_PACK_STATE_BATCH",
  EMVO_PRODUCT_PACK_STATE_FROM_EVENT: "EMVO_PRODUCT_PACK_STATE_FROM_EVENT",
  PRDUCT_PACK_STATUS_UPDATE_GEN_TYPE: "PRDUCT_PACK_STATUS_UPDATE_GEN_TYPE",
  EMVO_PRODUCT_PACK_STATE_FROM_DATE: "EMVO_PRODUCT_PACK_STATE_FROM_DATE",
  EMVO_PRODUCT_PACK_STATE_COMMON_LIST_ICON: "list",
  EMVO_PRODUCT_PACK_STATE_CODE_LIST: "EMVO_PRODUCT_PACK_STATE_CODE_LIST",
  EMVO_PRODUCT_PACK_STATE_TO_STATUS: "EMVO_PRODUCT_PACK_STATE_TO_STATUS",

  EMVO_FROM_EVENT: "EMVO_FROM_EVENT",

  IVEDA_PRODUCT_TYPE: "IVEDA_PRODUCT_TYPE",
  IVEDA_PRODUCT_GENERIC_NAME: "IVEDA_PRODUCT_GENERIC_NAME",
  IVEDA_PRODUCT_COMPOSITION: "IVEDA_PRODUCT_COMPOSITION",
  IVEDA_PRODUCT_REMARK: "IVEDA_PRODUCT_REMARK",
  IVEDA_PRODUCT_STORAGE_CONDITION: "IVEDA_PRODUCT_STORAGE_CONDITION",
  IVEDA_PRODUCT_STRENGTH: "IVEDA_PRODUCT_STRENGTH",
  IVEDA_PRODUCT_DOSAGE: "IVEDA_PRODUCT_DOSAGE",
  IVEDA_PRODUCT_HS_CODE: "IVEDA_PRODUCT_HS_CODE",
  IVEDA_PRODUCT_SCHEDULED: "IVEDA_PRODUCT_SCHEDULED",

  PARSER:
  {
    SERIAL_NUMBER: "SERIAL_NUMBER",
    LOT_NUMBER: "LOT_NUMBER",
    EXPIRY_DATE: "EXPIRY_DATE",
    TRADE_TYPE: "TRADE_TYPE",
    TRADE_VALUE: "TRADE_VALUE"
  },


  LOT_MASTER_CREATE_LOT_NUMBER: "LOT_MASTER_CREATE_LOT_NUMBER",
  LOT_MASTER_CREATE_PO_NUMBER: "LOT_MASTER_CREATE_PO_NUMBER",
  LOT_MASTER_BATCH_SIZE: "LOT_MASTER_BATCH_SIZE",
  LOT_MASTER_CREATE_PRODUCT_CODE: "LOT_MASTER_CREATE_PRODUCT_CODE",
  LOT_MASTER_CREATE_SENDER: "LOT_MASTER_CREATE_SENDER",
  LOT_MASTER_CREATE_RECEIVER: "LOT_MASTER_CREATE_RECEIVER",
  LOT_MASTER_CREATE_MFG_DATE: "LOT_MASTER_CREATE_MFG_DATE",
  LOT_MASTER_CREATE_EXP_DATE: "LOT_MASTER_CREATE_EXP_DATE",
  LOT_MASTER_CREATE_EVENT_START_TIME: "LOT_MASTER_CREATE_EVENT_START_TIME",


  CHECK_CREDENTIALS: "CHECK_CREDENTIALS",
  AXA_AVAILABLE_COUNT: "AXA_AVAILABLE_COUNT",
  AXA_COUNT: "AXA_COUNT",
  AXA_TRADE_TYPE: "AXA_TRADE_TYPE",
  AXA_TRADE_VALUE: "AXA_TRADE_VALUE",
  AXA_SENDER_ID: "AXA_SENDER_ID",
  AXA_RECEIVER_ID: "AXA_RECEIVER_ID",
  AXA_PRODUCT: "AXA_PRODUCT",

  MDLP_OUTSIDE_RUSSIA: '2',
  MDLP_INSIDE_RUSSIA: '1',
  PRODUCT_CMO: 'Contract',
  PRODUCT_SELF: 'Self',
  NO_DATA: "NO_DATA",

  AGGREGATION: 'AGGREGATION',
  DEAGGREGATION: 'DEAGGREGATION',
  SCANPARENT: 'SCANPARENT',
  SCANCHILD: 'SCANCHILD',
  BYPARENT: 'BYPARENT',
  BYCHILD: 'BYCHILD',

  /** EMVO Request Report */

  EMVO_REPORT_REQUEST_PRODUCT_ID: "EMVO_REPORT_REQUEST_PRODUCT_ID",
  EMVO_REPORT_REQUEST_LOT_ID: "EMVO_REPORT_REQUEST_LOT_ID",
  EMVO_REPORT_REQUEST_PACCK_BY_BATCH_PRODUCT_STATE: "EMVO_REPORT_REQUEST_PACCK_BY_BATCH_PRODUCT_STATE",
  EMVO_REPORT_REQUEST_FORMAT_TYPE: "EMVO_REPORT_REQUEST_FORMAT_TYPE",


  CHINA_CUSTOMER_CODE: "CHINA_CUSTOMER_CODE",
  CHINA_TO_UNIT_CODE: "CHINA_TO_UNIT_CODE",
  CHINA_TO_UNIT: "CHINA_TO_UNIT",
  CHINA_TO_COUNTRY: "CHINA_TO_COUNTRY",
  CHINA_TO_UNIT_TYPE: "CHINA_TO_UNIT_TYPE",
  CHINA_TO_CITY: "CHINA_TO_CITY",
  CHINA_TO_PROVINCE: "CHINA_TO_PROVINCE",
  CHINA_TAG_RATIO: "CHINA_TAG_RATIO",
  CHINA_MIN_TAG_UNIT: "CHINA_MIN_TAG_UNIT",
  CHINA_MIN_PACK_UNIT: "CHINA_MIN_PACK_UNIT",
  CHINA_SPECIFICATION: "CHINA_SPECIFICATION",
  CHINA_MINIMUM_PACKAGE_SPECIFICATION: "CHINA_MINIMUM_PACKAGE_SPECIFICATION",
  CHINA_MANCODE: "CHINA_MANCODE",
  CHINA_REGISTRATION_EXPIRATION_DATE: "CHINA_REGISTRATION_EXPIRATION_DATE",
  CHINA_TELEPHONE_NUMBER: "CHINA_TELEPHONE_NUMBER",
  CHINA_COMPANY_NAME: "CHINA_COMPANY_NAME",
  CHINA_TRANSLATION: "CHINA_TRANSLATION",
  CHINA_REGISTRATION_LICENSE_NUMBER: "CHINA_REGISTRATION_LICENSE_NUMBER",
  CHINA_PRODUCT_ID: "CHINA_PRODUCT_ID",
  CHINA_LOT_IDS: "CHINA_LOT_IDS",

  EMVO_BIZSTEP: {
    EMVO_SendProcessProductServiceSubscriptionMessage: " Process Product Service Subscription",
    EMVO_SendProcessProductServiceSubscriptionRollMessage: "Process Product Service Subscription Roll",
    EMVO_SendProcessProductMasterDataMessage: "Process Product Master Data",
    EMVO_SendProcessProductPackDataMessage: "Process Product Pack Data",
    EMVO_SendProcessProductBatchRecallMessage: "Process Batch Recall",
    EMVO_SendProcessProductPackStateUpdateMessage: " Process Product Pack State Update",
    EMVO_SendProcessProductPackVerificationMessage: "Process Product Pack Verification",
    EMVO_SendProcessProductPackDataUpdateMessage: " Process Product Pack Data",
    EMVO_SendProcessProductServiceConnectionTestMessage: "Process Product Service Connection Test",
    EMVO_SendProductWithdrawalMessage: "Process Product Withdrawal Request",
    EMVO_ReportingRecallBatchReport: "Recall Batch Report",
    EMVO_ReportingProductMasterData: "Product Master Data Report",
    EMVO_ReportingPackDisclosure: "Pack Disclosure Report",
    EMVO_ReportingPacksByBatch: "Packs by Batch Report",
    EMVO_ReportingWithdrawnProduct: "Withdrawn Product Report",
    EMVO_ReportingRepackagedPacks: "Repackaged Packs Report",
    EMVO_ReportingLinkingReportOfBatches: "Linking Report of Batches Report"
  },

  /** AS2 Receiver(Inbox) */

  AS2_RECEIVER_SOURCE_IP: "AS2_RECEIVER_SOURCE_IP",
  AS2_RECEIVER_SOURCE_PORT: "AS2_RECEIVER_SOURCE_PORT",
  AS2_RECEIVER_DEST_IP: "AS2_RECEIVER_DEST_IP",
  AS2_RECEIVER_DEST_PORT: "AS2_RECEIVER_DEST_PORT",
  AS2_RECEIVER_CONTENT_TYPE: "AS2_RECEIVER_CONTENT_TYPE",
  AS2_RECEIVER_SENDER_AS2_ID: "AS2_RECEIVER_SENDER_AS2_ID",
  AS2_RECEIVER_RECEIVER_AS2_ID: "AS2_RECEIVER_RECEIVER_AS2_ID",
  AS2_RECEIVER_RECEIVER_MSG_ID: "AS2_RECEIVER_RECEIVER_MSG_ID",

  CANT_EXPORT_JSON_ERROR_MSG: "CANT_EXPORT_JSON_ERROR_MSG",

  SAME_SENDER_CHECKBOX_ERROR: "Please Select same sender checkbox",
  DATA_MISMATCHED_CHECKBOX_ERROR: "Data are mismatched in selected checkbox",


  VERIFY_PRODUCT_GTIN: "VERIFY_PRODUCT_GTIN",
  VERIFY_PRODUCT_SERIAL_NUMBER: "VERIFY_PRODUCT_SERIAL_NUMBER",
  VERIFY_PRODUCT_REQUEST_TYPE: "VERIFY_PRODUCT_REQUEST_TYPE",
  VERIFY_PRODUCT_GUID: "VERIFY_PRODUCT_GUID",
  VERIFY_PRODUCT_REQUEST_GLN: "VERIFY_PRODUCT_REQUEST_GLN",
  VERIFY_PRODUCT_LOT_NUMBER: "VERIFY_PRODUCT_LOT_NUMBER",
  VERIFY_PRODUCT_TRACE_IN_CODE: "VERIFY_PRODUCT_TRACE_IN_CODE",
  VERIFY_PRODUCT_EXPIRY_DATE: "VERIFY_PRODUCT_EXPIRY_DATE",
  VERIFY_PRODUCT_REQUEST_TYPE_LOV: "VERIFY_PRODUCT_REQUEST_TYPE_LOV",
  VERIFY_PRODUCT_DID: "VERIFY_PRODUCT_DID",
  VERIFY_CTRL_POSSESS_ATT: "VERIFY_CTRL_POSSESS_ATT",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_TELEPHONE: "VERIFY_TELEPHONE",
  VERIFY_CONTEXT: "VERIFY_CONTEXT",


  VERIFICATION_HISTORY_DATE: "VERIFICATION_HISTORY_DATE",
  VERIFICATION_HISTORY_GLN: "VERIFICATION_HISTORY_GLN",
  VERIFICATION_HISTORY_GTIN: "VERIFICATION_HISTORY_GTIN",
  VERIFICATION_HISTORY_SERIAL_NUMBER: "VERIFICATION_HISTORY_SERIAL_NUMBER",
  VERIFICATION_HISTORY_LOT_NUMBER: "VERIFICATION_HISTORY_LOT_NUMBER",
  VERIFICATION_HISTORY_EXPIRY_DATE: "VERIFICATION_HISTORY_EXPIRY_DATE",
  VERIFICATION_HISTORY_REQUEST_TYPE: "VERIFICATION_HISTORY_REQUEST_TYPE",
  VERIFICATION_HISTORY_RESULT: "VERIFICATION_HISTORY_RESULT",

  LOOKUPD_RECORD_GUID: "LOOKUPD_RECORD_GUID",
  LOOKUPD_RECORD_OWNER: "LOOKUPD_RECORD_OWNER",
  LOOKUPD_GTIN: "LOOKUPD_GTIN",
  LOOKUPD_CI: "LOOKUPD_CI",
  LOOKUPD_SRC_VRS: "LOOKUPD_SRC_VRS",
  LOOKUPD_START_EXP_DATE: "LOOKUPD_START_EXP_DATE",
  LOOKUPD_END_EXP_DATE: "LOOKUPD_END_EXP_DATE",
  LOOKUPD_STATUS: "LOOKUPD_STATUS",
  LOOKUPD_NEXT_RECORD_OWNER: "LOOKUPD_NEXT_RECORD_OWNER",
  LOOKUPD_LAST_MODIFIED_DATE: "LOOKUPD_LAST_MODIFIED_DATE",
  LOOKUP_DIRECTORY_STATUS: "LOOKUP_DIRECTORY_STATUS",

  PRINT_CODE_LOT: "PRINT_CODE_LOT",
  PRINT_CODE_BATCH_WEIGHT: "PRINT_CODE_BATCH_WEIGHT",
  PRINT_CODE_NET_WEIGHT: "PRINT_CODE_NET_WEIGHT",
  PRINT_CODE_TARE_WEIGHT: "PRINT_CODE_TARE_WEIGHT",
  PRINT_CODE_HANDLING_UNIT_NUMBER: "PRINT_CODE_HANDLING_UNIT_NUMBER",
  PRINT_CODE_COUNT: "PRINT_CODE_COUNT",
  PRINT_CODE_TRADE_TYPE: "PRINT_CODE_TRADE_TYPE",
  PRINT_CODE_TRADE_VALUE: "PRINT_CODE_TRADE_VALUE",
  PRINT_CODE_PRINT_TYPE: "PRINT_CODE_PRINT_TYPE",
  PRINT_CODE_DELAY: "PRINT_CODE_DELAY",
  PRINT_CODE_PRN: "PRINT_CODE_PRN",
  MYLAN_PRINT_CODE_PRINT_LABEL_COUNT: "MYLAN_PRINT_CODE_PRINT_LABEL_COUNT",
  PRINT_CODE_FROM_DATE: "PRINT_CODE_FROM_DATE",
  PRINT_CODE_TO_DATE: "PRINT_CODE_TO_DATE",
  PRINT_SCAN_CODE: "PRINT_SCAN_CODE",
  PRINT_MODE: "PRINT_MODE",
  MYLAN_PRINT_LABEL_PRN: "MYLAN_PRINT_LABEL_PRN",

  COMMON_SCANNED_CODE_COUNT: "Scanned Count",
  COMMON_LAST_SCAN_CODE: "Last Scan Code",
  COMMON_TOTAL_PROVISION_CODE_COUNT: "Provision Count",
  COMMON_TOTAL_COMMISSION_CODE_COUNT: "Commissioned Count",
  COMMON_TOTAL_PRINTED_CODE_COUNT: "Printed Count",

  SCAN_CODE_PRODUCT_CODE: "SCAN_CODE_PRODUCT_CODE",
  SCAN_CODE_BATCH_SIZE: "SCAN_CODE_BATCH_SIZE",
  SCAN_CODE_LABEL_SIZE: "SCAN_CODE_LABEL_SIZE",
  SCAN_CODE_MFG_DATE: "SCAN_CODE_MFG_DATE",
  SCAN_CODE_EXPIRY_DATE: "SCAN_CODE_EXPIRY_DATE",
  SCAN_CODE_PO_NUMBER: "SCAN_CODE_PO_NUMBER",
  SCAN_CODE_LOT_NUMBER: "SCAN_CODE_LOT_NUMBER",
  SCAN_CODE_AGGREGATED_CODE_SSCC: "SCAN_CODE_AGGREGATED_CODE_SSCC",
  SCAN_CODE_AGGREGATED_CODE_GTIN: "SCAN_CODE_AGGREGATED_CODE_GTIN",
  SCAN_CODE_CHILD_CODE: "SCAN_CODE_CHILD_CODE",
  SCAN_CODE_PARENT_CODE: "SCAN_CODE_PARENT_CODE",

  WAREHOUSE_SUPPLIER_TYPE: 'WAREHOUSE_SUPPLIER_TYPE',
  WAREHOUSE_ROLE: 2,
  WAREHOUSE_NAME: 'WAREHOUSE_NAME',
  WAREHOUSE_GLN: 'WAREHOUSE_GLN',
  WAREHOUSE_SGLN: "WAREHOUSE_SGLN",
  WAREHOUSE_ADDRESS1: 'WAREHOUSE_ADDRESS1',
  WAREHOUSE_ADDRESS2: 'WAREHOUSE_ADDRESS2',
  WAREHOUSE_CITY: 'WAREHOUSE_CITY',
  WAREHOUSE_PIN_CODE: 'WAREHOUSE_PIN_CODE',
  WAREHOUSE_STATE: 'WAREHOUSE_STATE',
  WAREHOUSE_COUNTRY: 'WAREHOUSE_COUNTRY',
  WAREHOUSE_PHONE: 'WAREHOUSE_PHONE',
  WAREHOUSE_EMAIL: 'WAREHOUSE_EMAIL',
  WAREHOUSE_ADMIN: 'WAREHOUSE_ADMIN',

  WAREHOUSE_TYPE_ROLE: {
    WAREHOUSE: 'WAREHOUSE',
    SITE: 'SITE',
    RETAILER: 'RETAILER',
    SFDA_WAREHOUSE: 'SFDA_WAREHOUSE',
    SFDA_SITE: 'SFDA_SITE',
  },

  SFDA_CT: {
    SFDA: 1,
    MODULE_SUPPLY_CHAIN: 0,
    MODULE_REPACK_DEAGGREGATION: 1,
    MODULE_REPACKREAGGREGATION: 2,
    LOCK_STATUS: 1,
    UNLOCK_STATUS: 0,
    LOT_CONFIRMED: 2,
    SN_PROCESSED: 0,
    SFDA_ACCEPT: 1,
    SFDA_IMPORT: 2,
    SFDA_RETURN: 3,
    SFDA_DISPATCH: 4,
    SFDA_EXPORT: 5,
    SFDA_DEACTIVATE: 6,
    SFDA_DISPATCH_CANCEL: 7,
    SFDA_EXPORT_CANCEL: 8,
    SFDA_DEACTIVATE_CANCEL: 9,
    SFDA_RETURN_CONFIRMTION: 19,
    BARCODE_HOMO: 0,
    BARCODE_HETRO: 1,
    SFDA_GLN: "SFDA_GLN",
    SFDA_COUNTRY_CODE: "COUNTRY_CODE",
    SFDA_DEACTIVATION_REASON: "DEACTIVATION_REASON",
    SFDA_DEACTIVATION_EXPLANATIN: "DEACTIVATION_EXPLANATION",
    SFDA_POST_SUCESS: "SUCCESS",
    SFDA_POST_PENDING: "PENDNG",
    SFDA_POST_FAILURE: "FAILURE",
    SFDA_SENDER: "SFDA_SENDER",
    SFDA_RECEIVER: "SFDA_RECEIVER",
    LOT_MST_ID: "lotMstId",
    COMPLIANCE_ID: "COMPLIANCE_ID",
    SFDA_PO_NUMBER: "SFDA_PO_NUMBER",
    SFDA_ACCEPT_EVENT: "Accept even",
    SFDA_IMPORT_EVENT: "Import event",
    SFDA_RETURN_EVENT: "Return event",
    SFDA_DISPATCH_EVENT: "Dispatch event",
    SFDA_DISPATCH_CANCEL_EVENT: "Dipatch cancel event",
    SFDA_RETURN_CONFIRMATION_EVENT: "Return(Dispach Cancel) event",
    SFDA_EXPORT_EVENT: "Export event",
    SFDA_EXPORT_CANCEL_EVENT: "Export cancel event",
    SFDA_DEACTIVATE_EVENT: "Deactivate event",
    SFDA_DEACTIVATE_CANCEL_EVENT: "Deactivate cancel event",
    ACCEPT_SUCCESS: "ACCEPT_SUCCES",
    IMPORT_SUCCESS: "IMPORT_SUCCESS",
    RETURN_SUCCESS: "RETURN_SUCCES",
    DISPATCH_SUCCESS: "DISPATCH_SUCCESS",
    EXPORT_SUCCESS: "EXPORT_SUCCESS",
    DEACTIVATE_SUCCESS: "DEACTIVATE_SUCCESS",
    DISPATCH_CANCEL_SUCCESS: "DISPATCH_CANCEL_SUCESS",
    RETURN_CONFIRMATION_SUCCESS: "RETURN_CONFIRMATION_SUCESS",
    EXPORT_CANCEL_SUCCESS: "EXPORT_CANCEL_SUCCESS",
    DEACTIVATE_CANCEL_SUCCESS: "DEACTIVATE_CANCEL_SUCCESS",
    SFDA_POSTING_DTL_NOT_CONFIGURED: "SFDA_POSTING_DTL_NOT_CONFIGURED",
    CANT_CREATE_DIR_AT_FILE_PATH: "System cannot crete directory to write file at path",

    SFDA_EVENT_SUCCESS: "Event performed sucessfully",

    SALABLECOUNT_EMPTY: "SALABLE_COUNT_EMPTY",
    EN: "en",
    RU: "ru",
    SFDA_ACCEPT_OFFILENE: "SFDA_ACCEPT_OFFILEN",
    SFDA_IMPORT_OFFILENE: "SFDA_IMPORT_OFFILENE",
    SFDA_RETURN_OFFILENE: "SFDA_RETURN_OFFILENE",
    SFDA_DISPATCH_OFFILENE: "SFDA_DISPATCH_OFFLENE",
    SFDA_DISPATCH_CANCEL_OFFILENE: "SFDA_DISPATCH_CANCEL_OFFLENE",
    SFDA_EXPORT_OFFILENE: "SFDA_EXPORT_OFFILENE",
    SFDA_EXPORT_CANCEL_OFFILENE: "SFDA_EXPORT_CANCEL_OFFILENE",
    SFDA_DEACTIVATE_OFFILENE: "SFDA_DEACTIVATE_OFFILEN",
    SFDA_DEACTIVATE_CANCEL_OFFILENE: "SFDA_DEACTIVATE_CANCEL_OFFILENE",
    // SFDA_RETURN_CONFIRMATION: "SFDA_RETURN_CONFIRMATION",
    SFDA_RETURN_CONFIRMATION_OFFILENE: "SFDA_RETURN_CONFIRMATION_OFFILENE",
    ACCEPT_IMPORT_EVENT_DD: "ACCEPT_IMPORT_EVENT_DD",
    SITE_ROLE: 3,
    RETAILER_ROLE: 4,
    SITE_NAME: 'SITE_NAME',
    SITE_MASTER: 'SITE_MASTER',
    RETAILER_NAME: 'RETAILER_NAME',
    RETAILER_MASTER: 'RETAILER_MASTER',
    HEADER_WAREHOUSE_MASTER: 'HEADER_WAREHOUSE_MASTER',
    HEADER_SITE_MASTER: 'HEADER_SITE_MASTER',
    HEADER_RETAILER_MASTER: 'HEADER_RETAILER_MASTER',
    EVENT_TYPE: 'EVENT_TYPE',
    LOTMST_ID: 'lotMstId',
    DEACTIVATE_REASON: 'DEACTIVATE_REASON',
    DEACTIVATE_REASON_TEXT: 'DEACTIVATE_REASON_TEXT',
    SITE_ADMIN: 'SITE_ADMIN',
    RETAILER_ADMIN: 'RETAILER_ADMIN',
    COUNTRY_NAME: 'COUNTRY_NAME',
    SFDA_USERNAME: 'SFDA_USERNAME',
    SFDA_PASSWORD: 'SFDA_PASSWORD',
    SFDA_OTHER_DETAILS: 'SFDA_OTHER_DETAILS',
    SFDA_FILE_PATH: 'SFDA_FILE_PATH',
    SFDA_FILE_SIZE: 'SFDA_FILE_SIZE',
    SFDA_TIMEOUT: 'SFDA_TIMEOUT',
    REEWARE_ADMIN: 'WMS_ADMIN',
    SEARCH_CODE: 'SEARCH_CODE',
    WAREHOUSE_SUPPLIER_TYPE: 'WAREHOUSE_SUPPLIER_TYPE',
    TRADE_VALUE_EXT_DIGIT: 'TRADE_VALUE_EXT_DIGIT',
    Lot_MST_TRADE_MAPPING: "lotMstTradeMapping",
    DEACTIVATE: 6,
    DISPATCH: 4,
    EXPORT: 5,
    RETURN: 3,
    ACCEPT: 1,
    IMPORT: 2,
    DEACTIVATE_CANCEL: 9,
    DISPATCH_CANCEL: 7,
    EXPORT_CANCEL: 8,
    SFDA_RETURN_CONFIRMATION: 19,
    SN_MASTER_LIST: 'SN_MASTER_LIST',
    BY_LOT_SELECTION: 0,
    BY_SSCC_SELECTION: 1,
    COUNTRY_CODE: 'COUNTRY_CODE',
    EXPORT_TYPE: 'EXPORT_TYPE',
    SUPPLY_CHAIN_OPERATION_DD: 'SUPPLY_CHAIN_OPERATION_DD',

    WAREHOUSE_NAME: 'WAREHOUSE_NAME',
    WAREHOUSE_GLN: 'WAREHOUSE_GLN',
    WAREHOUSE_ADDRESS1: 'WAREHOUSE_ADDRESS1',
    WAREHOUSE_ADDRESS2: 'WAREHOUSE_ADDRESS2',
    WAREHOUSE_CITY: 'WAREHOUSE_CITY',
    WAREHOUSE_PIN_CODE: 'WAREHOUSE_PIN_CODE',
    WAREHOUSE_STATE: 'WAREHOUSE_STATE',
    WAREHOUSE_COUNTRY: 'WAREHOUSE_COUNTRY',
    WAREHOUSE_PHONE: 'WAREHOUSE_PHONE',
    WAREHOUSE_EMAIL: 'WAREHOUSE_EMAIL',
    WAREHOUSE_ADMIN: 'WAREHOUSE_ADMIN',

    PO_COMPLIANCE: "PO_COMPLIANCE",

    INSIDE_KSA: 1,
    OUTSIDE_KSA: 2,
    DEACTIVATION_REASON: 'DEACTIVATION_REASON',
    DEACTIVATION_EXPLANATION: 'DEACTIVATION_EXPLANATION',
    TRACE_GOODS_WAREHOUSE_EVENT_TYPE: 'TRACE_GOODS_WAREHOUSE_EVENT_TYPE',
    TRANSFER_ID: "TRANSFER_ID",
    COMPLIANCE_SUCCESS_RESPONSE: "COMPLIANCE_SUCCESS_RESPONSE",
    COMPLIANCE_FAILURE_RESPONSE: "COMPLIANCE_FAILURE_RESPONSE",
    REESOURCE_SUCCESS: 1,
    REESOURCE_FAILURE: 0,
    EVENT_TRIGGER_NOT_PROCESSED: 0,
    EVENT_TRIGGER_PROCESSED: 1,
    EVENT_TRIGGER_FAILED_TO_PROCESSED: 2,
    EVENT_TRIGGER_BLOCKED: 3,
    SFDA_SELECT_LOT: "SFDA_SELECT_LOT",
    SFDA_OPERATIONS_EVENT: "SFDA_OPERATIONS_EVENT",
    SSCC_TRADE_VALUE: "SSCC_TRADE_VALUE",
    EVENT_BARCODE: "EVENT_BARCODE",
    SUPPLY: "SFDA_SUPPLY",
    DISPATCH_SFDA: "SFDA_DISPATCH",
    SCAN_CODE_HERE: 'SCAN_CODE_HERE',
    SFDA_SUPPLY: 101,
    SFDA_DISPATCH_LOT_MASTER: 'SFDA_DISPATCH_LOT_MASTER',
    REQUEST_FILE_CAPTION: 'REQUEST_FILE_CAPTION',
    RESPONSE_FILE_CAPTION: 'RESPONSE_FILE_CAPTION',
    POSTING_STATUS: 'POSTING_STATUS',
    DESCRIPTION: 'DESCRIPTION',
    SFDA_IMPORT_EVENT_STR: 'SFDA_IMPORT',
    SFDA_SELECT_WAREHOUSE: "SFDA_SELECT_WAREHOUSE",

    PTS_TRANSFER_ID: 'PTS_TRANSFER_ID',
    PTS_GLN: 'PTS_GLN',
    PTS_FROM_GLN: 'PTS_FROM_GLN',
    PTS_TO_GLN: 'PTS_TO_GLN',
    PACKAGE_TRANSFER_ID: 'PACKAGE_TRANSFER_ID',
    PACKAGE_TRANSFER_FILENAME: 'PACKAGE_TRANSFER_FILENAME',
    PACKAGE_TRANSFER_REASON: 'PACKAGE_TRANSFER_REASON',
    PACKAGE_TRANSFER_PROCESSING_STATUS: 'PACKAGE_TRANSFER_PROCESSING_STATUS',
    PACKAGE_TRANSFER_DOWNLOAD_STATUS: 'PACKAGE_TRANSFER_DOWNLOAD_STATUS',
    PACKAGE_TRANSFER_SENDER: 'PACKAGE_TRANSFER_SENDER',
    PACKAGE_TRANSFER_RECEIVER: 'PACKAGE_TRANSFER_RECEIVER',
    PACKAGE_QUERY_SEND_DATE: 'PACKAGE_QUERY_SEND_DATE',
    PACKAGE_QUERY_FIRST_TRANSFER_DATE: 'PACKAGE_QUERY_FIRST_TRANSFER_DATE',
    PACKAGE_QUERY_LAST_TRANSFER_DATE: 'PACKAGE_QUERY_LAST_TRANSFER_DATE',
    PACKAGE_QUERY_CHECKSUM: 'PACKAGE_QUERY_CHECKSUM',
    GLN_PREFIX: '628',
    SFDA_ACCEPT_STR: 'Accept',
    SFDA_IMPORT_STR: 'Import',
    COMMON_DAYS_DIFFERENCE: 30,
    COMMON_DAYS_DIFFERENCE_SUMMARY: 7,
    DESTINATION_GLN: 'DESTINATION_GLN',
    PRODUCT_COMPLIANCE_TYPE: 'PRODUCT_COMPLIANCE_TYPE',

    SSCC_NAME: 'SSCC_NAME',
    SSCC_TRADE_TYPE: 'SSCC_TRADE_TYPE',
    SSCC_EXTENSION_DIGIT: 'SSCC_EXTENSION_DIGIT',
    SSCC_COUNTER: 'SSCC_COUNTER',
    CHAR_INCLUDING_EXT: 'CHAR_INCLUDING_EXT',

    LOV_SSCC_TRADE_TYPE: 'SSCC_TRADE_TYPE',
    LOV_SSCC_EXTENSION_DIGIT: 'SSCC_EXTENSION_DIGIT',
    USER_WAREHOUSE_MASTER: 'USER_WAREHOUSE_MASTER',

    AC_IMAGE_SIZE: 'MAXIMUM_IMAGE_SIZE',
    AC_MINIMUM_IMAGE_SIZE: 'MINIMUM_IMAGE_SIZE',
    AC_MINIMUM_PRN_SIZE: 'MINIMUM_PRN_SIZE',
    AC_PRN_SIZE: 'MAXIMUM_PRN_SIZE',
    PRINT_SERVER_NOT_CONNECTED: 'Printer Service is not connected',

    REPRINT_DATE_FORMAT: 'REPRINT_DATE_FORMAT',
    REPRINT_BCD_DATE: 'REPRINT_BCD_DATE',
    WAREHOUSE_REPACK_TRADEVALUE: 'WAREHOUSE_REPACK_TRADEVALUE',
    BATCH_IN_PROCESS_FAILURE: 90,
    PRN_PRODUCT: 'PRN_PRODUCT',
    LOT_ACCEPT_IMPORT: 'acceptedImportedCodeList',
    LOT_TOTAL: 'total',
    LOT_DISPATCH: 'dispatchCodeList',
    LOT_RETURN: 'returnCodeList',
    LOT_DEACTIVATE: 'deactivatedCodeList',
    LOT_EXPORT: 'exportCodeList',
    LOT_PENDING: 'pendingCodeList',
    TRACE_IN: 'TRACE_IN'
  },

  SCAN_LOT: "SCAN_LOT",
  AGG_OF_CHILD: "AGG_OF_CHILD",
  AGG_OF_PARENT: "AGG_OF_PARENT",
  DE_AGG_CHILD: "DE_AGG_CHILD",
  DE_AGG_PARENT: "DE_AGG_PARENT",
  WAREHOUSE_COMPLIANCE_NAME: "WAREHOUSE_COMPLIANCE_NAME",
  WAREHOUSE_COMPLIANCE_WAREHOUSE: "WAREHOUSE_COMPLIANCE_WAREHOUSE",
  WAREHOUSE_COMPLIANCE_SENDER_ID: "WAREHOUSE_COMPLIANCE_SENDER_ID",
  WAREHOUSE_COMPLIANCE_RECEIVER_ID: "WAREHOUSE_COMPLIANCE_RECEIVER_ID",
  WAREHOUSE_COMPLIANCE_POSTING_TYPE: "WAREHOUSE_COMPLIANCE_POSTING_TYPE",
  WAREHOUSE_COMPLIANCE_POSTING_USER_NAME: "WAREHOUSE_COMPLIANCE_POSTING_USER_NAME",
  WAREHOUSE_COMPLIANCE_POSTING_PASSWORD: "WAREHOUSE_COMPLIANCE_POSTING_PASSWORD",
  WAREHOUSE_COMPLIANCE_POSTING_URL: "WAREHOUSE_COMPLIANCE_POSTING_URL",
  WAREHOUSE_COMPLIANCE_TYPE: "WAREHOUSE_COMPLIANCE_TYPE",

  /**Warehouse Location Master */

  WAREHOUSE_LOCATION_NAME: "WAREHOUSE_LOCATION_NAME",
  WAREHOUSE_LOCATION_GLN: "WAREHOUSE_LOCATION_GLN",
  WAREHOUSE_LOCATION_SGLN: "WAREHOUSE_LOCATION_SGLN",
  WAREHOUSE_LOCATION_ADDRESS1: "WAREHOUSE_LOCATION_ADDRESS1",
  WAREHOUSE_LOCATION_ADDRESS2: "WAREHOUSE_LOCATION_ADDRESS2",
  WAREHOUSE_LOCATION_CITY: "WAREHOUSE_LOCATION_CITY",
  WAREHOUSE_LOCATION_PIN_CODE: "WAREHOUSE_LOCATION_PIN_CODE",
  WAREHOUSE_LOCATION_STATE: "WAREHOUSE_LOCATION_STATE",
  WAREHOUSE_LOCATION_DISTRICT: "WAREHOUSE_LOCATION_DISTRICT",
  WAREHOUSE_LOCATION_COUNTRY: "WAREHOUSE_LOCATION_COUNTRY",
  WAREHOUSE_LOCATION_OWNER_TYPE: "WAREHOUSE_LOCATION_OWNER_TYPE",
  WAREHOUSE_LOCATION_STATUS: "WAREHOUSE_LOCATION_STATUS",
  WAREHOUSE_LOCATION_WAREHOUSE_ID: "WAREHOUSE_LOCATION_WAREHOUSE_ID",
  WAREHOUSE_LOCATION_WAREHOUSE_NAME: "WAREHOUSE_LOCATION_WAREHOUSE_NAME",
  WAREHOUSE_LOCATION_WAREHOUSE: "WAREHOUSE_LOCATION_WAREHOUSE",
  WAREHOUSE_COMPLIANCE_TATMEEN_API_KEY: 'WAREHOUSE_COMPLIANCE_TATMEEN_API_KEY',

  TATMEEN_DESTINATION_SGLN: "TATMEEN_DESTINATION_SGLN",

  REPACK_TRADEVALUE: "REPACK_TRADEVALUE",

  WAREHOUSE_PRODUCT_MASTER_NAME: 'WAREHOUSE_PRODUCT_MASTER_NAME',
  WAREHOUSE_PRODUCT_MASTER_CODE: 'WAREHOUSE_PRODUCT_MASTER_CODE',
  WAREHOUSE_PRODUCT_MASTER_EXTRA_FIELD: 'WAREHOUSE_PRODUCT_MASTER_EXTRA_FIELD',
  WAREHOUSE_PRODUCT_MASTER_FIELD_NAME: 'WAREHOUSE_PRODUCT_MASTER_FIELD_NAME',
  WAREHOUSE_PRODUCT_MASTER_FIELD_VALUE: 'WAREHOUSE_PRODUCT_MASTER_FIELD_VALUE',
  WAREHOUSE_PRODUCT_MASTER_SR_NO: 'WAREHOUSE_PRODUCT_MASTER_SR_NO',
  WAREHOUSE_PRODUCT_MASTER_PRN_NAME: 'WAREHOUSE_PRODUCT_MASTER_PRN_NAME',
  WAREHOUSE_PRODUCT_MASTER_IMAGE_NAME: 'WAREHOUSE_PRODUCT_MASTER_IMAGE_NAME',
  TRADEVALUE_COMBO: 'TRADEVALUE_COMBO',
  PRODUCT_COMBO: 'PRODUCT_COMBO',
  REAGG_MANAGE_SSCC: 'REAGG_MANAGE_SSCC',
  SCAN_CODE_HERE: 'SCAN_CODE_HERE',
  GENERATE_LABEL: 'GENERATE_LABEL',
  PRINT_SERVICE_NOT_RUN: "Please run printer service.",
  BUTTON_OK: "BUTTON_OK",
  COMPLIANCE_TYPE: "COMPLIANCE_TYPE",
  SELECT_WAREHOUSE: "SELECT_WAREHOUSE",

  TATMEEN_EPCIS_COMMON_EXTENSION: {
    DISTRIBUTOR_OWN_GLN: "DIST_OWN_GLN",
    DISTRIBUTOR_UNIQUE_REF_NO: "DIST_UNIQUE_REF_NO",
    DESTINATION_WH_LOC_ID: "DEST_WH_LOC_ID",
    DESTINATION_OWN_PARTY_SGLN: "DEST_OWN_SGLAN",
    DESTINATION_LOCATION_SGLN: "DEST_LOC_SGLAN",
    REASON_CODE: "REASON_CODE",
    BLOCKED: "BLOCKED",
  },

  TATMEEN_BIZSTEP: {
    Tatmeen_commissioning: "Commissioning",
    Tatmeen_shipping: "Shipping",
    Tatmeen_shipping_return: "Shipping Return",
    Tatmeen_receiving: "Receiving",
    Tatmeen_receiving_return: "Receiving Return",
    Tatmeen_blocking_batch_lot: "Blocking Batch/Lot",
    Tatmeen_blocking_obj_ids: "Blocking Object Ids",
    Tatmeen_unblocking_batch_lot: "Unblocking Batch/Lot",
    Tatmeen_unblocking_obj_ids: "Unblocking Object Ids",
    Tatmeen_sampling: "Sampling",
    Tatmeen_destruction: "Destruction",
    Tatmeen_destroyed: "Destroyed",
    Tatmeen_damaged: "Damaged",
    Tatmeen_stolen: "Stolen",
    Tatmeen_export: "Export",
    Tatmeen_lost: "Lost",
    Tatmeen_dispensing: "Dispensing",
    Tatmeen_pack: "Pack",
    Tatmeen_unpack: "Unpack",
    Tatmeen_unpack_all: "Unpack_all",
  },
  MYLAN_API_PRINTER_MATERIAL_DESCRIPTION_1	:	"MYLAN_API_PRINTER_MATERIAL_DESCRIPTION_1",
  MYLAN_API_PRINTER_MATERIAL_DESCRIPTION_2	:	"MYLAN_API_PRINTER_MATERIAL_DESCRIPTION_2",
  MYLAN_API_PRINTER_MATERIAL_DESCRIPTION_3	:	"MYLAN_API_PRINTER_MATERIAL_DESCRIPTION_3",
  MYLAN_API_PRINTER_NDC	:	"MYLAN_API_PRINTER_NDC",
  MYLAN_API_PRINTER_CAS	:	"MYLAN_API_PRINTER_CAS",
  MYLAN_API_PRINTER_MFG_LIC_L1	:	"MYLAN_API_PRINTER_MFG_LIC_L1",
  MYLAN_API_PRINTER_MFG_LIC_L2_CAPTION	:	"MYLAN_API_PRINTER_MFG_LIC_L2_CAPTION",
  MYLAN_API_PRINTER_MFG_LIC_L2	:	"MYLAN_API_PRINTER_MFG_LIC_L2",
  API_PRINTER_PRODUCT_STORAGE_CONDITION_1 : "API_PRINTER_PRODUCT_STORAGE_CONDITION_1",
  API_PRINTER_PRODUCT_STORAGE_CONDITION_2	:	"API_PRINTER_PRODUCT_STORAGE_CONDITION_2",
  API_PRINTER_PRODUCT_STORAGE_CONDITION_3	:	"API_PRINTER_PRODUCT_STORAGE_CONDITION_3",
  API_PRINTER_PRODUCT_STORAGE_CONDITION_4	:	"API_PRINTER_PRODUCT_STORAGE_CONDITION_4",
  MYLAN_API_PRINTER_CAUTION_1	:	"MYLAN_API_PRINTER_CAUTION_1",
  MYLAN_API_PRINTER_CAUTION_2	:	"MYLAN_API_PRINTER_CAUTION_2",
  MYLAN_API_PRINTER_CAUTION_3	:	"MYLAN_API_PRINTER_CAUTION_3",
  MYLAN_API_PRINTER_CAUTION_4	:	"MYLAN_API_PRINTER_CAUTION_4",
  MYLAN_API_PRINTER_FACTORY_ADR_1	:	"MYLAN_API_PRINTER_FACTORY_ADR_1",
  MYLAN_API_PRINTER_FACTORY_ADR_2	:	"MYLAN_API_PRINTER_FACTORY_ADR_2",
  MYLAN_API_PRINTER_FACTORY_ADR_3	:	"MYLAN_API_PRINTER_FACTORY_ADR_3",
  MYLAN_API_PRINTER_FACTORY_ADR_4	:	"MYLAN_API_PRINTER_FACTORY_ADR_4",
  MYLAN_API_PRINTER_FACTORY_ADR_5	:	"MYLAN_API_PRINTER_FACTORY_ADR_5",
  MYLAN_API_PRINTER_FACTORY_ADR_6	:	"MYLAN_API_PRINTER_FACTORY_ADR_6",
  MYLAN_API_PRINTER_OFFICE_ADR_1	:	"MYLAN_API_PRINTER_OFFICE_ADR_1",
  MYLAN_API_PRINTER_OFFICE_ADR_2	:	"MYLAN_API_PRINTER_OFFICE_ADR_2",
  MYLAN_API_PRINTER_OFFICE_ADR_3	:	"MYLAN_API_PRINTER_OFFICE_ADR_3",
  MYLAN_API_PRINTER_OFFICE_ADR_4	:	"MYLAN_API_PRINTER_OFFICE_ADR_4",
  MYLAN_API_PRINTER_OFFICE_ADR_5	:	"MYLAN_API_PRINTER_OFFICE_ADR_5",
  MYLAN_API_PRINTER_MFG_DATE	:	"MYLAN_API_PRINTER_MFG_DATE",
  MYLAN_API_PRINTER_RETEST_DATE	:	"MYLAN_API_PRINTER_RETEST_DATE",
  MYLAN_API_PRINTER_EXPIRY_DATE	:	"MYLAN_API_PRINTER_EXPIRY_DATE",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_1	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_1",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_2	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_2",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_3	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_3",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_4	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_4",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_5	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_5",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_6	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_6",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_7	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_7",
  MYLAN_API_PRINTER_ADDITIONAL_TEXT_8	:	"MYLAN_API_PRINTER_ADDITIONAL_TEXT_8",
  API_PRINTER_NAME_OF_API : "API_PRINTER_NAME_OF_API",
  API_PRINTER_BRAND_NAME :"API_PRINTER_BRAND_NAME",
  API_PRINTER_PRODUCT_DATE_FORMAT : "API_PRINTER_PRODUCT_DATE_FORMAT" ,

  UZ_FILE_ID: "UZ_FILE_ID",
  UZ_EXPORT_COUNTRY: "UZ_EXPORT_COUNTRY",
  UZ_ORIGIN_COUNTRY: "UZ_ORIGIN_COUNTRY",
  UZ_CURRENCY: "UZ_CURRENCY",
  UZ_CURRENCY_RATE: "UZ_CURRENCY_RATE",
  UZ_SENDING_DATE: "UZ_SENDING_DATE",
  UZ_ICODE_FROM_DECLARATION: "UZ_ICODE_FROM_DECLARATION",

  // CHina-VDTS
  EVENT_FROM_DATE: "EVENT_FROM_DATE",
  EVENT_TO_DATE: "EVENT_TO_DATE",

  FILE_UPLOAD_PLANT_NAME: 'FILE_UPLOAD_PLANT_NAME',
  FILE_UPLOAD_GLN: 'FILE_UPLOAD_GLN',
  FILE_UPLOAD_FILE_URI: 'FILE_UPLOAD_FILE_URI',
  FILE_UPLOAD_FILE_SIZE: 'FILE_UPLOAD_FILE_SIZE',
  FILE_UPLOAD_UPLOADED_AT: 'FILE_UPLOAD_UPLOADED_AT',
  FILE_UPLOAD_LAST_DOWNLOADED_AT: 'FILE_UPLOAD_LAST_DOWNLOADED_AT',
};


