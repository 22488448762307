import { Component, OnInit, Inject } from "@angular/core";
import { AppSettings } from "app/reetrak-layout/util/app-settings";
import { Constants } from "app/reetrak-layout/util/constants";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "app/reetrak-layout/services/loader.service";
import { CommonService } from "app/reetrak-layout/services/common.service";
@Component({
  selector: "app-list-confirmation-dialog",
  templateUrl: "./list-confirmation-dialog.component.html",
  styleUrls: ["./list-confirmation-dialog.component.scss"]
})
export class ListConfirmationDialogComponent implements OnInit {
  AS = AppSettings;
  CT = Constants;
  title: string;
  list: String[];
  buttonSubmit: string;
  buttonCancel: string;
  constructor(
    public dialogRef: MatDialogRef<ListConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private commonService: CommonService
  ) {
    this.title = data.title;
    this.list = data.list;
    this.buttonSubmit = data.buttonSubmit;
    this.buttonCancel = data.buttonCancel;
  }
  ngOnInit() {
    this.commonService.dialogZIndexAdjustment();
    // this.commonService.customScroller({ suppressScrollX: true }, ".lot-master-scroll");
    this.translate.get(this.buttonSubmit).subscribe(res => {
      this.buttonSubmit = res;
    });
    this.translate.get(this.buttonCancel).subscribe(res => {
      this.buttonCancel = res;
    });
    this.loaderService.display(false);
  }
  submit() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }
}
