import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NomenclatureResolver } from '../../resolver/nomenclature.service';
import { AuthGuard } from '../../auth/auth-guard.service';
import { LoaderService } from '../services/loader.service';
import { AuthServiceService } from '../../services/auth-service.service';
import { TokenStorageService } from '../services/token-storage.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Interceptor } from '../services/interceptor.service';
import { LovService } from '../services/lov.service';
import { MenuService } from '../services/menu.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotificationService } from '../services/notification.service';
import { UserRemarksComponent } from '../modules/dialog/user-remarks/user-remarks.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmationComponent } from '../modules/dialog/confirmation/confirmation.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfigService } from '../services/config.service';
import { SecurityPolicyService } from '../services/security-policy.service';
import { PipeSharedModule } from '../pipe-shared/pipe-shared.module';
import { CommonService } from '../services/common.service';
import { AliasService } from '../services/alias.service';
import { ImagePreviewComponent } from '../modules/dialog/image-preview/image-preview.component';
import { EditProfileComponent } from '../modules/edit-profile/edit-profile.component';
import { ResetPasswordModule } from 'app/pages/login/reset-password/reset-password.module';
import { LockModule } from '../modules/dialog/lock/lock.module';
import { NotificationsFormComponent } from '../modules/notifications/dialog/notifications-form/notifications-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  // return new TranslateHttpLoader(httpClient);
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTooltipModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateModule,
    ResetPasswordModule,
    PipeSharedModule,
    LockModule,
    NgIdleKeepaliveModule.forRoot(),
    ZXingScannerModule
  ],
  declarations: [
    UserRemarksComponent,
    ConfirmationComponent,
    ImagePreviewComponent,
    EditProfileComponent,
    NotificationsFormComponent
  ],
  exports: [
    UserRemarksComponent,
    ConfirmationComponent,
    ImagePreviewComponent,
    EditProfileComponent,
  ],
  providers: [
    NomenclatureResolver, AuthGuard, AuthServiceService, LoaderService,
    TokenStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    LovService,
    MenuService,
    NotificationService,
    ConfigService,
    CommonService,
    AliasService,
    SecurityPolicyService
  ],
  entryComponents: [
    UserRemarksComponent,
    ConfirmationComponent,
    ImagePreviewComponent,
    EditProfileComponent,
    NotificationsFormComponent
  ]
})

export class ReeSourceInitModule { }